import { selectAnalysisType, selectChartData } from '@analysis/analysisSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useIsXSmall } from '@hooks/responsiveHooks';
import { Paper, Typography, styled } from '@mui/material';
import {
	ChartComponent,
	Inject,
	Legend,
	Tooltip,
	LineSeries,
	SplineSeries,
	DataLabel,
	ChartAnnotation,
	SeriesCollectionDirective,
	SeriesDirective,
	Crosshair,
	type MarkerSettingsModel,
	type IAxisLabelRenderEventArgs,
	type ILoadedEventArgs,
	type AxisModel,
	type ISharedTooltipRenderEventArgs,
	ColumnSeries,
	type ITextRenderEventArgs,
} from '@syncfusion/ej2-react-charts';
import styles from './CoverageChart.module.css';
import { setCoverageChartImage } from '../viewerSlice';
import { useEffect, useMemo, useRef } from 'react';
import type { AsphaltCoverageChartData, BaseCoverageChartData, ReportType } from '@analysis/RollerAnalysis';

interface ChartSetup {
	primaryXAxis: AxisModel;
	primaryYAxis: AxisModel;
	marker: MarkerSettingsModel;
	columnMarker?: MarkerSettingsModel;

	secondaryYAxis?: AxisModel;
}

const ChartPaper = styled(Paper)(({ theme }) => ({
	borderRadius: '16px',
	borderColor: 'rgba(0, 0, 0, 0.12)',
	borderWidth: '1px',

	width: '100%',
	height: '100%',

	[theme.breakpoints.down('sm')]: {
		borderRadius: '8px',
	},
}));

function getChartSetup(isXSmall: boolean, reportType?: ReportType): ChartSetup {
	if (reportType === 'ASPHALT') {
		return {
			primaryXAxis: {
				title: 'Rolling Time',
				valueType: 'Double',
				minimum: 0,
				maximum: 30 * 60,
				interval: 60 * (isXSmall ? 5 : 2.5),
				crosshairTooltip: { enable: false },
			},
			primaryYAxis: {
				title: 'Coverage',
				valueType: 'Double',
				minimum: 0,
				maximum: 100,
				labelFormat: '{value}%',
				crosshairTooltip: { enable: false },
			},
			marker: {
				visible: true,
				width: isXSmall ? 1 : 5,
				height: isXSmall ? 1 : 5,
			},
		};
	} else if (reportType === 'BASE') {
		return {
			primaryXAxis: {
				title: 'CMV Value',
				valueType: 'Double',
				minimum: 0,
				maximum: 250,
				interval: isXSmall ? 10 : 10,
				crosshairTooltip: { enable: false },
			},
			primaryYAxis: {
				title: 'Cumulative Coverage (%)',
				valueType: 'Double',
				minimum: 0,
				maximum: 100,
				labelFormat: '{value}%',
				crosshairTooltip: { enable: false },
			},
			marker: {
				visible: true,
				width: isXSmall ? 1 : 5,
				height: isXSmall ? 1 : 5,
			},

			secondaryYAxis: {
				title: 'Occurrence (%)',
				valueType: 'Double',
				name: 'secondary',
				opposedPosition: true,
				minimum: 0,
				maximum: 20,
				interval: 1,
				labelFormat: '{value}%',
				crosshairTooltip: { enable: false },
			},
		};
	} else {
		return {
			primaryXAxis: {
				title: 'Unknown',
			},
			primaryYAxis: {
				title: 'Unknown',
			},
			marker: {
				visible: false,
			},
		};
	}
}

interface CoverageChartProps {
	isSpecsShown: boolean;
}

/**
 * Displays the cumulative coverage charts for each spec
 * @returns coverage chart component
 */
const CoverageChart = (props: CoverageChartProps) => {
	const { isSpecsShown } = props;

	const specShownRef = useRef(false);

	const dispatch = useAppDispatch();
	const data = useAppSelector(selectChartData);
	const reportType = useAppSelector(selectAnalysisType);
	const chartData = data?.[reportType ?? 'ASPHALT'];

	const chart = useRef<ChartComponent | null>(null);
	const isXSmall = useIsXSmall();

	const { primaryXAxis, primaryYAxis, marker, secondaryYAxis } = useMemo(
		() => getChartSetup(isXSmall, reportType),
		[isXSmall, reportType]
	);
	const pdfSetup = getChartSetup(false, reportType);

	const labelRenderer = (args: IAxisLabelRenderEventArgs) => {
		if (args.axis.name === 'primaryXAxis') {
			if (reportType === 'ASPHALT') {
				const mins = Math.floor(args.value / 60);
				const secs = args.value % 60;
				args.text = `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
			} else {
				args.text = args.value.toString();
			}
		}
	};

	useEffect(() => {
		if (specShownRef.current !== isSpecsShown) {
			specShownRef.current = isSpecsShown;

			const timeout = setTimeout(() => {
				console.log('force refresh chart...');
				if (chart.current) {
					//chart.current.redraw = true;
					//chart.current.chartResize();

					chart.current.animated = false;
					chart.current.animateSeries = false;
					chart.current.refresh();
				}
			}, 250);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [isSpecsShown]);

	const onChartLoaded = (args: ILoadedEventArgs) => {
		console.log('Chart Loaded: ', args.name);

		const svg: SVGElement = document.querySelector('#charts_hidden_svg')!;
		if (!svg) {
			console.error('SVG Chart not found');
			return;
		}

		const svgData = new XMLSerializer().serializeToString(svg);
		const canvas = document.createElement('canvas');
		document.body.appendChild(canvas);
		const svgSize = svg.getBoundingClientRect();
		canvas.width = svgSize.width;
		canvas.height = svgSize.height;
		const ctx = canvas.getContext('2d');

		const img = document.createElement('img');
		img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));
		img.onload = function () {
			if (!ctx) {
				console.log('Canvas context not found');
				return;
			}
			ctx.drawImage(img, 0, 0);
			const imagedata = canvas.toDataURL('image/png');
			//console.log(imagedata); // printed base64 in console
			console.log('Chart Image Data: ', (imagedata.length / 1024).toFixed(1), 'KB');
			canvas.remove();

			dispatch(setCoverageChartImage(imagedata));
		};
	};

	const titleHeight = isXSmall ? 24 : 32;

	const onRenderSharedTooltip = (args: ISharedTooltipRenderEventArgs) => {
		if (reportType === 'ASPHALT') {
			const mins = Math.floor((args.point[0].x as number) / 60)
				.toFixed(0)
				.padStart(2, '0');
			const secs = ((args.point[0].x as number) % 60).toFixed(0).padStart(2, '0');

			args.headerText = `Coverage @ ${mins}:${secs}mins`;
			args.text = args.point.map((point, index) => {
				return `${args.series[index].name}:\t\t${Math.round(point.y as number)}%`;
			});
		} else if (reportType === 'BASE') {
			args.headerText = `Coverage: ≥ ${args.point[0].x} CMV`;
			args.text = args.point.map((point, index) => {
				return `${args.series[index].name}:\t\t${Math.round(point.y as number)}%`;
			});
		}
	};

	const onRenderText = (args: ITextRenderEventArgs) => {
		if (args.series.type === 'Column') {
			const percent = parseFloat(args.text);
			if (percent < 1.0) {
				args.text = '';
			} else {
				args.text = percent.toFixed(1);
			}
		}
	};

	return (
		<ChartPaper style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
			<div className={`${styles.fillSpace} ${styles.pr16}`}>
				<Typography
					variant='h6'
					align='center'
					sx={{
						alignContent: 'end',
						fontSize: { xs: 12, md: 16 },
						height: { xs: 24, md: 32 },
						paddingLeft: 8,
					}}>
					Cumulative Coverage Chart(s)
				</Typography>

				<ChartComponent
					id='charts'
					ref={(r) => (chart.current = r)}
					style={{ width: '100%', height: `calc(100% - ${titleHeight}px)` }}
					primaryXAxis={primaryXAxis}
					axisLabelRender={labelRenderer}
					primaryYAxis={primaryYAxis}
					crosshair={{
						enable: true,
						opacity: 0.75,
						lineType: 'Vertical',
						line: {
							width: 1,
						},
					}}
					tooltip={{
						enable: true,
						shared: true,
						duration: 1000,
						enableAnimation: true,
						enableMarker: true,
						fadeOutMode: 'Click',
						showNearestPoint: true,
					}}
					sharedTooltipRender={onRenderSharedTooltip}
					axes={secondaryYAxis ? [secondaryYAxis] : undefined}
					textRender={onRenderText}
					legendSettings={{
						alignment: 'Center',
						padding: 8,
					}}>
					<Inject
						services={[
							Crosshair,
							ColumnSeries,
							LineSeries,
							SplineSeries,
							Legend,
							Tooltip,
							DataLabel,
							ChartAnnotation,
						]}
					/>
					<SeriesCollectionDirective>
						{chartData
							?.map((item) => {
								if (reportType === 'ASPHALT') {
									const spec = item as AsphaltCoverageChartData;
									return (
										<SeriesDirective
											key={spec.specNumber}
											dataSource={spec.data}
											xName='x'
											yName='coverageCumulative'
											width={2}
											name={`Spec #${spec.specNumber}`}
											type='Spline'
											marker={marker}
										/>
									);
								} else if (reportType === 'BASE') {
									const plot = item as BaseCoverageChartData;

									return [
										<SeriesDirective
											key={`${plot.chartType}_coverage`}
											dataSource={plot.data}
											xName='x'
											yName='coverage'
											name={`${plot.chartType} Occurence`}
											type='Column'
											yAxisName='secondary'
											marker={{
												dataLabel: {
													visible: true,
												},
											}}
										/>,
										<SeriesDirective
											key={`${plot.chartType}_cumulative`}
											dataSource={plot.data}
											xName='x'
											yName='coverageCumulative'
											width={2}
											name={`${plot.chartType} Cumulative (Total)`}
											type='Spline'
											marker={marker}
										/>,

										<SeriesDirective
											key={`${plot.chartType}_cumulative_constrained`}
											dataSource={plot.data}
											xName='x'
											yName='coverageCumulativeConstrained'
											width={2}
											name={`${plot.chartType} Cumulative (Tested)`}
											type='Spline'
											marker={marker}
										/>,
									];
								}
							})
							.flat()}
					</SeriesCollectionDirective>
				</ChartComponent>

				<ChartComponent
					id='charts_hidden'
					style={{
						position: 'absolute',
						left: -9999,
						width: 1200,
						height: 800,
						display: 'none!important',
					}}
					className={styles.hiddenChart}
					primaryXAxis={{ ...pdfSetup.primaryXAxis, crosshairTooltip: { enable: false } }}
					axisLabelRender={labelRenderer}
					primaryYAxis={{ ...pdfSetup.primaryYAxis, crosshairTooltip: { enable: false } }}
					title='Cumulative Coverage Chart(s)'
					allowExport
					loaded={onChartLoaded}
					enableAnimation={false}
					axes={pdfSetup.secondaryYAxis ? [pdfSetup.secondaryYAxis] : undefined}
					textRender={onRenderText}>
					<Inject services={[SplineSeries, ColumnSeries, LineSeries, Legend, DataLabel, ChartAnnotation]} />

					<SeriesCollectionDirective>
						{chartData
							?.map((item) => {
								if (reportType === 'ASPHALT') {
									const spec = item as AsphaltCoverageChartData;
									return (
										<SeriesDirective
											key={spec.specNumber}
											dataSource={spec.data}
											xName='x'
											yName='coverageCumulative'
											width={2}
											name={`Spec #${spec.specNumber}`}
											type='Spline'
											marker={pdfSetup.marker}
											animation={{ enable: false }}
										/>
									);
								} else if (reportType === 'BASE') {
									const plot = item as BaseCoverageChartData;
									return [
										<SeriesDirective
											key={`${plot.chartType}_coverage`}
											dataSource={plot.data}
											xName='x'
											yName='coverage'
											name={`${plot.chartType} Occurence`}
											type='Column'
											yAxisName='secondary'
											animation={{ enable: false }}
											marker={{
												dataLabel: {
													visible: true,
												},
											}}
										/>,
										<SeriesDirective
											key={`${plot.chartType}_cumulative`}
											dataSource={plot.data}
											xName='x'
											yName='coverageCumulative'
											width={2}
											name={`${plot.chartType} Cumulative (Total)`}
											type='Spline'
											marker={pdfSetup.marker}
											animation={{ enable: false }}
										/>,
										<SeriesDirective
											key={`${plot.chartType}_cumulative_constrained`}
											dataSource={plot.data}
											xName='x'
											yName='coverageCumulativeConstrained'
											width={2}
											name={`${plot.chartType} Cumulative (Tested)`}
											type='Spline'
											marker={pdfSetup.marker}
											animation={{ enable: false }}
										/>,
									];
								}
							})
							.flat()}
					</SeriesCollectionDirective>
				</ChartComponent>
			</div>
		</ChartPaper>
	);
};

export default CoverageChart;
