import dayjs, { type Dayjs } from 'dayjs';
import { mergeRollerData, RollerData, type AmplitudeSetting, type OverviewInfo, type RollerPass } from './rollerTypes';
import { Datum, type GridCoordinate } from '@shared/geometry/core/Coordinate';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';

export default class DynapacCSV {

  static async read(files: File[]): Promise<RollerData> {
    const data: RollerData[] = [];
    const overviews: OverviewInfo[] = [];
    const start = dayjs();

    const result = await Promise.all(files.map(async (file) => {
      try {
        const buffer = await file.arrayBuffer();

        // TODO: create transform dynamically based on file content
        const transform = MercatorTransform.fromGDA94ToGDA2020();
        //const transform = MercatorTransform.createFromSingleDatum(Datum.GDA94);
        // transform.adjustment = {
        //   x: 0.0,
        //   y: 0.0,
        // };

        //const transform = MercatorTransform.fromGDA2020ToGDA94();
        return DynapacCSV.parse(buffer, transform);
      } catch (err) {
        console.error(err);
      }
    }));

    result.forEach((roller) => {
      if (!roller) {
        return;
      }

      const [rollerData, overview] = roller;
      data.push(rollerData);
      overviews.push(overview);
    });

    const parseTime = dayjs().diff(start, 'milliseconds', true);

    // merge the data/overviews from all the files
    const merged = mergeRollerData(data, overviews);

    const mergeTime = dayjs().diff(start, 'milliseconds', true) - parseTime;

    console.log(`Read ${files.length} files:`);
    console.log(`\tTime: ${parseTime + mergeTime}ms (parse), ${mergeTime}ms (merge)`);
    console.log(merged.overviews);
    console.log('');

    return merged;
  }

  private static find(header: string, lines: string[], startIdx: number): number {
    let idx = startIdx;
    while (!lines[idx].toLowerCase().includes(header.toLowerCase())) {
      ++idx;
    }
    return idx + 1;
  }

  // TODO: Extract coordinate info from the CSV
  static parse(buffer: ArrayBuffer, transform?: MercatorTransform): [RollerData, OverviewInfo] {
    //const start = window.performance.now();

    const text = new TextDecoder('utf-8').decode(buffer);

    //const afterDecode = window.performance.now();

    const lines = text.split(/\r?\n/);

    //const afterSplit = window.performance.now();

    let startIdx = 0;
    const epsgIdx = DynapacCSV.find('EPSG', lines, startIdx);
    const engineerIdx = DynapacCSV.find('Quality engineer', lines, startIdx);
    const projectIdx = DynapacCSV.find('Project', lines, startIdx);
    const machineIdx = DynapacCSV.find('Machines working on the project', lines, startIdx);

    const epsg = +lines[epsgIdx].split(',')[0];
    const epsgName = lines[epsgIdx].split(',')[1];
    const engineer = lines[engineerIdx];
    const project = lines[projectIdx];

    let date: Dayjs | null = null;

    while (lines[startIdx].indexOf('Begin') === -1) {
      ++startIdx;
    }

    //const afterFindStart = window.performance.now();
    const data = new RollerData();

    // const splitTimes: number[] = [];
    // const parseTimes: number[] = [];
    // const addTimes: number[] = [];
    for (const line of lines.slice(startIdx + 1)) {

      //const beforeSplit = window.performance.now();
      const fields = line.split(',');
      if (fields.length < 25) {
        continue;
      }
      //const afterSplit = window.performance.now();

      // const northing = parseFloat(fields[2]);
      // const easting = parseFloat(fields[3]);
      // const utc = dayjs(fields[9]);
      // const temp = parseInt(fields[22]);
      // const pass = parseInt(fields[24]);
      // const speed = parseFloat(fields[26]);

      // using the unary + operator to convert to number is supposedly faster...
      // TODO: it really doesn't seem to be, so maybe go back to parseInt/parseFloat for better readability
      const northing = +fields[2];
      const easting = +fields[3];
      const utc = dayjs.utc(fields[9]);
      const temp = +fields[22];
      const pass = +fields[24];
      const speed = +fields[26];
      const rollerId = fields[1];

      if (!date) {
        date = utc.local(); // local time
      }

      const coord: GridCoordinate = { x: easting, y: northing, zone: 56, datum: Datum.GDA94 };
      const transformed = transform?.transformRedfearn(coord);

      const gnssQuality = +fields[11];
      const cmv = fields[14] ? +fields[14] : null;
      // const evib1 = +fields[15];
      // const evib2 = +fields[16];
      // const direction = fields[25];
      const amplitude = +fields[28];
      const amplitudeSetting = fields[27] as AmplitudeSetting;

      // TODO: Remove this horrible fix for the inconsistent clocks between the rollers
      // if (rollerId === '10000610JNA032408') {
      //   utc = utc.subtract(9, 'hours');
      // }

      const rollerPass: RollerPass = {
        rollerId,
        coords: transformed ?? coord,
        passNumber: pass,
        speed,
        cmv,
        amplitudeSetting,
        amplitude,
        temperature: temp,
        timestamp: utc,

        gnss: gnssQuality === 1 ? 'High' : 'Low',
      };

      //const afterParse = window.performance.now();

      data.addOrUpdate(rollerPass);

      //const final = window.performance.now();

      // splitTimes.push(afterSplit - beforeSplit);
      // parseTimes.push(afterParse - afterSplit);
      // addTimes.push(final - afterParse);
    }

    //const end = window.performance.now();

    // console.log(`Decode time: ${(afterDecode - start).toFixed(1)} ms`);
    // console.log(`Split time: ${(afterSplit - afterDecode).toFixed(1)} ms`);
    // console.log(`Find start time: ${(afterFindStart - afterSplit).toFixed(1) } ms`);
    // console.log(`Parse time: ${(end - start).toFixed(1) } ms`);

    // console.log(`\tSplit time: ${splitTimes.reduce((a, b) => a + b, 0).toFixed(1)} ms`);
    // console.log(`\tParse time: ${parseTimes.reduce((a, b) => a + b, 0).toFixed(1) } ms`);
    // console.log(`\tAdd time: ${addTimes.reduce((a, b) => a + b, 0).toFixed(1) } ms`);

    const overview: OverviewInfo = {
      rollerId: lines[machineIdx].split(',')[1],
      date: date ?? dayjs(),
      project,
      engineer,
      epsg,
      epsgName,
    };

    return [data, overview];
  }
}