import { coreApi } from './coreApiSlice';
import type { DynapacError, DynapacProjectDetails, DynapacJobListRequest, DynapacLoginDetails, DynapacTokenResponse, DynapacDownloadRequest, DynapacDownloadResponse } from '../../../../shared/src/dynapacTypes';
import type { RootState } from '@app/store';
import type { HeaderPreparer } from './helpers';

export const appendDynapacTokenHeader: HeaderPreparer = async (headers, { getState, endpoint }) => {
  switch (endpoint) {
    case 'getDynapacJobsList':
    case 'getDynapacDownloadUrl': {
      const token = (getState() as RootState).dataPicker.dynapac.auth?.accessToken;
      if (token) {
        headers.set('x-paveset-dynapac-token', `${token}`);
      }
    }
  }

  return headers;
}

export const dynapacApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynapacAccessToken: builder.query<DynapacTokenResponse, DynapacLoginDetails>({
      query: (details) => ({
        url: 'dynapac/login?' + new URLSearchParams(details).toString(),
        cache: 'default',
        method: 'GET'
      }),

      transformErrorResponse: (response): DynapacError => {
        if (response.data as { Unauthorized: string }) {
          return { detail: 'Invalid email or password' }
        }

        return { detail: 'Unknown Error' };
      }
    }),

    getDynapacJobsList: builder.query<DynapacProjectDetails[], DynapacJobListRequest>({
      query: (req) => ({
        url: 'dynapac/jobs',
        method: 'POST',
        body: {
          email: req.email,
        },
      }),
      keepUnusedDataFor: 15 * 60, // 15 minutes
      transformErrorResponse: (response): DynapacError => {
        if (response.status === 400) {
          return {
            detail: response.data as unknown as string
          }
        }

        const invalidEmailError = response.data as { status: string, response: string };
        if (invalidEmailError.status && invalidEmailError.response) {
          return { detail: `${invalidEmailError.status}: ${invalidEmailError.response}` }
        }

        const typeError = response.data as { status: string, message: string };
        if (typeError.status && typeError.message) {
          return { detail: `${typeError.status}: ${typeError.message}` }
        }

        const tokenError = response.data as { detail: string };
        if (tokenError.detail) {
          return { detail: tokenError.detail }
        }

        return { detail: 'Unknown Error' }
      }
    }),

    getDynapacDownloadUrl: builder.query<DynapacDownloadResponse, DynapacDownloadRequest>({
      query: (req: DynapacDownloadRequest) => {
        const params = new URLSearchParams({
          'layerId': req.layerId,
          'layerName': req.layerName,
          'isBaseData': req.isBaseData.toString()
        });
        if (req.fields) {
          params.append('fields', req.fields);
        }

        return {
          url: 'dynapac/download-url?' + params.toString(),
          method: 'GET',
        }
      },
    }),

    getDynapacDownloadUrlSimulation: builder.query<DynapacDownloadResponse, DynapacDownloadRequest>({
      query: (req: DynapacDownloadRequest) => {
        const params = new URLSearchParams({
          'layerId': req.layerId,
          'isBaseData': req.isBaseData.toString()
        });
        if (req.fields) {
          params.append('fields', req.fields);
        }

        return {
          url: 'test/download?' + params.toString(),
          method: 'GET',
        }
      },
    }),


  })
});

export const {
  useGetDynapacAccessTokenQuery,
  useGetDynapacJobsListQuery,
  useGetDynapacDownloadUrlQuery,
  useLazyGetDynapacDownloadUrlQuery,

  useGetDynapacDownloadUrlSimulationQuery,
  useLazyGetDynapacDownloadUrlSimulationQuery
} = dynapacApi;