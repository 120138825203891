
export interface Point {
  x: number;
  y: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface GridBounds {
  topLeft: GridCoordinate;
  bottomRight: GridCoordinate;
}

export interface GridCoordinate extends Point {
  datum: Datum;
  zone: number;

  height?: number;
}

export interface GeodeticCoordinate {
  lat: number;
  lon: number;
  datum: Datum;

  alt?: number;
}

export enum Datum {
  WGS84 = 'WGS84',
  GDA94 = 'GDA94',
  GDA2020 = 'GDA2020',

  Unknown = 'Unknown'
}

export interface CartestianCoordinate {
  x: number;
  y: number;
  z: number;
}