import type { Bounds, RollerData } from './rollerTypes';

interface Point { x: number, y: number }
type Polygon = Point[];

const GRID_SIZE = 0.4;

export function calculateBinaryGrid(rollerData: RollerData, pass: number, bounds: Bounds): boolean[][] {

  const rows = Math.ceil((bounds.max.y - bounds.min.y) / GRID_SIZE);
  const cols = Math.ceil((bounds.max.x - bounds.min.x) / GRID_SIZE);
  const grid = Array.from({ length: rows }, () => Array<boolean>(cols).fill(false));

  for (const cell of rollerData.getCells().where((cell) => cell.isWithinBoundary && cell.passes.length >= pass)) {
    const { coords } = cell;
    const x = Math.floor((coords.x - bounds.min.x) / GRID_SIZE);
    const y = Math.floor((coords.y - bounds.min.y) / GRID_SIZE);
    grid[y][x] = true;
  }

  return grid;
}

export function calculateOutlinePolygons(rollerData: RollerData, pass: number): Polygon[] {
  const bounds = rollerData.calculateBounds();
  const grid = calculateBinaryGrid(rollerData, pass, bounds);

  return calculateOutlinePolygonsInternal(grid, bounds.min.x, bounds.min.y);
}

function calculateOutlinePolygonsInternal(grid: boolean[][], originX: number, originY: number): Polygon[] {
  const rows = grid.length;
  const cols = grid[0].length;
  const visited = Array.from({ length: rows }, () => Array<boolean>(cols).fill(false));
  const directions = [
    { x: 1, y: 0 }, // right
    { x: 0, y: -1 }, // down
    { x: -1, y: 0 }, // left
    { x: 0, y: 1 }  // up
  ];

  const polygons: Polygon[] = [];

  function isValid(x: number, y: number): boolean {
    return x >= 0 && x < cols && y >= 0 && y < rows;
  }

  function floodFill(x: number, y: number): Polygon {
    const stack: Point[] = [{ x, y }];
    const edges = new Set<string>();

    while (stack.length) {
      const { x, y } = stack.pop()!;
      if (visited[y][x]) continue;
      visited[y][x] = true;

      for (const dir of directions) {
        const nx = x + dir.x;
        const ny = y + dir.y;
        if (isValid(nx, ny) && grid[ny][nx] && !visited[ny][nx]) {
          stack.push({ x: nx, y: ny });
        }
      }

      // Add edges to the set
      if (!isValid(x, y - 1) || !grid[y - 1][x]) edges.add(`${x},${y}-bottom`);
      if (!isValid(x, y + 1) || !grid[y + 1][x]) edges.add(`${x},${y}-top`);
      if (!isValid(x - 1, y) || !grid[y][x - 1]) edges.add(`${x},${y}-left`);
      if (!isValid(x + 1, y) || !grid[y][x + 1]) edges.add(`${x},${y}-right`);
    }

    return tracePolygon(edges, originX, originY);
  }

  function tracePolygon(edges: Set<string>, originX: number, originY: number): Polygon {
    const polygon: Polygon = [];
    const edgeMap: Record<string, { start: Point, end: Point, pos: 'top' | 'left' | 'bottom' | 'right' }> = {};

    // Convert edges to start-end points
    edges.forEach((edge) => {
      const [coord, pos] = edge.split('-');
      const [x, y] = coord.split(',').map(Number);
      let start: Point, end: Point;

      switch (pos) {
        case 'top':
          start = { x: originX + (x + 1) * GRID_SIZE, y: originY + (y + 1) * GRID_SIZE };
          end = { x: originX + x * GRID_SIZE, y: originY + (y + 1) * GRID_SIZE };
          break;
        case 'bottom':
          start = { x: originX + x * GRID_SIZE, y: originY + y * GRID_SIZE };
          end = { x: originX + (x + 1) * GRID_SIZE, y: originY + y * GRID_SIZE };
          break;
        case 'left':
          start = { x: originX + x * GRID_SIZE, y: originY + (y + 1) * GRID_SIZE };
          end = { x: originX + x * GRID_SIZE, y: originY + y * GRID_SIZE };
          break;
        case 'right':
          start = { x: originX + (x + 1) * GRID_SIZE, y: originY + y * GRID_SIZE };
          end = { x: originX + (x + 1) * GRID_SIZE, y: originY + (y + 1) * GRID_SIZE };
          break;
        default:
          throw new Error('Invalid edge position');
      }

      edgeMap[`${start.x},${start.y}`] = { start, end, pos };
    });

    // Trace the polygon
    let currentEdge = Object.values(edgeMap)[0];
    polygon.push(currentEdge.start);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      polygon.push(currentEdge.end);
      const nextEdge = edgeMap[`${currentEdge.end.x},${currentEdge.end.y}`];
      if (!nextEdge || nextEdge.end === polygon[0])
        break;

      if (polygon.length > 10000) {
        // nothing
        break;
      }
      currentEdge = nextEdge;
    }

    return polygon;
  }

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      if (grid[y][x] && !visited[y][x]) {
        const polygon = floodFill(x, y);
        if (polygon.length) polygons.push(polygon);
      }
    }
  }

  return polygons;
}