import { type V3 } from '@math/Vector3';
import dayjs, { type Dayjs } from 'dayjs';

const GDA94_TO_GDA2020 = new Float64Array([-0.0394924, -0.0327221, -0.0328979]);
const GDA2020_TO_GDA94 = new Float64Array([0.0394924, 0.0327221, 0.0328979]);

const WGS84_TO_GDA2020_ROTATION_RATE = new Float64Array([0.00150379, 0.00118346, 0.00120716]);

const GDA2020_EPOCH: Dayjs = dayjs('2020-01-01');

function calculateRotationParams(rx: number, ry: number, rz: number,
                                 drx: number, dry: number, drz: number,
                                 reference: Dayjs, epoch: Dayjs): V3 {
  const delta = epoch.diff(reference, 'years', true);
  const x = rx + drx * delta;
  const y = ry + dry * delta;
  const z = rz + drz * delta;
  return new Float64Array([x, y, z]);
}

function wgs84ToGDA2020(epoch: Dayjs) {
  const [drx, dry, drz] = WGS84_TO_GDA2020_ROTATION_RATE;

  return calculateRotationParams(0.0, 0.0, 0.0, drx, dry, drz, GDA2020_EPOCH, epoch);
}

function getGDA94ToGDA2020() {
  return GDA94_TO_GDA2020;
}

function getGDA2020ToGDA94() {
  return GDA2020_TO_GDA94;
}

export default {
  getGDA94ToGDA2020,
  getGDA2020ToGDA94,
  wgs84ToGDA2020
} as const;