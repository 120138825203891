import { combineSlices, configureStore, type ThunkAction } from '@reduxjs/toolkit';
import { dataPickerSlice, ignoredDataPickerActions, ignoredDataPickerPaths, type DataPickerActions } from '../features/dataPicker/dataPickerSlice';
import { analysisSlice, ignoredAnalysisActions, ignoredAnalysisPaths, type AnalysisActions } from '../features/analysis/analysisSlice';
import { viewerSlice, ignoredViewerActions, ignoredViewerPaths, type ViewerActions } from '../features/viewer/viewerSlice';
import { accountSlice, ignoredAccountActions, ignoredAccountPaths, type AccountActions } from '../features/account/accountSlice';
import { coreApi, ignoredApiActions, ignoredApiPaths } from '../features/api/coreApiSlice';
import { getDevToolsOptions } from '../middleware/devTools';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { SliceActions } from '@tools/helpers';
import persistStateMiddleware from '../middleware/persistStateMiddleware';
import { arcgisApi } from '../features/api/arcgisApiSlice';

const rootReducer = combineSlices(dataPickerSlice, analysisSlice, viewerSlice, accountSlice, coreApi, arcgisApi);

export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = (initialState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: getDevToolsOptions(),

    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            ...ignoredDataPickerActions,
            ...ignoredAnalysisActions,
            ...ignoredViewerActions,
            ...ignoredAccountActions,
            ...ignoredApiActions,
          ],
          ignoredPaths: [
            ...ignoredDataPickerPaths,
            ...ignoredAnalysisPaths,
            ...ignoredViewerPaths,
            ...ignoredAccountPaths,
            ...ignoredApiPaths
          ],
        }
      }).concat(persistStateMiddleware, coreApi.middleware, arcgisApi.middleware)
    },
    preloadedState: initialState
  });

  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

export type AllReducerActions = SliceActions<
  DataPickerActions
  & AnalysisActions
  & ViewerActions
  & AccountActions
>;

// export type AllApiActions = SliceActions<
//   typeof coreApi.internalActions
//   & Omit<typeof arcgisApi.internalActions, keyof typeof coreApi.internalActions>>;

export type AllApiActions = SliceActions<typeof coreApi.internalActions>;

export type AllActions = AllReducerActions | AllApiActions;

export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, AllActions>;


