import Loader from '@components/Loader';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ArcGISCallback = () => {
  const location = useLocation();

  console.log(location);

  useEffect(() => {
    // Parse the query parameters to get the access token
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      // Store the token (e.g., in local storage or context)
      localStorage.setItem('authCode', code);

      // Redirect to the home page or another protected route
      //navigate('/home');
      window.close();
    } else {
      // Handle the error case where access token is not present
      console.error('No access token found in query parameters');
      localStorage.removeItem('authCode');
      window.close();
    }
  }, [location]);

  return (
    <div style={{ width: '100%', height: '100%'}}>
      <Loader centered type='circle' size={40} />
    </div>
  );
};

export default ArcGISCallback;