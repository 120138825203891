import Loader from '@components/Loader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Typography, Box, Tab, styled, Button, Collapse, ButtonBase } from '@mui/material';
import DynapacDataPicker from './DynapacDownloader';
import { useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
	selectRollerFiles,
	selectRollerItemsByCurrentSource,
	selectRollerKeys,
	selectRollerSource,
	setRollerFiles,
	setRollerSource,
	type DataPickerSource,
} from '../dataPickerSlice';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import * as ufp from 'use-file-picker';
import { FileTypeValidator, FileSizeValidator } from 'use-file-picker/validators';
import DataPickerSection from '../shared/DataPickerSection';
import { FilesTable } from '../shared/FilesTable';
import SyncIcon from '@mui/icons-material/Sync';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

const PickerButton = styled(Button)({
	borderBottomLeftRadius: '16px',
	borderTopLeftRadius: 0,
	borderBottomRightRadius: 0,
	borderTopRightRadius: '16px',
	width: 200,
	marginLeft: -8,
	marginBottom: -8,
});

const RollerDataPicker = () => {
	const rollerDataSource = useAppSelector(selectRollerSource);
	const rollerItemsBySource = useAppSelector(selectRollerItemsByCurrentSource);
	const rollerFiles = useAppSelector(selectRollerFiles);
	const rollerKeys = useAppSelector(selectRollerKeys);

	const dispatch = useAppDispatch();
	const [refreshApi, setRefreshApi] = useState(false);
	const refreshBtnRef = useRef<HTMLDivElement | null>(null);
	const rippleRef = useRef<{ start: () => void; stop: () => void }>(null);

	const rollerPicker = ufp.useFilePicker({
		accept: ['.csv', '.txt'],
		readAs: 'Text',
		readFilesContent: undefined,
		validators: [
			new FileTypeValidator(['csv', 'txt']),
			new FileSizeValidator({
				minFileSize: 512 * 1024, // 512KB minimum file size
			}),
		],
		onFilesSuccessfullySelected: (files) => {
			dispatch(setRollerFiles(files.plainFiles));
		},
		onFilesRejected: (files) => {
			console.log('rejected files:', files);
		},
		onClear: () => {
			dispatch(setRollerFiles([]));
		},
	});

	const rollerFilesInfo = useMemo(() => {
		if (rollerFiles.length === 0) return 'Select the file(s) that contain the roller pass data';
		return rollerFiles.map((file) => `${file.name} (${(file.size / 1024 / 1024).toFixed(1)}MB)`).join('\n');
	}, [rollerFiles]);

	const handleChangeRollerDataSource = (_event: React.SyntheticEvent, newValue: DataPickerSource) => {
		dispatch(setRollerSource(newValue));
	};
	const handlePickRollerFiles = () => rollerPicker.openFilePicker();

	const handleRefreshClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		//rippleRef.current?.stop();
		//rippleRef.current?.start();

		if (refreshApi) {
			setRefreshApi(false);
			return;
		}

		setRefreshApi(true);
	};

	const handleRefreshStatusChanged = (isRefreshing: boolean): void => {
		if (!isRefreshing) {
			setRefreshApi(false);
		}
	};

	const ApiTabIcon = () => {
		return (
			<ButtonBase
				component='div'
				color='inherit'
				ref={refreshBtnRef}
				sx={{
					m: 0,
					p: 1,
					borderRadius: 2,
					position: 'absolute',
					right: 8,
					'& .MuiTouchRipple-root .MuiTouchRipple-rippleVisible': {
						animationDuration: '550ms',
					},
				}}
				onClick={handleRefreshClick}>
				<SyncIcon sx={{ height: 24, width: 24 }} />
				<TouchRipple ref={rippleRef} />
			</ButtonBase>
		);
	};

	return (
		<DataPickerSection section={1} title='Roller Data Source' isComplete={rollerItemsBySource.length > 0}>
			<Box height='100%'>
				<TabContext value={rollerDataSource}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={handleChangeRollerDataSource}
							orientation='horizontal'
							variant='fullWidth'
							centered
							aria-label='roller-data-source-tabs'>
							<Tab label='CSV File(s)' value='files' />
							<Tab
								label='DYNAPAC API'
								value='api'
								iconPosition='end'
								icon={<ApiTabIcon />}
								sx={{
									minHeight: '48px',
									paddingTop: '12px',
									paddingBottom: '12px',
									paddingRight: '24px',
									justifyContent: 'center',
									lineHeight: '24px',
									maxWidth: '50%',
								}}
							/>
						</TabList>
					</Box>

					<Collapse timeout={500} in={rollerDataSource === 'files'}>
						<TabPanel value='files' style={{ padding: '8px 8px 8px 0px' }}>
							{rollerFiles.length === 0 && (
								<>
									{!rollerPicker.loading && (
										<Typography
											fontStyle='italic'
											textAlign='center'
											p={{ xs: 1, sm: 2 }}
											whiteSpace='pre-wrap'>
											{rollerFilesInfo}
										</Typography>
									)}
									{rollerPicker.loading && <Loader centered delayed type='circle' text='loading files...' />}
								</>
							)}
							{rollerFiles.length > 0 && <FilesTable files={rollerFiles} />}
						</TabPanel>
					</Collapse>

					<Collapse timeout={500} in={rollerDataSource === 'api'}>
						<TabPanel value='api' style={{ padding: 8 }}>
							<DynapacDataPicker
								selectedItems={rollerKeys}
								forceRefresh={refreshApi}
								onRefreshStatusChanged={handleRefreshStatusChanged}
							/>
						</TabPanel>
					</Collapse>
				</TabContext>
			</Box>

			<PickerButton
				sx={{ display: rollerDataSource === 'files' ? 'flex' : 'none' }}
				startIcon={<AttachFileOutlinedIcon />}
				variant='contained'
				color='primary'
				size='large'
				onClick={handlePickRollerFiles}>
				Pick Files
			</PickerButton>
		</DataPickerSection>
	);
};

export default RollerDataPicker;
