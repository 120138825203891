import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { type LinkProps as RouterLinkProps, Link as RouterLink, useLocation } from 'react-router-dom';
import type { EndSessionRequest, RedirectRequest } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

import { useAppBarHeight } from '../hooks/responsiveHooks';
import styles from './Layout.module.css';

const drawerWidth = 240;

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: string;
	to: string;
	disabled?: boolean;
	isActive: boolean;

	closeDrawer: () => void;
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
	return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function ListItemLink(props: ListItemLinkProps) {
	const { icon, primary, to, closeDrawer, isActive, disabled = false } = props;

	return (
		<ListItem disablePadding component={Link} to={to}>
			<ListItemButton
				selected={isActive}
				onClick={closeDrawer}
				disabled={disabled}
				sx={{
					'& Mui-selected': {
						border: 'red 1px solid',
					},
				}}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText color='#0000007F' primary={primary} />
			</ListItemButton>
		</ListItem>
	);
}

const ToolbarButton = styled(Button)({
	color: 'inherit',
	fontFamily: 'Montserrat',
	fontSize: 'medium',
	'&:hover': {
		fontWeight: 600,
	},
	'&:focus': {
		outline: 'none',
	},
});

const MenuButton = styled(IconButton)({
	'&:focus': {
		outline: 'none',
	},
});

function Layout({ children }: { children: ReactNode }) {
	const location = useLocation();
	const [flyoutDrawerOpen, setFlyoutDrawerOpen] = useState(false);
	const isAuthenticated = useIsAuthenticated();
	const { instance } = useMsal();

	const appBarHeight = useAppBarHeight();

	const handleDrawerToggle = () => {
		setFlyoutDrawerOpen(!flyoutDrawerOpen);
	};

	const drawer = useMemo(
		() => {
			const isActive = (route: string): boolean => {
				return location.pathname.endsWith(route);
			};

			const closeDrawer = () => {
				if (flyoutDrawerOpen) {
					setFlyoutDrawerOpen(false);
				}
			};

			return (
				<div>
					{/* use empty toolbar to add paddding for drawer elements */}
					<Toolbar />
					<List>
						<ListItemLink
							to='/home'
							isActive={isActive('/home')}
							primary='Home'
							closeDrawer={closeDrawer}
							icon={<HomeOutlinedIcon />}
						/>

						<Divider sx={{ mt: '32px', mb: 1 }} />

						<ListItemLink
							to='/viewer'
							isActive={isActive('/viewer')}
							primary='Viewer'
							closeDrawer={closeDrawer}
							disabled={!isAuthenticated}
							icon={<TimelineIcon />}
						/>
					</List>

					<Button
						component={RouterLink}
						to='/account'
						disabled={!isAuthenticated}
						onClick={closeDrawer}
						fullWidth={true}
						variant='contained'
						disableElevation={true}
						color='primary'
						className={styles.accountButton}>
						<AccountCircleOutlinedIcon className={styles.accountIcon} />
						<Typography
							className={styles.accountTitle}
							variant='body1'
							style={{
								color: isAuthenticated ? 'white' : 'gray',
							}}>
							Account
						</Typography>
					</Button>
				</div>
			);
		},
		// flyout/location deps are required for the isActive/closeDrawer callback functions
		//  -> we use the underlying deps here instead of having to use useCallback() for each callback
		[isAuthenticated, flyoutDrawerOpen, location.pathname]
	);

	const authButtons = useMemo(() => {
		const handleLogin = () => {
			//document.cookie = 'msal.interaction.status=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
			const request: RedirectRequest = {
				...(loginRequest as RedirectRequest),
				loginHint: instance.getActiveAccount()?.username ?? '',
			};

			instance.loginRedirect(request).catch((error) => {
				console.error(error);
			});
		};

		const handleLogout = () => {
			instance
				.logoutRedirect({
					account: instance.getActiveAccount(),
				} as EndSessionRequest)
				.catch((error) => {
					console.error(error);
				});
		};

		return (
			<>
				{isAuthenticated && <ToolbarButton onClick={handleLogout}>Logout</ToolbarButton>}
				{!isAuthenticated && <ToolbarButton onClick={handleLogin}>Login</ToolbarButton>}
			</>
		);
	}, [isAuthenticated, instance]);

	return (
		<Box height='100%'>
			<AppBar color='primary' position='sticky' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					<MenuButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { lg: 'none' } }}>
						{flyoutDrawerOpen ? <CloseIcon /> : <MenuIcon />}
					</MenuButton>
					<Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
						Paveset IC
					</Typography>

					{/* add login/logout button based on auth status */}
					{authButtons}
				</Toolbar>
			</AppBar>
			<Box
				component='nav'
				sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
				aria-label='report viewer pages'>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					variant='temporary'
					open={flyoutDrawerOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', lg: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}>
					{drawer}
				</Drawer>

				<Drawer
					variant='permanent'
					sx={{
						display: { xs: 'none', lg: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component='main'
				className={styles.mainContainer}
				sx={{
					flexGrow: 1,
					pr: 0,
					pt: 0,
					pb: 0,
					pl: { xs: 0, lg: `${drawerWidth}px` },
					m: 0,
					width: '100%',
					height: `calc(100% - ${appBarHeight}px)`,
					bgcolor: 'background.default',
				}}>
				{/* add child pages (react-router routes) inside the main container */}
				{children}
			</Box>
		</Box>
	);
}

export default Layout;
