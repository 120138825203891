import type { MiddlewareAPI, ThunkDispatch } from '@reduxjs/toolkit';
import type { AllActions, AllReducerActions, RootState } from '../app/store';
import { dataPickerPersistHandlers } from '../features/dataPicker/dataPickerSlice';
import type { PersistHandlers } from '../tools/persistHelpers';

function isAction(action: unknown): action is AllActions {
    return (action as AllActions).type !== undefined;
}

type Dispatch = ThunkDispatch<RootState, unknown, AllActions>;
type Store = MiddlewareAPI<Dispatch, RootState>;

// create the combined persist handler from each reducer slice (non-api slices)
const persistHandlers: PersistHandlers<AllReducerActions> = {
    ...dataPickerPersistHandlers
};

const persistStateMiddleware =
    (store: Store) =>
        (next: Dispatch) =>
            (action: unknown) => {
                if (isAction(action)) {
                    const result = next(action);

                    // cast the action.type to the union type of all reducer (non-api) actions
                    const actionType = action.type as AllReducerActions['type'];

                    // use the action type to lookup the associated persist handler (if any)
                    const persistHandler = persistHandlers[actionType];

                    // execute the persist handler if it exists
                    if (persistHandler) {
                        persistHandler(store.getState());
                    }

                    return result;
                }
            };

export default persistStateMiddleware;