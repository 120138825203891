import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { Point, Rect } from '@shared/geometry/core/Coordinate';
import { diffPoints } from './helpers';
import { CanvasState } from './CanvasState';
import useStartupEffect from '@hooks/useStartupEffect';

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1.0 } = window;

interface CanvasControls {
	canvasRef: React.RefObject<HTMLCanvasElement>;
	canvasBgRef: React.RefObject<HTMLCanvasElement>;

	stateRef: React.RefObject<CanvasState>;

	center: (p: Point) => void;
	zoomBy: (factor: number, point: Point, worldUnits: boolean) => void;
	zoomTo: (rect: Rect) => void;

	invalidate: () => void;
}

function calculateMousePosition(canvas: HTMLCanvasElement, event: MouseEvent | WheelEvent | Touch): Point {
	const rect = canvas.getBoundingClientRect();

	return {
		x: event.clientX * ratio - rect.left * ratio,
		y: event.clientY * ratio - rect.top * ratio,
	};
}

interface CanvasOptions {
	canvasWidth: number;
	canvasHeight: number;
	offset: Point;

	drawBackground?: (ctx: CanvasRenderingContext2D) => void;
	draw?: (ctx: CanvasRenderingContext2D) => void;
	drawForeground?: (ctx: CanvasRenderingContext2D) => void;
}

export const useCanvas = (props: CanvasOptions): CanvasControls => {
	const { canvasHeight, canvasWidth, offset, draw, drawBackground } = props;

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasBgRef = useRef<HTMLCanvasElement>(null);

	const contextRef = useRef<CanvasRenderingContext2D | null>(null);
	const contextBgRef = useRef<CanvasRenderingContext2D | null>(null);

	const [drawFlag, setDrawFlag] = useState(false);
	const stateRef = useRef<CanvasState>(new CanvasState());
	const lastMousePosRef = useRef<Point | null>(null);
	const prevTranslateRef = useRef<Point>({ x: 0, y: 0 });
	const firstDraw = useRef(true);
	const pinchHandler = useRef<PinchHandler | null>(null);
	const busy = useRef(false);
	const offsetRef = useRef(offset);

	useEffect(() => {
		offsetRef.current = offset;
	}, [offset, offsetRef]);

	const invalidate = useCallback(() => {
		setDrawFlag((prev) => !prev);
	}, []);

	// setup canvas and set context
	useLayoutEffect(() => {
		if (canvasRef.current) {
			// get new drawing context
			const renderCtx = canvasRef.current.getContext('2d');
			const renderBgCtx = canvasBgRef.current?.getContext('2d', { alpha: false });

			if (renderCtx) {
				contextRef.current = renderCtx;
			}

			if (renderBgCtx) {
				contextBgRef.current = renderBgCtx;
			}

			if (firstDraw.current) {
				firstDraw.current = false;
				stateRef.current.translateTo(0.0, canvasHeight * ratio);
			}
		}
	}, [canvasHeight, canvasWidth]);

	useLayoutEffect(() => {
		if (busy.current) return;

		const context = contextRef.current;
		const contextBg = contextBgRef.current;

		const state = stateRef.current;
		if (context && state) {
			context.resetTransform();
			context.clearRect(0, 0, canvasWidth * ratio, canvasHeight * ratio);

			const transform = state.getTransform();
			state.clearFlags(); // reset dirty flag

			context.setTransform(transform);

			if (contextBg) {
				contextBg.resetTransform();
				contextBg.fillStyle = 'white';
				contextBg.fillRect(0, 0, canvasWidth * ratio, canvasHeight * ratio);
				contextBg.setTransform(transform);
				if (drawBackground) {
					drawBackground(contextBg);
				}
			}

			if (draw) {
				draw(context);
			}
		}
	}, [canvasHeight, canvasWidth, drawFlag, draw, drawBackground]);

	// functions for panning
	const mouseMove = useCallback(
		(event: MouseEvent) => {
			const context = contextRef.current;
			const state = stateRef.current;
			if (context && state) {
				const lastMousePos = lastMousePosRef.current;
				const currentMousePos = { x: event.clientX, y: event.clientY }; // use document so can pan off element
				const mouseDiff = diffPoints(currentMousePos, lastMousePos!);
				state.translateTo(
					prevTranslateRef.current.x + mouseDiff.x * ratio,
					prevTranslateRef.current.y + mouseDiff.y * ratio
				);
				invalidate();
			}
		},
		[invalidate]
	);

	const mouseUp = useCallback(() => {
		document.removeEventListener('mousemove', mouseMove);
		document.removeEventListener('mouseup', mouseUp);
		document.removeEventListener('mouseleave', mouseUp);
	}, [mouseMove]);

	const onMouseDown = (event: MouseEvent) => {
		event.preventDefault();

		if (!contextRef.current) {
			contextRef.current = canvasRef.current!.getContext('2d');
		}

		const state = stateRef.current;

		// add move/up listeners to document instead of canvas so panning doesn't
		// cancel when the mouse moves outside the canvas region
		document.addEventListener('mousemove', mouseMove);
		document.addEventListener('mouseup', mouseUp);
		document.addEventListener('mouseleave', mouseUp);

		lastMousePosRef.current = { x: event.clientX, y: event.clientY };
		prevTranslateRef.current = { x: state.translateX, y: state.translateY };
	};

	const touchMove = useCallback(
		(event: TouchEvent) => {
			if (event.touches.length > 1) {
				return;
			}

			const touch = event.touches[0];
			const context = contextRef.current;
			const state = stateRef.current;
			if (context && state) {
				const lastMousePos = lastMousePosRef.current;
				const currentMousePos = { x: touch.clientX, y: touch.clientY };

				const mouseDiff = diffPoints(currentMousePos, lastMousePos!);
				state.translateTo(
					prevTranslateRef.current.x + mouseDiff.x * ratio,
					prevTranslateRef.current.y + mouseDiff.y * ratio
				);
				invalidate();
			}
		},
		[invalidate]
	);

	const touchEnd = useCallback(() => {
		console.log('touch end');

		canvasRef.current!.removeEventListener('touchmove', touchMove);
		canvasRef.current!.removeEventListener('touchend', touchEnd);
	}, [touchMove]);

	const onTouchStart = (event: TouchEvent) => {
		if (event.touches.length !== 1) {
			return;
		}

		event.preventDefault();
		console.log('touch start');

		canvasRef.current!.addEventListener('touchmove', touchMove);
		canvasRef.current!.addEventListener('touchend', touchEnd);
		const state = stateRef.current;

		lastMousePosRef.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
		prevTranslateRef.current = { x: state.translateX, y: state.translateY };
	};

	const onDoubleClick = (event: MouseEvent) => {
		event.preventDefault();

		const pos = calculateMousePosition(canvasRef.current!, event);

		const transform = stateRef.current.getTransform().inverse();
		const posTransform = transform.transformPoint(pos);

		const posReal: Point = {
			x: posTransform.x + offsetRef.current.x,
			y: posTransform.y + offsetRef.current.y,
		};

		console.log(event);
		console.log(pos);
		console.log(ratio);
		console.log(stateRef.current.scale);
		console.log(`Double click at ${posReal.x.toFixed(3)}, ${posReal.y.toFixed(3)}`);
	};

	useStartupEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		canvas.setAttribute('willReadFrequently', 'true');

		const ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.canvas.width = canvasWidth * ratio;
			ctx.canvas.height = canvasHeight * ratio;

			contextRef.current = ctx;
		}

		const ctxBg = canvasBgRef.current?.getContext('2d', { alpha: false });
		if (ctxBg) {
			ctxBg.canvas.width = canvasWidth * ratio;
			ctxBg.canvas.height = canvasHeight * ratio;

			contextBgRef.current = ctxBg;
		}

		const handleWheel = (event: WheelEvent) => {
			event.preventDefault();
			if (busy.current) {
				return;
			}

			busy.current = true;

			const p = calculateMousePosition(canvasRef.current!, event);
			const factor = event.deltaY < 0 ? 1.1 : 0.9;

			stateRef.current.zoomBy(factor, p, false);
			busy.current = false;
			invalidate();
		};

		canvas.addEventListener('mousedown', onMouseDown);
		canvas.addEventListener('wheel', handleWheel);
		canvas.addEventListener('touchstart', onTouchStart);
		canvas.addEventListener('dblclick', onDoubleClick);

		pinchHandler.current = new PinchHandler(canvas, stateRef.current, invalidate);
		return () => {
			canvas.removeEventListener('mousedown', onMouseDown);
			canvas.removeEventListener('wheel', handleWheel);
			canvas.removeEventListener('touchstart', onTouchStart);
			canvas.removeEventListener('dblclick', onDoubleClick);

			pinchHandler.current?.detachListeners();
		};
	});

	const center = useCallback(
		(p: Point) => {
			stateRef.current.center(
				{
					x: 0,
					y: 0,
					width: canvasWidth * ratio,
					height: canvasHeight * ratio,
				},
				{
					x: p.x,
					y: p.y,
				}
			);

			invalidate();
		},
		[canvasHeight, canvasWidth, invalidate]
	);

	const zoomTo = useCallback(
		(rect: Rect) => {
			const zoomX = canvasWidth / rect.width;
			const zoomY = canvasHeight / rect.height;
			const zoom = Math.min(zoomX, zoomY);

			const p = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
			stateRef.current.zoomBy(zoom / stateRef.current.scale, p, false);
			center(p);

			invalidate();
		},
		[canvasHeight, canvasWidth, invalidate, stateRef, center]
	);

	return {
		canvasRef,
		canvasBgRef,
		stateRef,
		center,
		zoomBy: (factor: number, point: Point, worldUnits: boolean) => {
			stateRef.current.zoomBy(factor, point, worldUnits);
			invalidate();
		},
		zoomTo,
		invalidate,
	};
};

class PinchHandler {
	initialDistance: number | null = null;
	zoomLevel: number = 1; // Start with no zoom
	centerPoint: { x: number; y: number } | null = null;

	constructor(
		private element: HTMLCanvasElement,
		private state: CanvasState,
		private invalidate: () => void
	) {
		this.element.addEventListener('touchstart', this.handleTouchStart, { passive: false });
		this.element.addEventListener('touchmove', this.handleTouchMove, { passive: false });
		this.element.addEventListener('touchend', this.handleTouchEnd);
	}

	handleTouchStart = (event: TouchEvent) => {
		if (event.touches.length === 2) {
			console.log('pinch start');
			// Prevent the document from scrolling.
			event.preventDefault();

			// Calculate the initial distance between the two touches.
			this.initialDistance = this.calculateDistance(event.touches[0], event.touches[1]);
			// Calculate the initial center point of the two touches.
			this.centerPoint = this.calculateCenterPoint(event.touches[0], event.touches[1]);
		}
	};

	handleTouchMove = (event: TouchEvent) => {
		if (event.touches.length === 2) {
			// Prevent the document from scrolling.
			event.preventDefault();

			// Calculate the new distance between the two touches.
			const newDistance = this.calculateDistance(event.touches[0], event.touches[1]);
			if (this.initialDistance != null) {
				// Calculate the zoom level based on the change in distance.
				this.zoomLevel = newDistance / this.initialDistance;
				console.log(`Zoom Level: ${this.zoomLevel}`);

				this.state.zoomBy(this.zoomLevel, this.calculateCenterPoint(event.touches[0], event.touches[1]));
				this.invalidate();
			}

			// Update the center point for the current touch positions.
			this.centerPoint = this.calculateCenterPoint(event.touches[0], event.touches[1]);
			console.log(`Center Point: x: ${this.centerPoint.x}, y: ${this.centerPoint.y}`);
		}
	};

	handleTouchEnd = () => {
		// Reset the initial distance and center point when the touches end.
		this.initialDistance = null;
		this.centerPoint = null;
	};

	calculateDistance(touch1: Touch, touch2: Touch): number {
		const dx = touch1.pageX - touch2.pageX;
		const dy = touch1.pageY - touch2.pageY;
		return Math.sqrt(dx * dx + dy * dy) / ratio;
	}

	calculateCenterPoint(touch1: Touch, touch2: Touch): { x: number; y: number } {
		const pos1 = calculateMousePosition(this.element, touch1);
		const pos2 = calculateMousePosition(this.element, touch2);
		// return {
		//   x: (touch1.pageX + touch2.pageX) / 2,
		//   y: (touch1.pageY + touch2.pageY) / 2,
		// };

		return {
			x: (pos1.x + pos2.x) / 2,
			y: (pos1.y + pos2.y) / 2,
		};
	}

	detachListeners(): void {
		this.element.removeEventListener('touchstart', this.handleTouchStart);
		this.element.removeEventListener('touchmove', this.handleTouchMove);
		this.element.removeEventListener('touchend', this.handleTouchEnd);
	}
}
