import { shallowEqual, useDispatch, useSelector, type Selector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useShallowAppSelector = <TSelect>(selector: Selector<RootState, TSelect>) => {
  return useAppSelector(selector, shallowEqual);
}

// export const useAppSelector: TypedUseSelectorHook<RootState> = <TSelect>(selector: Selector<RootState, TSelect>): TSelect => {
//   return useSelector<RootState, TSelect>(selector, shallowEqual as EqualityFn<TSelect>);
// };