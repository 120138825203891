import { isDataItem, type FileOrDataItem } from '../features/dataPicker/dataPickerSlice';
import DynapacCSV from './DynapacCSV';
import DynapacJSON from './DynapacJSON';
import type { RollerData } from './rollerTypes';

export default async function readRollerData(filesOrKeys: FileOrDataItem[]): Promise<RollerData> {
  if (filesOrKeys.length === 0) {
    throw new Error('No files to read');
  }

  const files = filesOrKeys.filter((fileOrKey) => fileOrKey instanceof File) as File[];
  const keys = filesOrKeys.filter(isDataItem).map((item) => item.key);

  if (files.length > 0) {
    return await DynapacCSV.read(files);
  }

  return await DynapacJSON.read(keys);
}