import { useEffect, useCallback, useRef, useState } from 'react';
import useStartupEffect from '@hooks/useStartupEffect';
import type { Point, Rect } from '@shared/geometry/core/Coordinate';
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
} from '@mui/material';
import type { RollerCell, RollerData } from '../../../data/rollerTypes';
import { useAppSelector } from '@app/hooks';
import { useCanvas } from './useCanvas';
import { selectBackgroundImage } from '../viewerSlice';
import { selectBoundaries, selectRollerData, selectSetup } from '../../analysis/analysisSlice';
import type { CompactionSpecification, FailureReason, ReportType } from '@analysis/RollerAnalysis';
import { useIsXSmall } from '@hooks/responsiveHooks';
import { time } from '../../../tools/helpers';
import '../../../tools/canvasHelpers';
import { failureColors, getBaseMapCellColor, getCellColor, isWithinRect } from './helpers';
import { canvasRGB } from 'stackblur-canvas';

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1 } = window;

interface DrawingState {
	ctx: CanvasRenderingContext2D;
	offset: Point;
	clip?: Rect;
}

const cellSize = 0.45;
const cellOffset = cellSize / 2;

function drawCell(ctx: CanvasRenderingContext2D, coords: Point, offset: Point, size: number) {
	ctx.fillRect(coords.x - offset.x - size / 2, coords.y - offset.y - size / 2, size, size);
}

/**
 * Draws the specified RollerCell to the canvas
 * @param cell The cell to draw
 * @param state The current DrawingState
 * @param colorGetter The function to get the color for the cell
 */
function drawRollerCell(cell: RollerCell, state: DrawingState, colorGetter: (cell: RollerCell) => string) {
	const coords = cell.coords;
	const { ctx, offset, clip } = state;

	if (clip && !isWithinRect(coords, clip)) {
		return;
	}

	// no spec is specified, so just draw color-coded pass count data
	ctx.fillStyle = colorGetter(cell);
	ctx.strokeStyle = ctx.fillStyle;
	ctx.fillRect(coords.x - offset.x - cellOffset, coords.y - offset.y - cellOffset, cellSize, cellSize);

	// ctx.beginPath();
	// ctx.arc(coords.x - offset.x - 0.2, coords.y - offset.y - 0.2, 0.2, 0, 2 * Math.PI);
	// ctx.fill();
}

// Function to draw the north arrow
function drawNorthArrow(
	ctx: CanvasRenderingContext2D,
	origin: Point,
	color = 'black',
	arrowHeight = 25,
	arrowWidth = 10
) {
	ctx.beginPath();
	// Draw the stem of the arrow
	ctx.moveTo(origin.x, origin.y);
	ctx.lineTo(origin.x, origin.y + arrowHeight);

	// Draw the head of the arrow
	ctx.moveTo(origin.x - arrowWidth / 2, origin.y + arrowHeight * 0.6);
	ctx.lineTo(origin.x, origin.y + arrowHeight);
	ctx.lineTo(origin.x + arrowWidth / 2, origin.y + arrowHeight * 0.6);

	// Set style & stroke path
	ctx.strokeStyle = color;
	ctx.lineCap = 'round';
	ctx.lineJoin = 'round';
	ctx.lineWidth = 2;
	ctx.stroke();

	// Set style for the text
	ctx.font = '15px Arial';
	ctx.fillStyle = color;
	ctx.textAlign = 'center';
	// Draw the text 'N' below the arrow
	ctx.scale(1, -1);
	ctx.fillText('N', origin.x, -origin.y + 15); // Adjust the position as needed
	ctx.scale(1, -1);
}

function drawRollerData(
	rollerData: RollerData,
	state: DrawingState,
	analysisType: ReportType,
	spec?: CompactionSpecification
) {
	const { ctx, offset } = state;
	const clip = state.clip ?? {
		x: Number.MIN_SAFE_INTEGER,
		y: Number.MIN_SAFE_INTEGER,
		width: Number.MAX_SAFE_INTEGER,
		height: Number.MAX_SAFE_INTEGER,
	};

	if (spec) {
		time(
			() => {
				const cells = rollerData.groupedCells.get(spec);
				if (!cells) {
					console.log('No cells found for spec: ', spec);
					return;
				}

				ctx.globalAlpha = 0.25;
				ctx.fillStyle = 'limegreen';
				for (const passed of cells.passed.outside.filter((c) => isWithinRect(c.coords, clip))) {
					drawCell(ctx, passed.coords, offset, cellSize);
				}
				for (const reason in cells.failed.outside) {
					const result = reason as FailureReason;
					ctx.fillStyle = failureColors[result];
					for (const failed of cells.failed.outside[result].filter((c) => isWithinRect(c.coords, clip))) {
						drawCell(ctx, failed.coords, offset, cellSize);
					}
				}
				ctx.globalAlpha = 1.0;

				ctx.fillStyle = 'limegreen';
				for (const passed of cells.passed.inside.filter((c) => isWithinRect(c.coords, clip))) {
					drawCell(ctx, passed.coords, offset, cellSize);
				}

				for (const reason in cells.failed.inside) {
					const result = reason as FailureReason;
					ctx.fillStyle = failureColors[result];
					for (const failed of cells.failed.inside[result].filter((c) => isWithinRect(c.coords, clip))) {
						drawCell(ctx, failed.coords, offset, cellSize);
					}
				}
			},
			'Draw Roller Cells (Grouped)',
			'noconsole'
		);
	} else {
		time(
			() => {
				const inside = rollerData.getCells().where((c) => c.isWithinBoundary);
				const outside = rollerData.getCells().where((c) => !c.isWithinBoundary);
				const colorGetter =
					analysisType === 'ASPHALT'
						? getCellColor
						: (cell: RollerCell) => getBaseMapCellColor(cell, 'firstPass');

				ctx.globalCompositeOperation = 'source-over';

				ctx.globalAlpha = 0.25;
				for (const cell of outside) {
					drawRollerCell(cell, state, colorGetter);
				}

				ctx.globalAlpha = 1.0;
				for (const cell of inside) {
					drawRollerCell(cell, state, colorGetter);
				}
			},
			'Drawing Roller Cells (Pass Count)',
			'noconsole'
		);
	}
}

export default function Canvas() {
	const containerRef = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState(100);
	const [width, setWidth] = useState(100);
	const [offset, setOffset] = useState<Point>({ x: 0.0, y: 0.0 });
	const backgroundImage = useAppSelector(selectBackgroundImage);
	const rollerData = useAppSelector(selectRollerData);
	const boundaries = useAppSelector(selectBoundaries);
	const setup = useAppSelector(selectSetup);
	const [spec, setSpec] = useState(0);
	const isXSmall = useIsXSmall();
	const [smooth, setSmooth] = useState(false);

	const drawBackground = useCallback(
		(ctx: CanvasRenderingContext2D) => {
			if (backgroundImage) {
				const iwidth = backgroundImage.image.width * backgroundImage.pixelSize;
				const iheight = backgroundImage.image.height * backgroundImage.pixelSize;
				const x = backgroundImage.position.x - offset.x;
				const y = offset.y - backgroundImage.position.y;
				ctx.scale(1, -1);
				ctx.drawImage(backgroundImage.image, x, y, iwidth, iheight);
				ctx.scale(1, -1);
			}
		},
		[backgroundImage, offset]
	);

	const draw = useCallback(
		(ctx: CanvasRenderingContext2D) => {
			// grab the current transform matrix and extract the scale value
			//  -> we only calculate the X-scale because we assume the scale is uniform
			const matrix = ctx.getTransform();
			const inverse = matrix.inverse();
			const scale = Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b);
			const bounds = rollerData?.calculateBounds();

			const p = inverse.transformPoint({ x: 0, y: height * ratio });
			const visibleBounds: Rect = {
				x: p.x + offset.x,
				y: p.y + offset.y,
				width: (width * ratio) / scale,
				height: (height * ratio) / scale,
			};

			const state: DrawingState = { ctx, offset, clip: visibleBounds };
			const specToDraw = spec === 0 ? undefined : setup?.specifications.find((s) => s.specNumber === spec);

			if (rollerData && setup) {
				drawRollerData(rollerData, state, setup.analysisType, specToDraw);

				if (smooth) {
					canvasRGB(ctx.canvas, 0, 0, ctx.canvas.width, ctx.canvas.height, scale);
				}
			}

			if (boundaries) {
				ctx.strokeStyle = 'red';
				ctx.lineWidth = 0.25; // 3 / scale;

				for (const boundary of boundaries) {
					ctx.beginPath();
					ctx.moveTo(boundary.coords[0].x - offset.x, boundary.coords[0].y - offset.y);
					for (const vertex of boundary.coords.slice(1)) {
						ctx.lineTo(vertex.x - offset.x, vertex.y - offset.y);
					}
					ctx.stroke();
				}
			}

			if (bounds) {
				const origin = {
					x: bounds.max.x + 25 - offset.x,
					y: bounds.max.y - 5 - offset.y,
				};

				drawNorthArrow(ctx, origin, backgroundImage ? 'white' : 'black');
			}

			// try {
			//   const unions = rollerData?.calculatePassPolygons();
			//   if (unions) {
			//     ctx.fillStyle = 'rgba(255, 0, 0, 1)';
			//     ctx.strokeStyle = 'black';
			//     ctx.lineWidth = 0.1;
			//     ctx.lineJoin = 'bevel';
			//     ctx.lineCap = 'butt';
			//     for (const key of unions.keys().toArray().slice(0, 1)) {
			//       const union = unions.get(key);

			//       if (union) {
			//         for (const region of union) {
			//           ctx.beginPath();
			//           ctx.moveTo(region.vertices[0][0] - offset.x, region.vertices[0][1] - offset.y);
			//           for (const vertex of region.vertices.slice(1)) {
			//             ctx.lineTo(vertex[0] - offset.x - 0.2, vertex[1] - offset.y - 0.2);
			//           }
			//           ctx.closePath();
			//           ctx.fill();
			//         }
			//       }
			//     }
			//   }

			//   // if (rollerData && bounds) {
			//   //   const grid = calculateBinaryGrid(rollerData, 1, bounds);

			//   //   ctx.fillStyle = 'blue';
			//   //   ctx.globalAlpha = 0.75;

			//   //   for (let y = 0; y < grid.length; y++) {
			//   //     for (let x = 0; x < grid[y].length; x++) {
			//   //       if (grid[y][x]) {
			//   //         ctx.fillRect(bounds.min.x + x * cellSize - offset.x - 0.2, bounds.min.y + y * cellSize - offset.y - 0.2, cellSize, cellSize);
			//   //       }
			//   //     }
			//   //   }
			//   // }
			// } catch (error) {
			//   console.error(error);
			// }
		},
		[rollerData, height, offset, width, backgroundImage, boundaries, setup, spec, smooth]
	);

	const { canvasRef, canvasBgRef, stateRef, center, zoomTo } = useCanvas({
		canvasWidth: width,
		canvasHeight: height,
		offset,
		draw,
		drawBackground,
	});

	useEffect(() => {
		if (rollerData) {
			//console.log('init canvas map');

			const bounds = rollerData.calculateBounds();

			setOffset({ x: bounds.min.x, y: bounds.min.y });
			// center({
			//   x: bounds.centre.x - bounds.min.x,
			//   y: bounds.centre.y - bounds.min.y,
			// });

			// zoom & center to fit the roller data (this doesn't take into account the extra imagery space added)
			zoomTo({
				x: 0,
				y: 0,
				width: (bounds.max.x - bounds.min.x) / ratio,
				height: (bounds.max.y - bounds.min.y) / ratio,
			});

			center({ x: bounds.centre.x - bounds.min.x, y: bounds.centre.y - bounds.min.y });
		}
	}, [rollerData, center, stateRef, zoomTo]);

	const updateSize = () => {
		const container = containerRef.current;
		if (container) {
			setHeight(container.clientHeight);
			setWidth(container.clientWidth);

			//console.log(`set canvas container size: ${container.clientWidth} x ${container.clientHeight}px`);
		}
	};

	const handleResize = () => {
		updateSize();
	};

	useStartupEffect(() => {
		updateSize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const handleSpecChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSpec(parseInt(event.target.value));
	};

	return (
		<Paper
			elevation={2}
			style={{
				overflow: 'hidden',
				height: '100%',
				borderRadius: '16px',
				display: 'flex',
				position: 'relative',
			}}>
			<div
				ref={containerRef}
				style={{
					height: '100%',
					width: '100%',
				}}>
				<canvas
					ref={canvasBgRef}
					width={width * ratio}
					height={height * ratio}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: `${width}px`,
						height: `${height}px`,
					}}
				/>
				<canvas
					ref={canvasRef}
					width={width * ratio}
					height={height * ratio}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: `${width}px`,
						height: `${height}px`,
					}}
				/>
			</div>

			{/* <Button
        onClick={onTestClick}
        variant='contained'
        sx={{
          position: 'relative',
          display: 'block',
          height: 64,
          bottom: 64,
          width: 200,
          borderRadius: '0px 16px 0px 16px',
        }}>
        CENTER
      </Button> */}
			<Box
				sx={{
					position: 'absolute',
					display: 'block',
					alignSelf: 'flex-end',
					backgroundColor: 'rgba(255, 255, 255, 0.85)',
					pl: 2,
					pt: 1,
					pr: 2,
					borderTopRightRadius: '16px',
					borderBottomLeftRadius: '16px',
				}}>
				<FormControl>
					<FormLabel
						id='spec-display-radio-buttons-group'
						sx={{ color: '#556cd6', fontWeight: 500, fontSize: 18 }}>
						Display Setting
					</FormLabel>
					<RadioGroup
						aria-labelledby='spec-display-radio-buttons-group'
						name='spec-radio-buttons-group'
						row={!isXSmall}
						value={spec}
						onChange={handleSpecChange}>
						<FormControlLabel
							key={0}
							value={0}
							control={
								<Radio
									sx={{
										'& .MuiSvgIcon-root': {
											fontSize: { xs: '1.1rem', sm: '1.5rem' },
										},
									}}
								/>
							}
							label={setup?.analysisType === 'ASPHALT' ? 'Pass Count' : 'CMV'}
							sx={{
								color: 'black',
								'& .MuiTypography-root': {
									fontSize: { xs: 13, sm: 16 },
								},
							}}
						/>
						{setup?.specifications.map((spec) => (
							<FormControlLabel
								key={spec.specNumber}
								value={spec.specNumber}
								control={
									<Radio
										sx={{
											pl: { sm: 2 },
											'& .MuiSvgIcon-root': {
												fontSize: { xs: '1.1rem', sm: '1.5rem' },
											},
										}}
									/>
								}
								label={`Spec #${spec.specNumber}`}
								sx={{
									color: 'black',
									'& .MuiTypography-root': {
										fontSize: { xs: 13, sm: 16 },
									},
								}}
							/>
						))}
					</RadioGroup>
				</FormControl>

				<Divider />

				<FormGroup>
					<FormControlLabel
						control={<Checkbox value={smooth} onChange={(event, checked) => setSmooth(checked)} />}
						label='Smoothing'
					/>
				</FormGroup>
			</Box>
		</Paper>
	);
}
