import { useIsXSmall } from '@hooks/responsiveHooks';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './DataPickerSection.module.css';
import type { SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export interface DataPickerSectionProps {
	children: React.ReactNode;
	containerStyles?: SxProps<Theme>;

	section: number;
	title: string;
	titleComponent?: (titleHeight: number, titleClass: string) => React.ReactNode;

	isComplete: boolean;
}

const DataPickerSection = (props: DataPickerSectionProps) => {
	const { section, title, children, isComplete } = props;
	const isXSmall = useIsXSmall();

	const titleHeight = isXSmall ? 48 : 56;
	const numberingClass = isXSmall ? styles['numberingTextSmall'] : styles.numberingText;
	const titleClass = isXSmall ? styles['titleTextSmall'] : styles.titleText;

	return (
		<Paper
			elevation={3}
			sx={{
				overflow: 'hidden',
				width: '100%',
				pl: 1,
				pr: 1,
				pb: 1,
				borderRadius: 4,
				mt: { xs: 'unset', sm: 'auto!important' },
				...props.containerStyles,
			}}>
			<Stack direction='column' height='100%'>
				<Stack direction='row' width='100%' height={titleHeight}>
					<Typography
						variant='h5'
						bgcolor={isComplete ? 'primary.light' : 'error.light'}
						alignContent='center'
						color='white'
						className={numberingClass}>
						{section}
					</Typography>

					{props.titleComponent ? (
						props.titleComponent(titleHeight, titleClass)
					) : (
						<Typography
							height={titleHeight}
							width='100%'
							textAlign='center'
							alignContent='center'
							variant='h6'
							className={titleClass}>
							{title}
						</Typography>
					)}
				</Stack>

				<Divider orientation='horizontal' />

				{children}
			</Stack>
		</Paper>
	);
};

export default DataPickerSection;
