import { type V3 } from './Vector3';

/**
 * A 3x3 matrix that just wraps a Float64Array.
 */
export type M3 = Float64Array;

function add(a: M3, b: M3, output?: M3): M3 {
    const result = output ?? new Float64Array(9);

    for (let i = 0; i < 9; i++) {
        result[i] = a[i] + b[i];
    }
    return result;
}

function multiply(a: M3, b: M3, output?: M3): M3 {
    const result = output ?? new Float64Array(9);
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            result[i * 3 + j] = 0;
            for (let k = 0; k < 3; k++) {
                result[i * 3 + j] += a[i * 3 + k] * b[k * 3 + j];
            }
        }
    }
    return result;
}

function multiplyScalar(matrix: M3, scalar: number, output?: M3): M3 {
    const result = output ?? new Float64Array(9);
    for (let i = 0; i < 9; i++) {
        result[i] = matrix[i] * scalar;
    }
    return result;
}

function multiplyVector(matrix: M3, vector: V3, output?: V3): V3 {
    const result = output ?? new Float64Array(3);
    for (let i = 0; i < 3; i++) {
        result[i] = 0;
        for (let j = 0; j < 3; j++) {
            result[i] += matrix[i * 3 + j] * vector[j];
        }
    }
    
    return result;
}

function determinant(matrix: M3): number {
    return matrix[0] * (matrix[4] * matrix[8] - matrix[5] * matrix[7]) -
        matrix[1] * (matrix[3] * matrix[8] - matrix[5] * matrix[6]) +
        matrix[2] * (matrix[3] * matrix[7] - matrix[4] * matrix[6]);
}

function transpose(matrix: M3, output?: M3): M3 {
    const result = output ?? new Float64Array(9);
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            result[i * 3 + j] = matrix[j * 3 + i];
        }
    }
    return result;
}

function identity(output?: M3): M3 {
    if (output) {
        output.set([1, 0, 0, 0, 1, 0, 0, 0, 1]);
        return output;
    } else {
        return new Float64Array([
            1, 0, 0,
            0, 1, 0,
            0, 0, 1
        ]);
    }
}

// export class Matrix3 {
//     public data: Float64Array;

//     constructor(values: Float64Array) {
//         this.data = values;
//     }

//     public add(other: Matrix3): Matrix3 {
//         const result = new Float64Array(9);
//         for (let i = 0; i < 9; i++) {
//             result[i] = this.data[i] + other.data[i];
//         }
//         return new Matrix3(result);
//     }

//     public multiply(other: Matrix3): Matrix3 {
//         const result = new Float64Array(9);
//         for (let i = 0; i < 3; i++) {
//             for (let j = 0; j < 3; j++) {
//                 result[i * 3 + j] = 0;
//                 for (let k = 0; k < 3; k++) {
//                     result[i * 3 + j] += this.data[i * 3 + k] * other.data[k * 3 + j];
//                 }
//             }
//         }
//         return new Matrix3(result);
//     }

//     public multiplyScalar(scalar: number): Matrix3 {
//         const result = new Float64Array(9);
//         for (let i = 0; i < 9; i++) {
//             result[i] = this.data[i] * scalar;
//         }
//         return new Matrix3(result);
//     }

//     public multiplyVector(vector: Float64Array): Float64Array {
//         const result = new Float64Array(3);
//         for (let i = 0; i < 3; i++) {
//             result[i] = 0;
//             for (let j = 0; j < 3; j++) {
//                 result[i] += this.data[i * 3 + j] * vector[j];
//             }
//         }
        
//         return result;
//     }

//     public determinant(): number {
//         return this.data[0] * (this.data[4] * this.data[8] - this.data[5] * this.data[7]) -
//             this.data[1] * (this.data[3] * this.data[8] - this.data[5] * this.data[6]) +
//             this.data[2] * (this.data[3] * this.data[7] - this.data[4] * this.data[6]);
//     }

//     public transpose(): Matrix3 {
//         const result = new Float64Array(9);
//         for (let i = 0; i < 3; i++) {
//             for (let j = 0; j < 3; j++) {
//                 result[i * 3 + j] = this.data[j * 3 + i];
//             }
//         }
//         return new Matrix3(result);
//     }

//     public static identity(): Matrix3 {
//         return new Matrix3(new Float64Array([
//             1, 0, 0,
//             0, 1, 0,
//             0, 0, 1
//         ]));
//     }
// }

export default {
    add,
    multiply,
    multiplyScalar,
    multiplyVector,
    determinant,
    transpose,
    
    identity,
} as const;