import dayjs, { type Dayjs } from 'dayjs';
import { getDynapacFile } from './fileCache';
import { mergeRollerData, RollerCell, RollerData, type AmplitudeSetting, type GNSSQuality, type OverviewInfo, type RollerPass } from './rollerTypes';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';
import { Datum, type GridCoordinate } from '@shared/geometry/core/Coordinate';

function dynapacReviver(key: string, value: unknown) {
  if (key === 'utcdate' && typeof value === 'string') {
    return dayjs.utc(value);
  }

  if (key === 'amplitudesetting' && typeof value === 'number') {
    const rounded = Math.floor(value);
    if (rounded === 0) {
      return 'Static';
    } else if (rounded === 1) {
      return 'Low';
    } else if (rounded === 2) {
      return 'High';
    } else {
      return 'Unknown';
    }
  }

  if (key === 'gnssquality' && typeof value === 'number') {
    const rounded = Math.floor(value);
    if (rounded === 0) {
      return 'Low';
    } else if (rounded === 1) {
      return 'High';
    } else {
      return 'Unknown';
    }
  }

  return value;
}

export default class DynapacJSON {

  /**
   * Reads the roller data 'files' and combines them into a single RollerData object
   * @param files array of 'file' keys that point to sessionStorage items
   */
  static async read(files: string[]): Promise<RollerData> {
    const data: RollerData[] = [];
    const overviews: OverviewInfo[] = [];

    const result = await Promise.all(files.map(async (file) => {
      try {
        // TODO: create transform dynamically based on file content
        const transform = MercatorTransform.fromGDA94ToGDA2020();
        //const transform = MercatorTransform.fromGDA2020ToGDA94();
        //const transform = MercatorTransform.createFromSingleDatum(Datum.GDA94);

        return DynapacJSON.parse(file, transform);
      } catch (err) {
        console.error(err);
      }
    }));

    result.forEach((roller) => {
      if (!roller) {
        return;
      }

      const [rollerData, overview] = roller;
      data.push(rollerData);
      overviews.push(overview);
    });

    const merged = mergeRollerData(data, overviews);

    return merged;
  }

  private static async parse(filePath: string, transform?: MercatorTransform): Promise<[RollerData, OverviewInfo]> {
    const json = await getDynapacFile(filePath);
    const parsed = JSON.parse(json, dynapacReviver) as DynapacDataJSON[];

    const overview: OverviewInfo = {
      engineer: 'Unknown',
      epsg: -1,
      epsgName: 'Unknown',
      project: 'Unknown',

      ...extractOverviewInfo(parsed)
    };

    const data = new RollerData();

    for (const pass of parsed) {
      const { Easting, Northing, passes } = pass;

      const coord: GridCoordinate = {
        x: Easting,
        y: Northing,
        datum: Datum.GDA94,
        zone: 56
      }

      const transformed = transform?.transformRedfearn(coord) ?? coord;
      const cell = new RollerCell(transformed, passes.map((p, i) => {
        const pass: RollerPass = {
          rollerId: p.machineid,
          coords: transformed,
          passNumber: i + 1,
          timestamp: p.utcdate,

          speed: p.speed,
          amplitude: p.amplitude,
          amplitudeSetting: p.amplitudesetting,
          cmv: p.cmv === undefined ? null : p.cmv,
          temperature: p.temperature ?? 0,
          gnss: p.gnssquality ?? 'Unknown',
        };

        return pass;
      }));

      data.addOrUpdate(cell);
    }

    return [data, overview];
  }
}

function extractOverviewInfo(data: DynapacDataJSON[]): {
  rollerId: string,
  date: Dayjs
} {
  const rollerId = data[0].passes[0].machineid;
  const date = dayjs.unix(Math.min(...data.flatMap((d) => d.passes).map((p) => p.utcdate.unix())));

  return { rollerId, date };
}

interface DynapacDataJSON {
  Easting: number;
  Northing: number;
  passes: DynapacPassJSON[];
}

interface DynapacPassJSON {
  machineid: string;
  utcdate: Dayjs;

  cmv?: number | null;
  temperature?: number;

  speed: number;

  gnssquality?: GNSSQuality;
  amplitudesetting: AmplitudeSetting;
  amplitude: number;
}