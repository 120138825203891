import type { BaseQueryApi } from '@reduxjs/toolkit/query/react';
import { getSilentTokenRequest } from '../../authConfig';
import getMsalInstance from '../../msal';
import type { MaybePromise } from '@tools/typeHelpers';

export type HeaderPreparer = (headers: Headers, api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>) => MaybePromise<Headers | void>;

export const appendAuthorizationHeader: HeaderPreparer = async (headers) => {
  const msal = getMsalInstance();
  const account = msal.getActiveAccount();
  if (account) {
    try {
      const response = await msal.acquireTokenSilent(getSilentTokenRequest(account));
      const accessToken = response.accessToken;
      headers.set('x-paveset-auth-token', `Bearer ${accessToken}`);

    } catch (error) {
      console.error(error);

      // If the token is expired, try to acquire a new one
      await msal.acquireTokenRedirect(getSilentTokenRequest(account));
      //throw new Error('Token Interaction Required');
    }
  }

  return headers;
}