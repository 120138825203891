import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
});

const Table = ({ children }: React.PropsWithChildren) => (
	<View style={styles.tableContainer}>{children}</View>
);

export default Table;
