import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import {
	type InteractionType,
	type PopupRequest,
	type RedirectRequest,
	type SsoSilentRequest,
} from '@azure/msal-browser';
import { InteractionStatus } from '@azure/msal-browser';
import {
	useIsAuthenticated,
	useMsal,
	useMsalAuthentication,
	type AccountIdentifiers,
	type IMsalContext,
	type MsalAuthenticationResult,
} from '@azure/msal-react';
import { getChildrenOrFunction } from '@tools/typeHelpers';

export type MsalTemplateProps = PropsWithChildren<
	AccountIdentifiers & {
		interactionType: InteractionType;
		authenticationRequest?: PopupRequest | RedirectRequest | SsoSilentRequest;
		loadingComponent?: React.ElementType<IMsalContext>;
		errorComponent?: React.ElementType<MsalAuthenticationResult>;
	}
>;

/**
 * Attempts to authenticate user if not already authenticated, then renders child components
 * @param props
 */
export function MsalTemplate({
	interactionType,
	username,
	homeAccountId,
	localAccountId,
	authenticationRequest,
	loadingComponent: LoadingComponent,
	errorComponent: ErrorComponent,
	children,
}: MsalTemplateProps): React.ReactElement | null {
	const accountIdentifier: AccountIdentifiers = useMemo(() => {
		return {
			username,
			homeAccountId,
			localAccountId,
		};
	}, [username, homeAccountId, localAccountId]);

	const context = useMsal();
	const msalAuthResult = useMsalAuthentication(interactionType, authenticationRequest, accountIdentifier);
	const isAuthenticated = useIsAuthenticated(accountIdentifier);

	if (msalAuthResult.error && context.inProgress === InteractionStatus.None) {
		if (ErrorComponent) {
			return <ErrorComponent {...msalAuthResult} />;
		}

		throw msalAuthResult.error;
	}

	if (!!LoadingComponent && context.inProgress !== InteractionStatus.None) {
		return <LoadingComponent {...context} />;
	}

	if (isAuthenticated) {
		return <React.Fragment>{getChildrenOrFunction(children, msalAuthResult)}</React.Fragment>;
	}

	return null;
}
