import {
	Alert,
	Box,
	CircularProgress,
	Slide,
	Snackbar,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	type SnackbarCloseReason,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
	selectBoundaryFiles,
	selectCompactionSpecs,
	selectConfigStatus,
	selectOverview,
	selectReportType,
	selectRollerItemsByCurrentSource,
	setCompactionSpecs,
	updateOverview,
	type ConfigStatus,
} from './dataPickerSlice';
import { Boundary, BoundaryCollection } from '../../data/rollerTypes';
import { Datum } from '@shared/geometry/core/Coordinate';
import { time } from '../../tools/helpers';
import { setAnalysis } from '@analysis/analysisSlice';
import {
	analyseReport,
	type CompactionReportSetup,
	type CompactionSpecification,
	type ReportType,
} from '@analysis/RollerAnalysis';
import ReportConfigTable from './config/ReportConfigTable';
import { useIsXSmall } from '@hooks/responsiveHooks';
import { LoadingButtonWithTooltip } from './shared/LoadingButtonWithTooltip';
import RollerDataPicker from './roller/RollerDataPicker';
import BoundaryDataPicker from './boundary/BoundaryDataPicker';
import DataPickerSection from './shared/DataPickerSection';
import readRollerData from '../../data/rollerReader';

function isConfigComplete(status: ConfigStatus) {
	return status.rollerFiles && status.boundaryFiles && status.config;
}

function createTooltipMessage(status: ConfigStatus, isEditing: boolean) {
	if (!status.rollerFiles) {
		return 'Select the file(s) that contain the roller pass data';
	}

	if (!status.boundaryFiles) {
		return 'Select the file(s) that contain the roller boundary data';
	}

	if (!status.config) {
		return 'Configure the analysis specifications';
	}

	if (isEditing) {
		return 'Save or Cancel the current configuration to continue';
	}

	return 'Press the Process Report button to start the analysis';
}

const DataPicker = () => {
	const isXSmall = useIsXSmall();
	const [isProcessing, setIsProcessing] = useState(false);
	const [analysisError, setAnalysisError] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	const rollerFilesOrKeys = useAppSelector(selectRollerItemsByCurrentSource);
	const boundaryFiles = useAppSelector(selectBoundaryFiles);
	const compactionSpecs = useAppSelector(selectCompactionSpecs);
	const overview = useAppSelector(selectOverview);
	const reportType = useAppSelector(selectReportType);

	const status = useAppSelector(selectConfigStatus);

	const [isEditing, setIsEditing] = useState(false);

	const [isSetupComplete, setIsSetupComplete] = useState(isConfigComplete(status));
	const [tooltipMessage, setTooltipMessage] = useState<string>(createTooltipMessage(status, isEditing));

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isConfigComplete(status) && !isEditing) {
			setIsSetupComplete(true);
		} else {
			setIsSetupComplete(false);
		}

		setTooltipMessage(createTooltipMessage(status, isEditing));
	}, [status, isEditing]);

	const handleProcessRollerReport = () => {
		setIsProcessing(true);

		// push CPU intensive work to the back of the event queue so the UI can update
		setTimeout(async () => {
			// 1) process roller data - either CSV files or JSON from api (stored in IndexedDB)
			const data = await readRollerData(rollerFilesOrKeys);

			console.log(`Loaded ${data.length} cells`);
			if (data.length === 0) {
				// handle error...
				setIsProcessing(false);
				setAnalysisError('Failed Reading Roller CSV Data...');
				setShowError(true);
				return;
			}

			// 2) process boundary data
			const boundaries: BoundaryCollection = new BoundaryCollection();
			for (const boundaryFile of boundaryFiles) {
				try {
					const boundaryData = await Boundary.parseFile(boundaryFile, Datum.GDA2020);

					if (Array.isArray(boundaryData)) {
						boundaries.push(...boundaryData);
					} else {
						boundaries.push(boundaryData);
					}
				} catch (error) {
					console.error(error);
					setIsProcessing(false);
					setAnalysisError('Failed Reading Boundary Data...');
					setShowError(true);
					return;
				}
			}

			data.boundaries = boundaries;
			time(() => {
				if (boundaries.length > 0) {
					for (const cell of data.getCells()) {
						cell.checkWithinBoundaries(boundaries);
					}
				}
			}, 'Check Boundaries');

			// 3) process configuration
			// TODO: extract job info from the roller data
			const reportSetup: CompactionReportSetup = {
				dates: data.overviews.map((overview) => overview.date),

				analysisType: reportType,
				specifications: compactionSpecs,
				overview,

				rollerData: data,
				boundaries,
			};

			try {
				const analysis = analyseReport(reportSetup);
				dispatch(setAnalysis(analysis));
			} catch (error) {
				console.error(error);
				setIsProcessing(false);
				setAnalysisError('Failed to Analyse Roller Data...');
				setShowError(true);
				return;
			}

			setIsProcessing(false);

			navigate('/viewer');
		}, 250);
	};

	const handleErrorAlertClose = (
		_event: Event | React.SyntheticEvent<unknown, Event>,
		_reason: SnackbarCloseReason
	) => {
		console.log('closing error alert...');
		setAnalysisError(null);
		setShowError(false);
	};

	const handleSpecsUpdated = useCallback(
		(specs: CompactionSpecification[]) => {
			dispatch(setCompactionSpecs(specs));
		},
		[dispatch]
	);

	const handleEditModeChange = (isInEditMode: boolean) => {
		setIsEditing(isInEditMode);
	};

	const handleReportTypeChange = (event: React.MouseEvent<HTMLElement>, newType: ReportType | null) => {
		dispatch(setCompactionSpecs([]));
		dispatch(
			updateOverview({
				reportType: newType ?? 'ASPHALT',
			})
		);
	};

	const orientation = isXSmall ? 'column' : 'row';
	return (
		<Stack direction='column' height='100%'>
			<Stack
				spacing={2}
				sx={{
					pt: { xs: 1, sm: 2 },
					pl: { xs: 1, sm: 2 },
					pr: { xs: 1, sm: 2 },
					pb: { xs: 1, sm: 2 },
					mt: 0,
					mb: 0,
					overflowY: 'auto',
					flexGrow: 1,
					flexShrink: 1,
				}}>
				<Stack direction={orientation} spacing={2}>
					{/*
					 * picker component for roller IC data
					 * 	- CSV file(s)
					 * 	- Dynapac API
					 */}
					<RollerDataPicker />

					{/* picker component for boundary data */}
					<BoundaryDataPicker />
				</Stack>

				<Box sx={{ mb: 'auto' }}>
					<DataPickerSection
						containerStyles={{
							pb: 0,
						}}
						section={3}
						title='Report Configuration'
						titleComponent={(titleHeight, titleClass) => (
							<Box sx={{ width: '100%', display: 'flex' }}>
								<Typography
									height={titleHeight}
									width='100%'
									textAlign='center'
									alignContent='center'
									variant='h6'
									flex={1}
									className={titleClass}>
									Report Configuration
								</Typography>
								<ToggleButtonGroup
									color='primary'
									exclusive
									value={reportType}
									onChange={handleReportTypeChange}
									aria-label='Report Type Selector'
									sx={{
										mb: { xs: 0.5, sm: 1 },
										mt: { xs: 0.5, sm: 1 },
									}}>
									<ToggleButton value='ASPHALT'>Asphalt</ToggleButton>
									<ToggleButton value='BASE'>Base</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						)}
						isComplete={compactionSpecs.length > 0}>
						<Box sx={{ width: '100%', height: '100%' }}>
							<ReportConfigTable
								reportType={reportType}
								specs={compactionSpecs}
								onSpecsUpdated={handleSpecsUpdated}
								onEditModeChange={handleEditModeChange}
							/>
						</Box>
					</DataPickerSection>
				</Box>
			</Stack>

			{/* <Box
				sx={{
					zIndex: 100,
					width: 'calc(100%)',
					paddingTop: '25px',
					overflow: 'hidden',
					ml: '0px',
					mr: '0px',
					boxShadow: '0px -4px 8px -2px rgba(120, 120, 120, 0.55)',
				}}></Box> */}

			<LoadingButtonWithTooltip
				tooltipText={tooltipMessage}
				disabled={!isSetupComplete}
				loading={isProcessing}
				loadingPosition='center'
				loadingIndicator={<CircularProgress color='inherit' style={{ width: 32, height: 32 }} />}
				startIcon={<AutoGraphOutlinedIcon />}
				onClick={handleProcessRollerReport}
				variant='contained'
				color='primary'
				size='large'
				sx={{
					'.MuiButton-startIcon>*:nth-of-type(1)': {
						fontSize: {
							xs: 24,
							md: 32,
						},
					},

					ml: { xs: 1, sm: 2 },
					mr: { xs: 1, sm: 2 },
					mb: { xs: 1, sm: 2 },
				}}>
				<Typography
					variant='h6'
					sx={{
						fontSize: {
							xs: '1rem',
							sm: '1.25rem',
						},
						p: 1,
					}}>
					Process Roller Report
				</Typography>
			</LoadingButtonWithTooltip>

			<Snackbar
				open={showError}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				autoHideDuration={5000}
				onClose={handleErrorAlertClose}
				transitionDuration={1000}
				TransitionComponent={(props) => <Slide {...props} direction='up' />}
				sx={{ mb: '72px' }}>
				<Alert
					severity='error'
					variant='filled'
					sx={{
						width: '100%',
						pl: { xs: 2, sm: 4 },
						pr: { xs: 2, sm: 4 },
					}}>
					{analysisError}
				</Alert>
			</Snackbar>
		</Stack>
	);
};

export default DataPicker;
