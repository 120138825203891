import * as React from 'react';
import {
	Unstable_NumberInput as BaseNumberInput,
	type NumberInputProps,
	numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';

const CustomNumberInput = React.forwardRef(function CustomNumberInput(
	props: NumericInputProps,
	ref: React.ForwardedRef<HTMLDivElement>
) {
	return (
		<BaseNumberInput
			slots={{
				root: StyledInputRoot,
				input: StyledInputElement,
				incrementButton: StyledButton,
				decrementButton: StyledButton,
			}}
			slotProps={{
				incrementButton: {
					children: '▴',
				},
				decrementButton: {
					children: '▾',
				},
				root: {
					style: {
						...props.style,
					},
				},
				input: {
					style: {
						...props.inputStyle,
					},
				},
			}}
			{...props}
			ref={ref}
		/>
	);
});

interface NumericInputProps extends Partial<NumberInputProps> {
	size?: 'small' | 'medium' | 'large';
	required?: boolean;
	placeholder?: string;
	min?: number;
	max?: number;
	step?: number;
	value: number | null;
	onValueChange?: (value: number | null) => void;

	style?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
}

export default function CompactNumericInput(props: NumericInputProps) {
	const handleChange = (
		_event:
			| React.FocusEvent<HTMLInputElement, Element>
			| React.PointerEvent<Element>
			| React.KeyboardEvent<Element>,
		value: number | null
	) => {
		if (props.onValueChange) {
			props.onValueChange(value);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			const input = event.target as HTMLInputElement;

			if (input) {
				const value = parseFloat(input.value);
				if ((!isNaN(value) && value < (props.min ?? 0)) || value > (props.max ?? 100)) {
					event.stopPropagation();
					return;
				}

				input.blur();
			}
		}
	};

	return (
		<CustomNumberInput
			required={props.required ?? false}
			placeholder={props.placeholder ?? 'Enter a number'}
			aria-label='Quantity Input'
			min={props.min ?? 0}
			max={props.max ?? 100}
			value={props.value}
			style={props.style}
			step={props.step}
			onChange={handleChange}
			onKeyDown={handleKeyPress}
		/>
	);
}

const blue = {
	100: '#DAECFF',
	200: '#80BFFF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0059B2',
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

const StyledInputRoot = styled('div')(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'};
  display: grid;
  grid-template-columns: calc(100% - 28px) 19px;
  grid-template-rows: 50% 50%;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

  &.${numberInputClasses.focused} {
    //border-color: ${blue[400]};
    //box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const StyledInputElement = styled('input')(
	({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  outline: 0;
  text-align: center;
`
);

const StyledButton = styled('button')(
	({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 100%;
  max-width: 19px;
  height: 100%;
  max-height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }

  &:hover {
    cursor: pointer;
    color: #FFF;
    background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
    border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);
