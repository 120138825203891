import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Stack, CircularProgress, Typography, Fab } from '@mui/material';
import type { DynapacLayerDetails } from '@shared/dynapacTypes';
import type { DataItem } from '../../dataPickerSlice';
import { memo, useCallback } from 'react';

export interface DownloadButtonProps {
	isDownloading: boolean;
	isProcessing: boolean;
	downloadStatus: string;
	layer?: DynapacLayerDetails | null;
	isLayerDownloaded: boolean;

	startDownload: () => void;

	selectedItems: DataItem[];
	addItem: (item: DataItem) => void;
}

const DownloadButton = (props: DownloadButtonProps) => {
	const {
		isDownloading,
		isProcessing,
		downloadStatus,
		layer,
		isLayerDownloaded,
		selectedItems,
		startDownload,
		addItem,
	} = props;

	const handleAddClick = useCallback(() => {
		if (!layer) return;
		if (selectedItems.find((item) => item.key === layer.Layer_ID)) return;

		addItem({
			key: layer.Layer_ID,
			name: layer.Layer_Name,
		});
	}, [addItem, layer, selectedItems]);

	return (
		<Stack direction='row' spacing={2} width={1}>
			<LoadingButton
				startIcon={<CloudDownloadOutlined sx={{ width: 32, height: 24 }} />}
				sx={{
					width: 1,
					pt: 1,
					pb: 1,
					borderRadius: 4,
					fontSize: '1.15rem',
					' .MuiLoadingButton-loadingIndicator': {
						width: '100%',
					},
					' .MuiStack-root': {
						width: '100%',
						justifyContent: 'center',
					},
				}}
				disabled={!layer?.Layer_ID}
				loading={isDownloading || isProcessing}
				loadingIndicator={
					<Stack direction='row' spacing={2}>
						{isProcessing && <CircularProgress size={24} />}
						<Typography color='primary'>{downloadStatus}</Typography>
					</Stack>
				}
				onClick={startDownload}>
				{!!layer?.Layer_ID && isLayerDownloaded ? 'COMPLETE' : 'DOWNLOAD'}
			</LoadingButton>

			{isLayerDownloaded && !selectedItems.find((item) => item.key === layer?.Layer_ID) && (
				<Fab
					color='primary'
					sx={{ width: 48, minWidth: 48, height: 48, alignSelf: 'center' }}
					onClick={handleAddClick}>
					<AddIcon />
				</Fab>
			)}
		</Stack>
	);
};

const MemoizeDownloadButton = memo(DownloadButton);
export default MemoizeDownloadButton;
