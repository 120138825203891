export type V3 = Float64Array;

function add(a: V3, b: V3, output?: V3): V3 {
    const result = output ?? new Float64Array(3);

    for (let i = 0; i < 3; i++) {
        result[i] = a[i] + b[i];
    }
    return result;
}

function subtract(a: V3, b: V3, output?: V3): V3 {
    const result = output ?? new Float64Array(3);

    for (let i = 0; i < 3; i++) {
        result[i] = a[i] - b[i];
    }
    return result;
}

function multiplyScalar(vector: V3, scalar: number, output?: V3): V3 {
    const result = output ?? new Float64Array(3);
    for (let i = 0; i < 3; i++) {
        result[i] = vector[i] * scalar;
    }
    return result;
}

function dot(a: V3, b: V3): number {
  return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

function cross(a: V3, b: V3, output?: V3): V3 {
    const result = output ?? new Float64Array(3);
    result[0] = a[1] * b[2] - a[2] * b[1];
    result[1] = a[2] * b[0] - a[0] * b[2];
    result[2] = a[0] * b[1] - a[1] * b[0];
    return result;
}

export default {
    add,
    subtract,
    multiplyScalar,
    dot,
    cross
} as const;