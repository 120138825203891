import { Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
	useGetDynapacAccessTokenQuery,
	useGetDynapacJobsListQuery,
	useLazyGetDynapacDownloadUrlQuery,
} from '../../api/dynapacApiSlice';
import type { DynapacLoginDetails } from '@shared/dynapacTypes';
import { downloadParallel } from '@tools/downloader';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
	addRollerFile,
	removeRollerFile,
	selectDynapacAuthState,
	selectDynapacSelection,
	selectReportType,
	setDynapacAuth,
	updateDynapacSelection,
	type DataItem,
	type DynapacSelectionState,
} from '../dataPickerSlice';
import dayjs from 'dayjs';
import { checkDynapacFileExists, saveDynapacFile } from '../../../data/fileCache';
import ProjectsView from './downloader/ProjectsView';
import DownloadButton from './downloader/DownloadButton';

const loginDetails: DynapacLoginDetails = {
	email: 'surfacing.seq@fultonhogan.com.au',
	password: 'FHSEQ01',
};

interface DownloadProgress {
	layerId: string | null;
	progress: number;
}

interface DynapacDataPickerProps {
	selectedItems: DataItem[];

	forceRefresh?: boolean;
	onRefreshStatusChanged?: (isRefreshing: boolean) => void;
}

const DynapacDataPicker = (props: DynapacDataPickerProps) => {
	const { selectedItems, forceRefresh, onRefreshStatusChanged } = props;

	const dispatch = useAppDispatch();
	const authState = useAppSelector(selectDynapacAuthState);
	const reportType = useAppSelector(selectReportType);

	const login = useGetDynapacAccessTokenQuery(loginDetails, {
		skip: authState.isAuth && !authState.getIsExpired(),
		pollingInterval: 45 * 60 * 1000,
	});

	const jobsList = useGetDynapacJobsListQuery(
		{
			email: loginDetails.email,
		},
		{
			skip: !authState.auth || authState.getIsExpired(),
		}
	);

	const { project, object, layer } = useAppSelector(selectDynapacSelection);

	const [downloadProgress, setDownloadProgress] = useState<DownloadProgress>({
		layerId: null,
		progress: -1,
	});
	const [downloadStatus, setDownloadStatus] = useState('');
	const [isLayerDownloaded, setIsLayerDownloaded] = useState(false);
	const isProcessing = !!downloadProgress.layerId && downloadProgress.progress === 0;
	const isDownloading = downloadProgress.progress > 0 && downloadProgress.progress < 100;

	const [getDownloadQueryTrigger, getDownloadQuery] = useLazyGetDynapacDownloadUrlQuery();

	const updateSelection = useCallback(
		(selection: Partial<DynapacSelectionState>) => {
			dispatch(updateDynapacSelection(selection));
		},
		[dispatch]
	);

	useEffect(() => {
		const checkCache = async () => {
			if (!layer) {
				setIsLayerDownloaded(false);
				return;
			}

			const exists = await checkDynapacFileExists(layer.Layer_ID);
			setIsLayerDownloaded(exists);
		};

		checkCache();
	}, [layer]);

	useEffect(() => {
		if (forceRefresh) {
			if (jobsList.isSuccess || jobsList.isError) {
				(async () => {
					onRefreshStatusChanged && onRefreshStatusChanged(true);
					if (!authState.isAuth || authState.getIsExpired()) {
						console.log('refreshing access token...');
						await login.refetch();
					}

					await jobsList.refetch();
					onRefreshStatusChanged && onRefreshStatusChanged(false);
				})();
			}
		}
	}, [forceRefresh, jobsList.isSuccess, login, authState, onRefreshStatusChanged, jobsList]);

	useEffect(() => {
		if (login.isLoading || login.isFetching) {
			console.log('loading access token...');
		} else if (login.isSuccess && !login.isFetching && login.data) {
			console.log('access token query success');
			dispatch(
				setDynapacAuth({
					accessToken: login.data.token.access_token,
					expiration: dayjs().add(login.data.token.expires_in_minutes, 'minutes'),
				})
			);
		} else if (login.isError) {
			console.warn('access token query error: ', login.error);
		}
	}, [login.isFetching, login.isLoading, login.isSuccess, login.isError, login.data, login.error, dispatch]);

	useEffect(() => {
		if (jobsList.isLoading && jobsList.startedTimeStamp) {
			console.log('loading jobs list...');
		} else if (jobsList.isSuccess) {
			console.log('jobs list query success');
		} else if (jobsList.isError) {
			console.log('jobs list query error: ', jobsList.error);
		}
	}, [jobsList.isLoading, jobsList.isSuccess, jobsList.isError, jobsList.startedTimeStamp, jobsList.error]);

	useEffect(() => {
		if (getDownloadQuery.isFetching) return;

		const timeouts: NodeJS.Timeout[] = [];

		if (getDownloadQuery.isUninitialized) {
			// we skipped the query - no layer download is selected
		} else if (getDownloadQuery.isSuccess) {
			const layerId = getDownloadQuery.originalArgs?.layerId;
			const layerName = getDownloadQuery.originalArgs?.layerName;
			if (!layerId || !layerName) return;

			const data = getDownloadQuery.data;
			if (data.success && data.url) {
				console.log('start download query success...');

				setDownloadStatus('Processing Complete, Downloading...');
				(async () => {
					try {
						const updateProgress = (progress: number | ((p: number) => number)) => {
							console.log('progress update...');
							if (progress instanceof Function) {
								setDownloadProgress((prev) => {
									console.log('updating progress (fn):', progress(prev.progress));
									return {
										layerId: prev.layerId,
										progress: progress(prev.progress),
									};
								});
							} else {
								setDownloadProgress((prev) => {
									console.log('updating progress:', progress);
									return {
										layerId: prev.layerId,
										progress: progress,
									};
								});
							}
						};

						const downloadData = await downloadParallel(getDownloadQuery.data.url!, {
							chunkCount: 4,
							progress: updateProgress,
						});

						setDownloadStatus('Download Complete, Processing Data...');
						setDownloadProgress({
							layerId: layerId,
							progress: 100,
						});

						timeouts.push(
							setTimeout(async () => {
								const decoder = new TextDecoder('utf-8');
								const text = decoder.decode(downloadData);

								await saveDynapacFile(layerId, text);

								setDownloadStatus('Download & Processing Complete');

								const item: DataItem = { name: layerName, key: layerId };
								dispatch(addRollerFile(item));
							}, 500)
						);
					} catch (error) {
						console.error(error);

						setDownloadStatus('Failed Download. Try Again');
						timeouts.push(
							setTimeout(() => {
								setDownloadProgress({
									layerId: layerId,
									progress: -1,
								});
							}, 2500)
						);
					}
				})();
			} else if (!data.success) {
				console.log('start download query failed:', data.error);
				setDownloadStatus('Failed to start download');
				timeouts.push(
					setTimeout(() => {
						setDownloadProgress({
							layerId: layerId,
							progress: -1,
						});
					}, 2500)
				);
			}
		}

		return () => {
			timeouts.forEach((timeout) => clearTimeout(timeout));
		};
	}, [
		dispatch,
		getDownloadQuery.data,
		getDownloadQuery.isFetching,
		getDownloadQuery.isSuccess,
		getDownloadQuery.isUninitialized,
		getDownloadQuery.originalArgs,
	]);

	const LoadingView = useCallback(() => {
		const content = login.isFetching
			? 'Logging in to Dynapac API...'
			: jobsList.isFetching
			? 'Querying jobs list...'
			: '';

		return (
			<>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress size={40} content='Loading...' />
				</Box>

				<Typography textAlign='center' padding={2}>
					{content}
				</Typography>
			</>
		);
	}, [login, jobsList.isFetching]);

	const handleStartDownload = useCallback(async () => {
		if (!layer?.Layer_ID) {
			return;
		}

		setDownloadProgress({
			layerId: layer.Layer_ID,
			progress: 0,
		});
		setDownloadStatus('Processing Report...');

		getDownloadQueryTrigger({
			layerId: layer.Layer_ID,
			layerName: layer.Layer_Name,
			isBaseData: reportType === 'BASE',
		}).catch((error) => {
			console.error('Failed to start download:', error);
		});
	}, [getDownloadQueryTrigger, layer, reportType]);

	const addSelectedItem = useCallback(
		(item: DataItem) => {
			dispatch(addRollerFile(item));
		},
		[dispatch]
	);

	const removeSelectedItem = useCallback(
		(item: DataItem) => {
			dispatch(removeRollerFile(item));
		},
		[dispatch]
	);

	return (
		<div>
			{/* we're loading, show loading ui */}
			{(login.isLoading || jobsList.isLoading) && LoadingView()}

			{/* everything is good, show combo boxes n shit */}
			{jobsList.isSuccess && (
				<Stack direction='column'>
					<ProjectsView
						isDownloading={isDownloading}
						isLoading={jobsList.isFetching}
						project={project}
						object={object}
						layer={layer}
						data={jobsList.data}
						selectedItems={selectedItems}
						updateSelection={updateSelection}
						removeItem={removeSelectedItem}
					/>

					<DownloadButton
						isDownloading={isDownloading}
						isProcessing={isProcessing}
						downloadStatus={downloadStatus}
						layer={layer}
						isLayerDownloaded={isLayerDownloaded}
						selectedItems={selectedItems}
						addItem={addSelectedItem}
						startDownload={handleStartDownload}
					/>
				</Stack>
			)}

			{/* error occurred, show error message */}
			{jobsList.isError && <div>Error: {`${JSON.stringify(jobsList.error)}`}</div>}

			{!!downloadProgress.layerId && downloadProgress.progress >= 0 && (
				<LinearProgress
					variant='determinate'
					value={downloadProgress.progress}
					sx={{
						position: 'relative',
						left: 0,
						marginLeft: '-16px',
						marginBottom: '-16px',
						marginRight: '-16px',
						width: 'calc(100% + 32px)',
						height: '8px',
					}}
				/>
			)}
		</div>
	);
};

export default DynapacDataPicker;
