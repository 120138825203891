import type React from 'react';
import { useEffect, useState, type ReactNode } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './App.css';
import { MsalProvider } from '@azure/msal-react';
import { type IPublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { isIE } from './tools/helpers';
import { CustomNavigationClient } from './authConfig';

interface ClientSideNavigationParams {
	instance: IPublicClientApplication;
	children: ReactNode;
}

const ClientSideNavigation: React.FC<ClientSideNavigationParams> = (params: ClientSideNavigationParams) => {
	const { instance, children } = params;

	// replace the default navigation client with CustomNavigationClient
	//  - this uses react-router for internal navigation
	const navigate = useNavigate();
	const navigationClient: CustomNavigationClient = new CustomNavigationClient(navigate);
	instance.setNavigationClient(navigationClient);

	// react-router-dom v6 doesn't allow navigation on the first render
	// - delay rendering of MsalProvider to get around this limitation
	const [firstRender, setFirstRender] = useState(true);
	useEffect(() => {
		setFirstRender(false);
	}, []);

	if (firstRender) {
		console.log('[App] first render...');
		return null;
	}

	return children;
};
/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App: React.FC<{ instance: IPublicClientApplication }> = ({ instance }) => {
	if (isIE()) {
		// user is running Internet Explorer. Prompt use to change browser...
	}

	return (
		<ClientSideNavigation instance={instance}>
			<Provider store={store}>
				<MsalProvider instance={instance}>
					<Layout>
						<Routes>
							{AppRoutes.map((route, index) => {
								const { element, ...rest } = route;
								return <Route key={index} {...rest} element={element} />;
							})}
						</Routes>
					</Layout>
				</MsalProvider>
			</Provider>
		</ClientSideNavigation>
	);
};

export default App;
