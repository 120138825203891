import { type Polygon as Poly, type Point as Coord, type Segments, PolyBool, GeometryEpsilon } from '@velipso/polybool';
import type { Point } from './Coordinate';

const polybool = new PolyBool(new GeometryEpsilon());

function calculateArea(vertices: Coord[]): number {
  if (vertices.length < 3) {
    //throw new Error('There must be at least three unique vertices to form a polygon.');
    return NaN;
  }

  let area = 0;

  for (let i = 0; i < vertices.length; i++) {
    const j = (i + 1) % vertices.length;
    area += vertices[i][0] * vertices[j][1];
    area -= vertices[j][0] * vertices[i][1];
  }

  return Math.abs(area / 2);
}

function pointInPolygon(poly: Coord[], pt: Coord): boolean {
  let c = false;
  for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
    if (((poly[i][1] > pt[1]) !== (poly[j][1] > pt[1])) &&
      (pt[0] < (poly[j][0] - poly[i][0]) * (pt[1] - poly[i][1]) / (poly[j][1] - poly[i][1]) + poly[i][0])) {
      c = !c;
    }
  }

  return c;
}

export class Polygon implements Poly {
  regions: Coord[][];
  inverted: boolean;

  constructor(pts: Coord[]) {
    this.regions = [pts];
    this.inverted = false;
  }

  get vertices(): Coord[] {
    return this.regions[0];
  }

  calculateArea(): number {
    return calculateArea(this.vertices);
  }

  containsPoint(pt: Coord): boolean {
    return pointInPolygon(this.vertices, pt);
  }

  calculateIntersection(poly: Polygon): Polygon {
    const result = polybool.intersect(this, poly);
    return new Polygon(result.regions[0]);
  }

  static fromCoordinates(coords: Point[]): Polygon {
    return new Polygon(coords.map((coord) => [coord.x, coord.y]));
  }
}

export class PreparedPolygon implements Poly {
  regions: Coord[][];
  inverted: boolean;
  segments: Segments;

  get vertices(): Coord[] {
    return this.regions[0];
  }

  constructor(pts: Coord[]) {
    this.regions = [pts];
    this.inverted = false;
    this.segments = polybool.segments(this);
  }

  calculateIntersection(poly: PreparedPolygon): Polygon {
    const result = polybool.combine(this.segments, poly.segments);
    const combined = polybool.selectIntersect(result);

    const polygon = polybool.polygon(combined);
    return new Polygon(polygon.regions[0]);
  }

  union(poly: PreparedPolygon): PreparedPolygon {
    const result = polybool.union(this, poly);
    return new PreparedPolygon(result.regions[0]);
  }

  calculateArea(): number {
    return calculateArea(this.vertices);
  }

  containsPoint(pt: Coord): boolean {
    return pointInPolygon(this.vertices, pt);
  }

  static fromCoordinates(coords: Point[]): PreparedPolygon {
    return new PreparedPolygon(coords.map((coord) => [coord.x, coord.y]));
  }

  static convertPolygonsToRegions(polygons: PreparedPolygon[]): Poly {
    return {
      regions: polygons.map((polygon) => polygon.vertices),
      inverted: false,
    };
  }
}
