import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
	type ArcAuthDetails,
	type ArcTokenResponse,
	type LayerItem,
	type ServiceItem,
} from '@shared/arcgisTypes';
import {
	useExchangeAuthCodeMutation,
	useRefreshArcTokenMutation,
	useSearchArcLayersQuery,
	useSearchArcServicesQuery,
} from '../../../api/arcgisApiSlice';
import {
	selectArcIsAuth,
	selectArcIsExpired,
	selectArcAuthState,
	setArcAuth,
	updateArcToken,
	updateArcSelection,
	type ArcDataTypes,
	makeSelectArcSelectionByType,
} from '../../dataPickerSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete, Button, Chip, CircularProgress, Collapse, TextField, Typography } from '@mui/material';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import LoginOutlined from '@mui/icons-material/LoginOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { isDebug } from '../../../../tools/helpers';
import { isSuccess } from '../../../api/coreApiSlice';

const responseType = 'code';
const clientId = '8aqGyzQOTqoD3ThK';
const redirectUri = isDebug
	? 'https://localhost:3000/arcgis-auth'
	: 'https://ic.pavesetreport.com/arcgis-auth';

const authUrl = `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(
	redirectUri
)}`;

export interface ArcDownloaderProps {
	dataType: ArcDataTypes;
}

const ArcDownloader = (props: ArcDownloaderProps) => {
	const { dataType } = props;

	// create a new memo-ized selector for this component, on mount
	const selectArcSelectionByType = useMemo(makeSelectArcSelectionByType, []);
	const { feature, layer, pavingLayer } = useAppSelector((state) =>
		selectArcSelectionByType(state.dataPicker, dataType)
	);

	const arcgisAuth = useAppSelector(selectArcAuthState);
	const isAuth = useAppSelector(selectArcIsAuth);
	const isExpired = useAppSelector(selectArcIsExpired);

	const dispatch = useAppDispatch();

	const [arcExchangeAuthCode] = useExchangeAuthCodeMutation();
	const [arcRefreshToken] = useRefreshArcTokenMutation();
	const [loggingIn, setLoggingIn] = useState(false);

	const [downloading, setDownloading] = useState(false);

	const searchServices = useSearchArcServicesQuery(
		{
			type: 'Service',
			orgId: arcgisAuth?.user?.orgId ?? '',
			token: arcgisAuth?.accessToken ?? '',
			filter: 'type:"Feature Service"',
		},
		{
			skip: !arcgisAuth?.user?.orgId || !isAuth || isExpired,
		}
	);

	const searchLayers = useSearchArcLayersQuery(
		{
			type: 'Layer',
			token: arcgisAuth?.accessToken ?? '',
			orgId: arcgisAuth?.user?.orgId ?? '',
			featureName: feature?.name ?? feature?.title ?? '',
		},
		{
			skip: !feature?.id || !isAuth || isExpired,
		}
	);

	const refreshArcGISAccessToken = useCallback(async (): Promise<boolean> => {
		if (arcgisAuth?.refreshToken) {
			try {
				const response = await arcRefreshToken({
					refreshToken: arcgisAuth.refreshToken,
					clientId,
				});

				if (isSuccess<ArcTokenResponse>(response)) {
					if (response.data.accessToken && response.data.expiration) {
						dispatch(updateArcToken(response.data));
						return true;
					}

					if (response.data.error === 'Invalid Refresh Token') {
						console.error('Invalid Refresh Token');
						dispatch(setArcAuth(null));
					}
				}
			} catch (error) {
				console.error(error);
			}
		}

		return false;
	}, [arcRefreshToken, arcgisAuth, dispatch]);

	const handlePopupClosed = useCallback(async () => {
		const code = localStorage.getItem('authCode');
		if (code) {
			console.log(`Received auth code: ${code}`);

			try {
				const response = await arcExchangeAuthCode({
					code,
					redirectUri,
					clientId,
				});

				if (isSuccess<ArcAuthDetails>(response) && response.data.accessToken) {
					console.log('exchanged auth code for access/refresh tokens: ', response.data);

					dispatch(
						setArcAuth({
							...response.data,
							clientId,
						})
					);
				}
			} catch (error) {
				console.error(error);
			} finally {
				// remove the authorization code after attempting to exchange for access token
				localStorage.removeItem('authCode');
			}
		} else {
			console.error('No authorization code found in local storage');
		}

		setLoggingIn(false);
	}, [arcExchangeAuthCode, dispatch]);

	const handleLogin = useCallback(() => {
		setLoggingIn(true);

		setTimeout(async () => {
			try {
				if (isAuth && isExpired) {
					if (!(await refreshArcGISAccessToken())) {
						console.error('Failed to refresh ArcGIS Access Token');
					} else {
						console.log('Refreshed ArcGIS Access Token...');
					}

					setLoggingIn(false);
				} else if (!isAuth) {
					// Open the external login page in a popup window
					const width = 600;
					const height = 700;
					const left = window.screenX + (window.outerWidth - width) / 2;
					const top = window.screenY + (window.outerHeight - height) / 2.5;
					const loginWindow = window.open(
						authUrl,
						'LoginPopup',
						`width=${width},height=${height},top=${top},left=${left}`
					);

					loginWindow?.addEventListener('load', (event) => {
						console.log(event);
						console.log(loginWindow.location);
					});

					// Polling to check if the popup has been closed
					const checkPopup = setInterval(async () => {
						if (loginWindow?.closed) {
							clearInterval(checkPopup);
							await handlePopupClosed();
						}
					}, 500);
				}
			} catch (error) {
				console.error(error);
			}
		}, 250);
	}, [isAuth, isExpired, handlePopupClosed, refreshArcGISAccessToken, setLoggingIn]);

	const handleRefreshClick = useCallback(() => {
		if (isAuth && !isExpired) {
			dispatch(updateArcSelection({ type: dataType, feature: null, layer: null, pavingLayer: null }));
			searchServices.refetch();
		}
	}, [dispatch, isAuth, isExpired, searchServices, dataType]);

	const FeaturesView = useMemo(
		() => (
			<Stack direction='column' spacing={1}>
				<Box width={1} height={36} display='flex' justifyContent='center' alignItems='center'>
					<Typography
						flexDirection='row'
						flexGrow={1}
						justifyContent='center'
						textAlign='center'
						lineHeight='36px'
						sx={{ mt: 'auto', pl: '48px' }}>
						{arcgisAuth?.user?.username}
					</Typography>

					<Button
						sx={{
							minWidth: 48,
							width: 48,
						}}
						disabled={downloading || searchServices.isFetching || searchLayers.isFetching}
						onClick={handleRefreshClick}>
						<SyncIcon color='primary' sx={{ width: 24, height: 24 }} />
					</Button>
				</Box>

				{searchServices.isFetching && (
					<CircularProgress sx={{ alignSelf: 'center' }} title='Searching Layers' />
				)}

				{!searchServices.isFetching && searchServices.isSuccess && (
					<>
						<Autocomplete
							disabled={downloading}
							options={searchServices.data ?? []}
							getOptionLabel={(option) => option.title}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							sx={{ width: '100%' }}
							value={feature}
							onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: ServiceItem | null) => {
								dispatch(
									updateArcSelection({
										type: dataType,
										feature: newValue,
										layer: null,
										pavingLayer: null,
									})
								);
							}}
							renderOption={(props, option) => (
								<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									{option.title}
								</Box>
							)}
							renderInput={(params) => <TextField {...params} label='Features' variant='outlined' />}
						/>

						<Autocomplete
							disabled={downloading}
							options={searchLayers.data ?? []}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							sx={{ width: '100%' }}
							value={layer}
							loading={searchLayers.isFetching}
							onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: LayerItem | null) => {
								dispatch(
									updateArcSelection({
										type: dataType,
										layer: newValue,
										pavingLayer: null,
									})
								);
							}}
							renderOption={(props, option) => (
								<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									{option.name}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Layers'
									variant='outlined'
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{searchLayers.isFetching && <CircularProgress color='inherit' size={20} />}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
							renderTags={(value, getTagProps) => {
								return value.map((option, index) => {
									const { key: _ignore, ...rest } = getTagProps({ index });

									return <Chip key={index} {...rest} label={option.name} />;
								});
							}}
						/>

						<Collapse
							orientation='vertical'
							timeout={{
								enter: 500,
								exit: 500,
							}}
							in={!!layer?.pavingLayers}>
							<Autocomplete
								disabled={downloading}
								options={(layer?.pavingLayers as string[]) ?? []}
								sx={{ width: '100%' }}
								value={pavingLayer}
								onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
									dispatch(updateArcSelection({ type: dataType, pavingLayer: newValue }));
								}}
								renderOption={(props, option) => (
									<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
										{option}
									</Box>
								)}
								renderInput={(params) => <TextField {...params} label='Paving Layer' variant='outlined' />}
							/>
						</Collapse>

						<LoadingButton
							startIcon={<CloudDownloadOutlined sx={{ width: 32, height: 24 }} />}
							loading={downloading}
							loadingIndicator={<CircularProgress thickness={3} color='primary' size={32} />}
							sx={{ pt: 1, pb: 1, borderRadius: 4, fontSize: '1.15rem' }}
							disabled={!layer}
							onClick={() => setDownloading((dl) => !dl)}>
							DOWNLOAD
						</LoadingButton>
					</>
				)}
			</Stack>
		),
		[
			arcgisAuth,
			searchServices,
			searchLayers,
			feature,
			layer,
			pavingLayer,
			downloading,
			dataType,
			handleRefreshClick,
			dispatch,
		]
	);

	const LoginView = useMemo(
		() => (
			<Stack direction='column' spacing={2}>
				<Typography p={{ xs: 1, sm: 2 }} fontStyle='italic' textAlign='center'>
					You need to login to ArcGIS to access the feature data
				</Typography>

				<LoadingButton
					loading={loggingIn}
					loadingPosition='center'
					loadingIndicator={<CircularProgress color='inherit' style={{ width: 32, height: 32 }} />}
					startIcon={<LoginOutlined />}
					onClick={handleLogin}
					variant='contained'
					color='primary'
					size='large'
					sx={{
						'.MuiButton-startIcon>*:nth-of-type(1)': {
							fontSize: {
								xs: 24,
								md: 28,
							},
						},

						ml: { xs: 1, sm: 2 },
						mr: { xs: 1, sm: 2 },
						mb: { xs: 0, sm: 2 },
						p: 0,
					}}>
					<Typography
						variant='h6'
						sx={{
							fontSize: '1rem',
							p: 1,
						}}>
						{isAuth && isExpired ? 'Refresh Access' : 'Login'}
					</Typography>
				</LoadingButton>
			</Stack>
		),
		[handleLogin, isAuth, isExpired, loggingIn]
	);

	return (
		<Box sx={{ width: '100%', height: '100%' }}>
			{isAuth && !isExpired && FeaturesView}

			{(!isAuth || isExpired) && LoginView}
		</Box>
	);
};

export default ArcDownloader;
