import { View, StyleSheet } from '@react-pdf/renderer';
import type { Style } from '@react-pdf/types';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		fontFamily: 'Montserrat',
		fontSize: 12,
		padding: 4,
	},
});

export interface TableRowProps {
	children: React.ReactNode;
	header?: boolean;

	style?: Style;
}

const TableRow = (props: TableRowProps) => {
	const s: Style[] = [styles.row];
	if (props.style) {
		s.push(props.style);
	}

	if (props.header) {
		s.push({ fontWeight: 'medium', fontSize: 14, fontFamily: 'Montserrat' });
	}

	return <View style={s}>{props.children}</View>;
};

export default TableRow;
