import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// extend dayjs with duration plugin
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

console.log('dayjs duration extended in librarySetup.ts');
