import {
	FormControl,
	InputLabel,
	Select,
	type SelectChangeEvent,
	MenuItem,
	TextField,
	InputAdornment,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { type PavementLayer, pavementLayerTypes, type OverviewSetup } from '../dataPickerSlice';
import React from 'react';

const numericKeys = new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Delete']);

export interface ReportOverviewFieldsProps {
	isXSmall: boolean;
	overview: OverviewSetup;

	onJobIdChanged: (jobId: string | null) => void;
	onLayerChanged: (layer: PavementLayer | null) => void;
	onMixTypeChanged: (mixType: string | null) => void;
	onThicknessChanged: (thickness: number | null) => void;
}

const ReportOverviewFields = (props: ReportOverviewFieldsProps) => {
	const { isXSmall, overview, onJobIdChanged, onLayerChanged, onMixTypeChanged, onThicknessChanged } = props;

	const handleJobIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onJobIdChanged(event.target.value.length > 0 ? event.target.value : null);
	};

	const handleLayerChange = (event: SelectChangeEvent<PavementLayer | null | ''>) => {
		const layer = event.target.value;
		onLayerChanged(!!layer && layer.length > 0 ? (layer as PavementLayer) : null);
	};

	const handleMixTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onMixTypeChanged(event.target.value.length > 0 ? event.target.value : null);
	};

	const handleThicknessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onThicknessChanged(event.target.value ? parseInt(event.target.value) : null);
	};

	const handleThicknessKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (!numericKeys.has(event.key)) {
			event.preventDefault();
		}
	};

	return (
		<Grid container spacing={2} mt={1}>
			<Grid xs={6} md={3}>
				<TextField
					id='job-id-field'
					aria-label='job id input'
					label={'Job ID'}
					variant='outlined'
					value={overview.jobId ?? ''}
					fullWidth
					error={!overview.jobId}
					onChange={handleJobIdChange}
					sx={{
						'& .MuiInputBase-input': {
							textTransform: 'uppercase',
						},
					}}
				/>
			</Grid>

			<Grid xs={6} md={3}>
				<FormControl fullWidth>
					<InputLabel
						error={overview.layer === null}
						id='layer-select-label'
						sx={{
							'&.Mui-error': {
								color: (theme) => theme.palette.error.dark,
							},
						}}>
						{isXSmall ? 'Layer' : 'Pavement Layer'}
					</InputLabel>
					<Select
						labelId='layer-select-label'
						id='layer-select'
						value={overview.layer ?? ''}
						label={isXSmall ? 'Layer' : 'Pavement Layer'}
						error={overview.layer === null}
						onChange={handleLayerChange}
						sx={{
							'&.Mui-error .MuiSvgIcon-root': {
								color: (theme) => theme.palette.error.dark,
							},
						}}>
						<MenuItem value=''></MenuItem>

						{pavementLayerTypes.map((layer) => (
							<MenuItem key={layer} value={layer}>
								{layer}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid xs={6} md={3}>
				<TextField
					id='mix-type-field'
					aria-label='mix type input'
					label={isXSmall ? 'Mix' : 'Mix Type'}
					variant='outlined'
					value={overview.mixType ?? ''}
					error={!overview.mixType}
					fullWidth
					onChange={handleMixTypeChange}
					sx={{
						'& .MuiInputBase-input': {
							textTransform: 'uppercase',
						},
					}}
				/>
			</Grid>

			<Grid xs={6} md={3} sx={{ alignContent: 'center', minHeight: 72 }}>
				<TextField
					id='nominal-thickness-field'
					aria-label='nominal thickness input'
					label={isXSmall ? 'Thickness' : 'Pavement Thickness'}
					variant='outlined'
					value={overview.thickness ?? ''}
					fullWidth
					error={overview.thickness === null}
					InputProps={{
						endAdornment: (
							<InputAdornment className={overview.thickness ? '' : 'Mui-error'} position='end'>
								mm
							</InputAdornment>
						),
					}}
					onChange={handleThicknessChange}
					onKeyDown={handleThicknessKeyDown}
				/>
			</Grid>
		</Grid>
	);
};

const ReportOverviewFieldsMemo = React.memo(ReportOverviewFields);
export default ReportOverviewFieldsMemo;
