import type { ArcAuthCodeRequest, ArcAuthCodeResponse, ArcAuthDetails, ServiceItem, ArcRefreshTokenRequest, ArcSearchRequest, FeatureServiceSearchResult, ArcTokenResponse, LayerItem, FeatureLayerSearchResult } from '@shared/arcgisTypes';
import dayjs from 'dayjs';
import { coreApi } from './coreApiSlice';

import type { HeaderPreparer } from './helpers';
import type { RootState } from '@app/store';

export const appendDynapacTokenHeader: HeaderPreparer = async (headers, { getState, endpoint }) => {
  switch (endpoint) {
    case 'searchFeatureServices':
    case 'searchFeatureLayers': {
      const token = (getState() as RootState).dataPicker.arcgis.auth?.accessToken;
      if (token) {
        headers.set('x-paveset-arcgis-token', `${token}`);
      }
    }
  }

  return headers;
}

export const arcgisApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    exchangeAuthCode: build.mutation<ArcAuthDetails, ArcAuthCodeRequest>({
      query: (details) => ({
        url: 'arcgis/exchangeAuthCode',
        method: 'POST',
        body: details
      }),

      transformResponse: (response: ArcAuthCodeResponse, _meta, arg): ArcAuthDetails => {
        const { accessToken, refreshToken, expiration, user } = response;
        return {
          accessToken,
          refreshToken,
          expiration: dayjs(expiration),
          user,
          clientId: arg.clientId
        };
      }
    }),

    refreshArcToken: build.mutation<ArcTokenResponse, ArcRefreshTokenRequest>({
      query: (details) => ({
        url: 'arcgis/refreshAccessToken',
        method: 'POST',
        body: details
      }),

      transformResponse: (response: ArcTokenResponse): ArcTokenResponse => {
        return {
          accessToken: response.accessToken,
          expiration: dayjs(response.expiration),
          error: response.error
        };
      }
    }),

    searchArcServices: build.query<ServiceItem[], ArcSearchRequest>({
      query: (details) => ({
        url: 'arcgis/search',
        method: 'GET',
        params: details
      }),

      transformResponse: (response: FeatureServiceSearchResult, _meta, _arg): ServiceItem[] => {
        if (response.success) {
          return response.items.sort((a, b) => a.title.localeCompare(b.title));
        }

        return [];
      },
    }),

    searchArcLayers: build.query<LayerItem[], ArcSearchRequest & { featureName: string }>({
      query: (details) => ({
        url: 'arcgis/search',
        method: 'GET',
        params: details
      }),

      transformResponse: (response: FeatureLayerSearchResult, _meta, _arg): LayerItem[] => {
        if (response.success) {
          return response.items;
        }

        return [];
      },
    }),
  }),

  overrideExisting: false,
});

export type ArcRefreshTokenMutationHook = ReturnType<typeof useRefreshArcTokenMutation>;
export type ArcRefreshTokenMutationTrigger = ArcRefreshTokenMutationHook[0];
export type ArcRefreshTokenMutationState = ArcRefreshTokenMutationHook[1];

export type ArcExchangeAuthCodeMutationHook = ReturnType<typeof useExchangeAuthCodeMutation>;
export type ArcExchangeAuthCodeMutationTrigger = ArcExchangeAuthCodeMutationHook[0];
export type ArcExchangeAuthCodeMutationState = ArcExchangeAuthCodeMutationHook[1];

export const {
  useRefreshArcTokenMutation,
  useExchangeAuthCodeMutation,
  useSearchArcServicesQuery,
  useLazySearchArcServicesQuery,
  useSearchArcLayersQuery,
  useLazySearchArcLayersQuery
} = arcgisApi;

export const arcgisEndpoints = arcgisApi.endpoints;