import { type IMsalContext, useMsal } from '@azure/msal-react';
import Loader from './Loader';
import { InteractionType, type RedirectRequest } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { useCallback, useMemo } from 'react';
import { MsalTemplate } from './MsalTemplate';

const withAuthProtection = (Component: React.ComponentType, route: string) => {
	const Protected = () => {
		const { instance } = useMsal();
		const account = instance.getActiveAccount() ?? instance.getAllAccounts()?.at(0);

		const Loading = useCallback((): React.ReactElement<IMsalContext> => {
			return <Loader text='Processing Authentication Request...' />;
		}, []);

		// const request: RedirectRequest = useMemo(() => {
		// 	console.log('Creating new request object...');

		// 	return {
		// 		...(loginRequest as RedirectRequest),
		// 		state: route,
		// 		prompt: 'select_account',
		// 		loginHint: instance.getActiveAccount()?.username,
		// 	};
		// }, [instance]);

		const request = {
			...(loginRequest as RedirectRequest),
			state: route,
			//prompt: 'select_account',
			account,
			loginHint: instance.getActiveAccount()?.name,
		};

		const Error = () => {
			return <div>Error during login...</div>;
		};

		return (
			<MsalTemplate
				interactionType={InteractionType.Redirect}
				authenticationRequest={request}
				username={account?.username}
				homeAccountId={account?.homeAccountId}
				localAccountId={account?.localAccountId}
				loadingComponent={Loading}
				errorComponent={Error}>
				<Component />
			</MsalTemplate>
		);
	};

	return Protected;
};

export default withAuthProtection;
