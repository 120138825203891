import './tools/librarySetup.ts'; // initialise tools like dayjs/duration
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from './Theme.tsx';
import { PublicClientApplication, EventType, type AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig.ts';
import { BrowserRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';
import './tools/LINQ.ts';
import getMsalInstance from './msal.ts';
import { initializeDynapacCache } from './data/fileCache.ts';

// buffer stuff needed for pdf generation
// import { Buffer as BufferPolyfill } from 'buffer';
// declare let Buffer: typeof BufferPolyfill;
// globalThis.Buffer = BufferPolyfill;

// ---------- configure libraries/plugins ----------//
// syncfusion license v25.1.35
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdkdhWn9WcnZURmBc');

const rootElement = document.getElementById('root');
if (rootElement == null) throw new Error('rootElement or baseUrl is null');

const root = createRoot(rootElement);

initializeDynapacCache()
	.then(() => {
		console.log('Dynapac cache initialized');
	})
	.catch((error) => {
		console.error('Error initializing Dynapac cache', error);
	});

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = getMsalInstance();
msalInstance
	.initialize()
	.then(() => msalInstance.handleRedirectPromise())
	.then(() => {
		// Default to using the first account if no account is active on page load
		if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
			// Account selection logic is app dependent. Adjust as needed for different use cases.
			msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
		}

		if (msalInstance.getActiveAccount()) {
			console.log(`Active account: ${msalInstance.getActiveAccount()?.username}`);
		}

		msalInstance.enableAccountStorageEvents();

		msalInstance.addEventCallback((event) => {
			if (
				event.eventType === EventType.LOGIN_SUCCESS ||
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
				event.eventType === EventType.SSO_SILENT_SUCCESS
			) {
				const result = event.payload as AuthenticationResult;
				//console.log(result);
				if (result.account) {
					msalInstance.setActiveAccount(result.account);
				}
			}
		});

		root.render(
			<React.StrictMode>
				<BrowserRouter>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<App instance={msalInstance} />
						</ThemeProvider>
					</StyledEngineProvider>
				</BrowserRouter>
			</React.StrictMode>
		);
	})
	.catch((error) => {
		console.error(error);
	});

// import { registerSW } from 'virtual:pwa-register'
// const updateSW = registerSW({
//   onNeedRefresh() {
//     console.log('serviceWorker updated. Needs refresh')
//     updateSW(true);
//   },

//   onOfflineReady() {

//   }
// });
