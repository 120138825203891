import type { Datum, Point } from './geometry/core/Coordinate';

export interface NearMapRequest {
    minLat: number;
    maxLat: number;
    minLon: number;
    maxLon: number;

    zoomLevel: number;

    apiKey: string;
}

export type NearmapFormat = 'jpeg' | 'png';

export type ZoomLevelPixelMap = Record<number, number>;

export function calcPixelSize(zoom: number, latitude: number = 0): number {
    const dist = 40_075_016.686 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom + 8);
    return Math.abs(dist);
}

export const zoomLevelPixelMap: Record<number, number> = {
    [0]: 156543.033928,
    [1]: 78271.516964,
    [2]: 39135.758482,
    [3]: 19567.879241,
    [4]: 9783.9396205,
    [5]: 4891.96981025,
    [6]: 2445.98490513,
    [7]: 1222.99245256,
    [8]: 611.496226281,
    [9]: 305.748113141,
    [10]: 152.87405657,
    [11]: 76.4370282852,
    [12]: 38.2185141426,
    [13]: 19.1092570713,
    [14]: 9.55462853565,
    [15]: 4.77731426782,
    [16]: 2.38865713391,
    [17]: 1.19432856695,
    [18]: 0.597164283477,
    [19]: 0.298582141739,
    [20]: 0.149291070869,
    [21]: 0.0746455354347,
    [22]: 0.0373227677174,
    [23]: 0.0186613838587,
    [24]: 0.00933069192937,
}

export interface NearMapWMSRequest {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;

    datum: Datum;
    zone: number;
    zoomLevel: number;

    format?: NearmapFormat;
    apiKey?: string;
}

export interface NearMapWMSResponse {
    image: ArrayBuffer;
    origin: Point;
}

export interface NearMapError {
    status: number;
    message: string;
    error?: unknown;
}