import { type Dayjs } from 'dayjs';
import { Helmert, HelmertSAA, IdentityHelmert, type IHelmertTransform } from './helmert';
import { type CartestianCoordinate, Datum, type GeodeticCoordinate, type GridCoordinate } from '../core/Coordinate';

type FixedLengthArray<T, L extends number> = T[] & { readonly length: L };
type Coefficients = FixedLengthArray<number, 8>;

const constants = {
  a: 6378137.0,          // semi-major axis
  finv: 298.257222101,      // inverse flattening (1 / f)
  f: 1 / 298.257222101,  // flattening (f)
  e2: 298.257222101 * (2 - 298.257222101),
  e: 0,

  E0: 500_000.0,
  N0: 10_000_000.0,
  k0: 0.9996,

  flattening: 298.257222101,
  n: 0,
  A: 0,

  coefficients: [0, 0, 0, 0, 0, 0, 0, 0] as FixedLengthArray<number, 8>,
  inverseCoefficients: [0, 0, 0, 0, 0, 0, 0, 0] as FixedLengthArray<number, 8>
}

function initializeConstants() {
  constants.f = 1 / constants.finv;
  constants.e2 = constants.f * (2 - constants.f);
  constants.e = Math.sqrt(constants.e2);

  constants.n = constants.f / (2 - constants.f);
  constants.A = constants.a / (1 + constants.n) * calcTempAConstant(constants.n);

  constants.coefficients = calculateCoefficients(constants.n);
  constants.inverseCoefficients = calculateInverseCoefficients(constants.n);
}

function calculateTransverseMercatorRatios(coefficients: Coefficients, etaDash: number, xiDash: number): [eta: number, xi: number] {
  let xi = xiDash;
  let eta = etaDash;

  const n = 8;
  for (let r = 1; r <= n; ++r) {
    eta += coefficients[r - 1] * Math.cos(2 * r * xiDash) * Math.sinh(2 * r * etaDash);
    xi += coefficients[r - 1] * Math.sin(2 * r * xiDash) * Math.cosh(2 * r * etaDash);
  }

  return [eta, xi];
}

function calculateGaussSchrieberRatios(coefficients: Coefficients, eta: number, xi: number): [etaDash: number, xiDash: number] {
  let etaDash = eta;
  let xiDash = xi;

  const n = 8;
  for (let r = 1; r <= n; ++r) {
    xiDash += coefficients[r - 1] * Math.sin(2 * r * xi) * Math.cosh(2 * r * eta);
    etaDash += coefficients[r - 1] * Math.cos(2 * r * xi) * Math.sinh(2 * r * eta);
  }

  return [etaDash, xiDash];
}

// function calculateScaleParameters(coefficients: Coefficients, etaDash: number, xiDash: number, n = 8): [scale: number, convergence: number] {
//   let convergence = 0.0;
//   let scale = 1.0;

//   for (let r = 1; r <= n; ++r) {

//     convergence += r * coefficients[r - 1] * Math.sin(2 * r * xiDash) * Math.sinh(2 * r * etaDash);
//     scale += r * coefficients[r - 1] * Math.cos(2 * r * xiDash) * Math.cosh(2 * r * etaDash);
//   }

//   return [scale, convergence];
// }


function calculateCoefficients(n: number): Coefficients {
  const n2 = n * n;
  const n3 = n2 * n;
  const n4 = n3 * n;
  const n5 = n4 * n;
  const n6 = n5 * n;
  const n7 = n6 * n;
  const n8 = n7 * n;

  const a2 = (0.5 * n)
    - (2.0 / 3 * n2)
    + (5.0 / 16 * n3)
    + (41.0 / 180 * n4)
    - (127.0 / 288 * n5)
    + (7891.0 / 37800 * n6)
    + (72161.0 / 387072 * n7)
    - (18975107.0 / 50803200 * n8);

  const a4 = (13.0 / 48 * n2)
    - (3.0 / 5 * n3)
    + (557.0 / 1440 * n4)
    + (281.0 / 630 * n5)
    - (1983433.0 / 1935360 * n6)
    + (13769.0 / 28800 * n7)
    + (148003883.0 / 174182400 * n8);

  const a6 = 61.0 / 240 * n3
    - 103.0 / 140 * n4
    + 15061.0 / 26880 * n5
    + 167603.0 / 181440 * n6
    - 67102379.0 / 29030400 * n7
    + 79682431.0 / 79833600 * n8;

  const a8 = 49561.0 / 161280 * n4
    - 179.0 / 168 * n5
    + 6601661.0 / 7257600 * n6
    + 97445.0 / 49896 * n7
    - 40176129013.0 / 7664025600 * n8;

  const a10 = 34729.0 / 80640 * n5
    - 3418889.0 / 1995840 * n6
    + 14644087.0 / 9123840 * n7
    + 2605413599.0 / 622702080 * n8;
  const a12 = 212378941.0 / 319334400 * n6
    - 30705481.0 / 10378368 * n7
    + 175214326799.0 / 58118860800 * n8;

  const a14 = 1522256789.0 / 1383782400 * n7
    - 16759934899.0 / 3113510400 * n8;

  const a16 = 1424729850961.0 / 743921418240 * n8;

  return [a2, a4, a6, a8, a10, a12, a14, a16];
}

function calculateInverseCoefficients(n: number): Coefficients {
  const n2 = n * n;
  const n3 = n2 * n;
  const n4 = n3 * n;
  const n5 = n4 * n;
  const n6 = n5 * n;
  const n7 = n6 * n;
  const n8 = n7 * n;

  const b2 = (-0.5 * n)
    + (2.0 / 3 * n2)
    - (37.0 / 96 * n3)
    + (1.0 / 360 * n4)
    + (81.0 / 512 * n5)
    - (96199.0 / 604800 * n6)
    + (5406467.0 / 38707200 * n7)
    - (7944359.0 / 67737600 * n8);

  const b4 = -(1.0 / 48 * n2)
    - (1.0 / 15 * n3)
    + (437.0 / 1440 * n4)
    - (46.0 / 105 * n5)
    + (1118711.0 / 3870720 * n6)
    - (51841.0 / 1209600 * n7)
    - (24749483.0 / 348364800 * n8);

  const b6 = -(17.0 / 480 * n3)
    + (37.0 / 840 * n4)
    + (209.0 / 4480 * n5)
    - (5569.0 / 90720 * n6)
    - (9261899.0 / 58060800 * n7)
    + (6457463.0 / 17740800 * n8);

  const b8 = -(4397.0 / 161280 * n4)
    + (11.0 / 504 * n5)
    + (830251.0 / 7257600 * n6)
    - (466511.0 / 2494800 * n7)
    - (324154477.0 / 7664025600 * n8);

  const b10 = -(4174.0 / 161280 * n5)
    + (108847.0 / 3991680 * n6)
    + (8005831.0 / 63866880 * n7)
    - (22894433.0 / 124540416 * n8);

  const b12 = -(20648693.0 / 638668800 * n6)
    + (16363163.0 / 518918400 * n7)
    + (2204645983.0 / 12915302400 * n8);

  const b14 = -(219941297.0 / 5535129600 * n7)
    + (497323811.0 / 12454041600 * n8);

  const b16 = -191773887257.0 / 3719607091200 * n8;

  return [b2, b4, b6, b8, b10, b12, b14, b16];
}

function calcTempAConstant(n: number): number {
  let tmpA = 0.0;
  const denominators = [1, 1, 1, 1, 25];

  const powers2 = [0, 2, 6, 8, 14];

  // ReSharper disable once LoopCanBeConvertedToQuery
  for (let i = 0; i < powers2.length; ++i) {
    tmpA += denominators[i] / Math.pow2(powers2[i]) * Math.pow(n, i * 2);
  }

  return tmpA;

}
function calculateZoneCentre(zone: number): number {
  return zone * 6 - 183;
}

function calculateZone(value: GeodeticCoordinate | number): number {
  if (typeof value === 'number') {
    return Math.floor((value + 180) / 6) + 1;
  } else {
    return Math.floor((value.lon + 180) / 6) + 1;
  }
}

function isGeodeticCoord(coord: GridCoordinate | GeodeticCoordinate): coord is GeodeticCoordinate {
  return 'lat' in coord && 'lon' in coord;
}

function calculateCartesianCoordinate(coord: GeodeticCoordinate): CartestianCoordinate {
  const { a, e2 } = constants;

  const phi = coord.lat.toRadians();
  const lambda = coord.lon.toRadians();
  const height = coord.alt ?? 0;

  const cosPhi = Math.cos(phi);
  const sinPhi = Math.sin(phi);
  const cosLambda = Math.cos(lambda);
  const sinLambda = Math.sin(lambda);
  const N = a / Math.sqrt(1 - e2 * sinPhi * sinPhi);

  const x = (N + height) * cosPhi * cosLambda;
  const y = (N + height) * cosPhi * sinLambda;
  const z = (N * (1 - e2) + height) * sinPhi;

  return { x, y, z };
}

function calculateGeodeticCoordinate(coord: CartestianCoordinate, datum: Datum): GeodeticCoordinate {
  const { a, e2, f } = constants;
  const { x, y, z } = coord;
  const p = Math.sqrt(x * x + y * y);
  const r = Math.sqrt(p * p + z * z);
  const u = Math.atan((z / p) * ((1 - f) + (e2 * a) / r));
  const e2a = e2 * a;

  let lambda = Math.atan(y / x);
  const phi = Math.atan((z * (1 - f) + e2a * Math.sin(u).cube()) / ((1 - f) * (p - e2a * Math.cos(u).cube())));
  if (lambda < 0)
    lambda += Math.PI;

  const lat = phi.toDegrees();
  const lon = lambda.toDegrees();
  const sinPhi = Math.sin(phi);
  const alt = p * Math.cos(phi) + z * sinPhi - a * Math.sqrt(1 - e2 * sinPhi.square());
  return {
    lat,
    lon,
    alt,
    datum
  };
}

export interface Adjustment2D {
  x: number;
  y: number;
}

export class MercatorTransform {
  private helmert: IHelmertTransform;
  private hasDatumShift: boolean;
  private adj?: Adjustment2D;

  get adjustment(): Adjustment2D | undefined {
    return this.adj;
  }

  set adjustment(value: Adjustment2D | undefined) {
    this.adj = value;
  }

  constructor(helmert: IHelmertTransform, adjustment?: Adjustment2D) {
    this.helmert = helmert;
    this.hasDatumShift = helmert.sourceDatum !== helmert.destDatum;
    this.adj = adjustment;

    if (constants.A === 0) {
      initializeConstants();
    }

    if (redfearn.A0 === 0) {
      calculateRedfearnConstants();
    }
  }

  public transformRedfearn(coord: GridCoordinate | GeodeticCoordinate): GridCoordinate {
    let geodetic = isGeodeticCoord(coord) ? coord : this.redfearnReverseMercatorTransform(coord);

    if (this.hasDatumShift) {
      // convert to cartesian, apply transform then convert back to geodetic
      const cartesian = calculateCartesianCoordinate(geodetic);
      const transformed = this.helmert.transform(cartesian);

      // convert back to geodetic with the destination datum
      const destDatum = this.helmert.destDatum;
      geodetic = calculateGeodeticCoordinate(transformed, destDatum);
    }

    // transform geodetic to grid
    return this.adjust(this.redfearnMercatorTransform(geodetic));
  }

  public transform(coord: GridCoordinate | GeodeticCoordinate): GridCoordinate {
    if (isGeodeticCoord(coord)) {
      let geodetic = coord;

      if (this.hasDatumShift) {
        // convert to cartesian, apply transform then convert back to geodetic
        const cartesian = calculateCartesianCoordinate(coord);
        const transformed = this.helmert.transform(cartesian);

        // convert back to geodetic with the destination datum
        const destDatum = this.helmert.destDatum;
        geodetic = calculateGeodeticCoordinate(transformed, destDatum);
      }

      // transform geodetic to grid
      return this.adjust(this.mercatorTransform(geodetic));
    } else {
      const geodetic = this.reverseMercatorTransform(coord);
      return this.transform(geodetic);
    }
  }

  private adjust(coord: GridCoordinate): GridCoordinate {
    if (this.adj) {
      return {
        x: coord.x + this.adj.x,
        y: coord.y + this.adj.y,
        datum: coord.datum,
        zone: coord.zone
      };
    }

    return coord;
  }

  private reverseAdjust(coord: GridCoordinate): GridCoordinate {
    if (this.adj) {
      return {
        x: coord.x - this.adj.x,
        y: coord.y - this.adj.y,
        datum: coord.datum,
        zone: coord.zone
      };
    }

    return coord;
  }

  private mercatorTransform(coord: GeodeticCoordinate): GridCoordinate {
    const zone = calculateZone(coord);
    const zoneCentre = calculateZoneCentre(zone);

    const { lat, lon } = coord;
    // 1-3) extract ellipsoid constants, rectifying radius and coefficients
    const { A, e, coefficients, k0, E0, N0 } = constants;

    // 4) compute conformal latitude
    const t = Math.tan(lat.toRadians());
    const rho = Math.sinh(e * Math.atanh(e * t / Math.sqrt(1 + t.square())));

    const tanPhiDash = t * Math.sqrt(1 + rho.square()) - rho * Math.sqrt(1 + t.square());

    const latConformal = Math.atan(tanPhiDash);

    // 5) compute longitude difference (in radians)
    const w = (lon - zoneCentre).toRadians();

    // 6) compute Gauss-Schrieber ratios
    const xi_dash = Math.atan(Math.tan(latConformal) / Math.cos(w));
    const eta_dash = Math.asinh(Math.sin(w) / Math.sqrt(Math.tan(latConformal).square() + Math.cos(w).square()));

    // 7) compute transverse mercator ratios
    const [eta, xi] = calculateTransverseMercatorRatios(coefficients, eta_dash, xi_dash);

    // 8) compute transverse mercator coordinates (X, Y)
    const X = A * eta;
    const Y = A * xi;

    // 9) compute MGA2020 coordinates (E, N)
    const E = k0 * X + E0;
    const N = k0 * Y + N0;

    return {
      x: E,
      y: N,
      datum: Datum.GDA2020,
      zone
    };
  }

  private reverseMercatorTransform(coord: GridCoordinate): GeodeticCoordinate {
    const { x, y, zone } = coord;
    const zoneCentre = calculateZoneCentre(zone);

    const { A, e, e2, inverseCoefficients, k0, E0, N0 } = constants;

    // 5) compute transverse mercator coordinates (X, Y)
    const X = (x - E0) / k0;
    const Y = (y - N0) / k0;

    // 6) compute transverse mercator ratios
    const eta = X / A;
    const xi = Y / A;

    // 7) compute Gauss-Schrieber ratios
    const [eta_dash, xi_dash] = calculateGaussSchrieberRatios(inverseCoefficients, eta, xi);

    // 8) compute t' = tan(phi')
    const t_dash = Math.sin(xi_dash) / Math.sqrt(Math.sinh(eta_dash).square() + Math.cos(xi_dash).square());

    // 9) solve for t = tan(phi) by Newton-Raphson iteration then compute latitude (lat)
    let t = t_dash;
    let diff = 1.0;

    // iteratively solve for t
    //  -> t_n+1 = t_n - f(t_n) / f'(t_n)
    while (diff > 1e-12) {
      const t2 = t * t;
      const sqrt1_t2 = Math.sqrt(1 + t2);

      // rho = sinh{epsilon x atanh(epsilon x t / sqrt(1 + t^2))}
      const r = Math.sinh(e * Math.atanh(e * t / sqrt1_t2));
      const r2 = r * r;
      const sqrt1_r2 = Math.sqrt(1 + r2);

      // f(t) = t(sqrt(1 + rho^2)) - rho(sqrt(1 + t^2)) - t'
      const f_t = t * sqrt1_r2 - r * sqrt1_t2 - t_dash;

      //                                                           (1 - e^2) x sqrt(1 + t^2)
      // f'(t) = { sqrt(1 + rho^2) x sqrt(1 + t^2) - rho x t } x { ------------------------- }
      //                                                              1 + (1 - e^2) x t^2
      const fPrime_t = (sqrt1_r2 * sqrt1_t2 - r * t) * ((1 - e2) * sqrt1_t2 / (1 + (1 - e2) * t2));

      // t_n+1 = t_n - f(t_n) / f'(t_n)
      const tNext = t - (f_t / fPrime_t);

      // calculate difference
      diff = Math.abs(tNext - t);

      // set t_n+1 = t_n
      t = tNext;
    }

    // compute latitude
    const lat = Math.atan(t).toDegrees();

    // 10) compute longitude difference (w) and longitude (lon)
    const w = Math.atan(Math.sinh(eta_dash) / Math.cos(xi_dash));

    // lon = zoneCenter +/- w... not sure how i'm supposed to decide between +w vs -w...
    const lon = zoneCentre + w.toDegrees();

    return {
      lat,
      lon,
      alt: coord.height,
      datum: coord.datum
    };
  }

  public redfearnMercatorTransform(coord: GeodeticCoordinate): GridCoordinate {
    const { lat, lon } = coord;
    const zoneCentre = calculateZoneCentre(calculateZone(lon));

    const { a, k0, E0, N0 } = constants;
    const { e2, A0, A2, A4, A6 } = redfearn;

    const phi = lat.toRadians();

    const w = (lon - zoneCentre).toRadians();
    const w2 = w * w;
    const w3 = w2 * w;
    const w4 = w3 * w;
    const w5 = w4 * w;
    const w6 = w5 * w;
    const w7 = w6 * w;
    const w8 = w7 * w;

    const cosPhi = Math.cos(phi);
    const cosPhi3 = cosPhi * cosPhi * cosPhi;
    const cosPhi5 = cosPhi3 * cosPhi * cosPhi;
    const cosPhi7 = cosPhi5 * cosPhi * cosPhi;

    const sinPhi = Math.sin(phi);
    const sinPhi2 = sinPhi * sinPhi;

    const tanPhi2 = Math.tan(phi).square();
    const tanPhi4 = tanPhi2 * tanPhi2;
    const tanPhi6 = tanPhi4 * tanPhi2;

    const rho = a * (1 - e2) / (1 - e2 * sinPhi2).pow(1.5);
    const nu = a / Math.sqrt(1 - e2 * sinPhi2);
    const psi = nu / rho;
    const psi2 = psi.square();
    const psi3 = psi2 * psi;
    const psi4 = psi2 * psi2;

    // calculate meridian distance to the 4th order
    const m = this.calculateMeridianDistance(phi, a, A0, A2, A4, A6);

    const E1 = nu * cosPhi * w;
    const E2 = nu * w3 * cosPhi3 * (psi - tanPhi2) / 6;
    const E3 = nu * w5 * cosPhi5 * (4 * psi3 * (1 - 6 * tanPhi2) + psi2 * (1 + 8 * tanPhi2) - psi * 2 * tanPhi2 + tanPhi4) / 120;
    const E4 = nu * w7 * cosPhi7 * (61 - 479 * tanPhi2 + 179 * tanPhi4 - tanPhi6) / 5040;

    const N1 = nu * sinPhi * cosPhi * w2 / 2;
    const N2 = nu * sinPhi * cosPhi3 * w4 * (4 * psi2 + psi - tanPhi2) / 24;
    const N3 = nu * sinPhi * cosPhi5 * w6 * (8 * psi4 * (11 - 24 * tanPhi2) - 28 * psi3 * (1 - 6 * tanPhi2) + psi2 * (1 - 32 * tanPhi2) - psi * 2 * tanPhi2 + tanPhi4) / 720;
    const N4 = nu * sinPhi * cosPhi7 * w8 * (1385 - 3111 * tanPhi2 + 543 * tanPhi4 - tanPhi6) / 40320;

    const E = k0 * (E1 + E2 + E3 + E4) + E0;
    const N = k0 * (m + N1 + N2 + N3 + N4) + N0;

    return {
      x: E,
      y: N,
      datum: Datum.GDA2020,
      zone: calculateZone(lon)
    };
  }

  public redfearnReverseMercatorTransform(coord: GridCoordinate): GeodeticCoordinate {
    const { x: easting, y: northing, zone } = coord;
    const { a, k0, E0, N0 } = constants;
    const { G, e2, n, n2, n3, n4 } = redfearn;
    const zoneCentre = calculateZoneCentre(zone);

    const EPrime = (easting - E0);
    const EPrimeScaled = EPrime / k0;

    const NPrime = (northing - N0);
    const m = NPrime / k0;

    const sigma = m * Math.PI / (180 * G);
    const sigma2 = sigma * 2;
    const sigma4 = sigma * 4;
    const sigma6 = sigma * 6;
    const sigma8 = sigma * 8;

    // foot point latitude
    const fpt1 = sigma;
    const fpt2 = ((3 * n / 2) - (27 * n3 / 32)) * Math.sin(sigma2);
    const fpt3 = ((21 * n2 / 16) - (55 * n4 / 32)) * Math.sin(sigma4);
    const fpt4 = (151 * n3 / 96) * Math.sin(sigma6);
    const fpt5 = (1097 * n4 / 512) * Math.sin(sigma8);

    const j = fpt1 + fpt2 + fpt3 + fpt4 + fpt5;
    const sinj = Math.sin(j);
    const secj = 1 / Math.cos(j);

    const rho = a * (1 - e2) / (1 - e2 * sinj.square()).pow(1.5);
    const nu = a / Math.sqrt(1 - e2 * sinj.square());

    const t1 = Math.tan(j);
    const t2 = t1.square();
    const t4 = t2.square();
    const t6 = t4 * t2;

    const x1 = EPrimeScaled / nu;
    const x3 = x1 * x1 * x1;
    const x5 = x3 * x1 * x1;
    const x7 = x5 * x1 * x1;

    //const E1 = EPrimeScaled.square() / (rho * nu);
    //const E2 = E1.square();
    //const E3 = E2 * E1;

    const y1 = nu / rho;
    const y2 = y1.square();
    const y3 = y2 * y1;
    const y4 = y3 * y1;

    const lat1 = -((t1 / (k0 * rho)) * x1 * EPrime / 2);
    const lat2 = ((t1 / (k0 * rho)) * x3 * EPrime / 24) * (-4 * y2 + 9 * y1 * (1 - t2) + 12 * t2);
    const lat3 = -(t1 / (k0 * rho)) * x5 * (EPrime / 720) * (8 * y4 * (11 - 24 * t2) - 12 * y3 * (21 - 71 * t2) + 15 * y2 * (15 - 98 * t2 + 15 * t4) + 180 * y1 * (5 * t2 - 3 * t4) + 360 * t4);
    const lat4 = ((t1 / (k0 * rho)) * x7 * EPrime / 40320) * (1385 - 3633 * t2 + 4095 * t4 + 1575 * t6);
    const lat = j + lat1 + lat2 + lat3 + lat4;

    const cm = zoneCentre.toRadians(); // central meridian
    const lon1 = secj * x1;
    const lon2 = -secj * x3 / 6 * (y1 + 2 * t2);
    const lon3 = secj * x5 / 120 * (-4 * y3 * (1 - 6 * t2) + y2 * (9 - 68 * t2) + 72 * y1 * t2 + 24 * t4);
    const lon4 = -secj * x7 / 5040 * (61 + 662 * t2 + 1320 * t4 + 720 * t6);

    const lon = cm + lon1 + lon2 + lon3 + lon4;

    return {
      lat: lat.toDegrees(),
      lon: lon.toDegrees(),
      datum: coord.datum,
    };
    //const rhoPrime = a * (1 - e2) / (1 - e2 * k0.square()).pow(1.5);
  }

  private calculateMeridianDistance(phi: number, a: number, A0: number, A2: number, A4: number, A6: number): number {
    const m1 = a * A0 * phi;
    const m2 = -a * A2 * Math.sin(2 * phi);
    const m3 = a * A4 * Math.sin(4 * phi);
    const m4 = -a * A6 * Math.sin(6 * phi);

    return m1 + m2 + m3 + m4;
  }

  static fromWGS84ToGDA2020(epoch: Dayjs): MercatorTransform {
    return new MercatorTransform(Helmert.createWGS84ToGDA2020(epoch));
  }

  static fromGDA94ToGDA2020(): MercatorTransform {
    return new MercatorTransform(Helmert.createGDA94ToGDA2020());
  }

  static fromGDA2020ToGDA94(): MercatorTransform {
    return new MercatorTransform(Helmert.createGDA2020ToGDA94());
  }

  static fromWGS84ToGDA2020SAA(epoch: Dayjs): MercatorTransform {
    return new MercatorTransform(HelmertSAA.createWGS84ToGDA2020(epoch));
  }

  static fromGDA94ToGDA2020SAA(): MercatorTransform {
    return new MercatorTransform(HelmertSAA.createGDA94ToGDA2020());
  }

  static fromGDA2020ToGDA94SAA(): MercatorTransform {
    return new MercatorTransform(HelmertSAA.createGDA2020ToGDA94());
  }

  static createGDA2020(): MercatorTransform {
    return new MercatorTransform(IdentityHelmert.createGDA2020());
  }

  static createFromSingleDatum(datum: Datum) {
    return new MercatorTransform(IdentityHelmert.create(datum));
  }
}

const redfearn = {
  finv: 298.257222101,
  f: 0,
  a: 6378137.0,
  b: 0,

  e2: 0,

  A0: 0,
  A2: 0,
  A4: 0,
  A6: 0,

  n: 0,
  n2: 0,
  n3: 0,
  n4: 0,
  G: 0
}

function calculateRedfearnConstants() {

  const e2 = 0.006694380023;
  const e4 = e2.square();
  const e6 = e2.cube();

  const A0 = 1 - e2 / 4 - 3 * e4 / 64 - 5 * e6 / 256;
  const A2 = 3 / 8 * (e2 + e4 / 4 + 15 * e6 / 128);
  const A4 = 15 / 256 * (e4 + 3 * e6 / 4);
  const A6 = 35 / 3072 * e6;

  redfearn.f = 1 / redfearn.finv;
  redfearn.b = redfearn.a * (1 - redfearn.f);

  redfearn.e2 = e2;

  redfearn.A0 = A0;
  redfearn.A2 = A2;
  redfearn.A4 = A4;
  redfearn.A6 = A6;

  const n = (redfearn.a - redfearn.b) / (redfearn.a + redfearn.b);
  const n2 = n * n;
  const n3 = n2 * n;
  const n4 = n3 * n;
  const G = redfearn.a * (1 - n) * (1 - n2) * (1 + 9 * n2 / 4 + 225 * n4 / 64) * Math.PI / 180;

  redfearn.n = n;
  redfearn.n2 = n2;
  redfearn.n3 = n3;
  redfearn.n4 = n4;
  redfearn.G = G;
}