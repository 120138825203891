import type { CMVType, FailureReason } from '@analysis/RollerAnalysis';
import type { Point, Rect } from '@shared/geometry/core/Coordinate';
import type { RollerCell } from '../../../data/rollerTypes';

export function diffPoints(p1: Point, p2: Point) {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y
  };
}

export const passCountColors: string[] = [
  'darkred',
  'red',
  'orange',
  'yellow',
  'greenyellow',
  'limegreen',
  'cyan',
  'blue',
  'purple',
  'magenta',
];

export const cmvColors: string[] = [
  'red',
  'orangered',
  'darkorange',
  'orange',
  'yellow',
  'limegreen',
  'springgreen',
  'cyan',
  'dodgerblue',
  'blue',
];

export interface CMVRange {
  min: number; // inclusive min
  max: number; // exclusive max
  color: string;
}

export const CMVBins: number[] = [
  0,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200,
  210,
  220,
  230,
  240,
  250
];

export const cmvColorRanges: CMVRange[] = [
  { min: 0, max: 10, color: 'darkred' },
  { min: 10, max: 0, color: 'red' },
  { min: 20, max: 0, color: 'orange' },
  { min: 30, max: 0, color: 'yellow' },
  { min: 40, max: 0, color: 'greenyellow' },
  { min: 60, max: 0, color: 'limegreen' },
  { min: 80, max: 0, color: 'springgreen' },
  { min: 100, max: 0, color: 'dodgerblue' },
  { min: 150, max: 250, color: 'blue' },
];

(function initCMVColorRanges() {
  for (let i = 0; i < cmvColorRanges.length - 1; ++i) {
    cmvColorRanges[i].max = cmvColorRanges[i + 1].min;
  }
})();

export const failureColors: Record<FailureReason, string> = {
  // asphalt spec failures
  'passes': 'red',
  'temperature': 'orange',
  'time': 'blue',

  // base spec failures
  'cmv': 'red',
  'invalid': 'black'
};

export function getCellColor(cell: RollerCell): string {
  if (cell.passes.length >= passCountColors.length) {
    return passCountColors[passCountColors.length - 1];
  }

  return passCountColors[cell.passes.length - 1];
}

export function getCMVBinIndex(cmv: number): number {
  for (let i = CMVBins.length - 1; i >= 0; --i) {
    if (cmv >= CMVBins[i]) {
      return i;
    }
  }

  return 0;
}

export function getCMVColorIndex(cmv: number): number {
  for (let i = cmvColorRanges.length - 1; i >= 0; --i) {
    const range = cmvColorRanges[i];
    if (cmv >= range.min) {
      return i;
    }
  }

  return 0; // default to the lowest color
}

export function getBaseMapCellColor(cell: RollerCell, type: CMVType): string {
  const cmv = cell.getCMV(type);
  // if CMV does not exist or somehow has a garbage (< 0) value, return black
  if (cmv === null || cmv < 0) {
    return 'black';
  }

  return cmvColorRanges[getCMVColorIndex(cmv)].color;
}

export function isWithinRect(point: Point, rect: Rect): boolean {
  return (
    point.x >= rect.x &&
    point.x <= rect.x + rect.width &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.height
  );
}
