import roboto400 from './fonts/Roboto-Regular.ttf';
import roboto400Italic from './fonts/Roboto-Italic.ttf';
import roboto300 from './fonts/Roboto-Light.ttf';
import roboto300Italic from './fonts/Roboto-LightItalic.ttf';
import roboto500 from './fonts/Roboto-Medium.ttf';
import roboto500Italic from './fonts/Roboto-MediumItalic.ttf';
import roboto900 from './fonts/Roboto-Bold.ttf';
import roboto900Italic from './fonts/Roboto-BoldItalic.ttf';
import montserrat200 from './fonts/Montserrat-ExtraLight.ttf';
import montserrat300 from './fonts/Montserrat-Light.ttf';
import montserrat400 from './fonts/Montserrat-Regular.ttf';
import montserrat500 from './fonts/Montserrat-Medium.ttf';
import montserrat700 from './fonts/Montserrat-Bold.ttf';
import montserrat900 from './fonts/Montserrat-Black.ttf';
import montserrat200Italic from './fonts/Montserrat-ExtraLightItalic.ttf';
import montserrat300Italic from './fonts/Montserrat-LightItalic.ttf';
import montserrat400Italic from './fonts/Montserrat-Italic.ttf';
import montserrat500Italic from './fonts/Montserrat-MediumItalic.ttf';
import montserrat700Italic from './fonts/Montserrat-BoldItalic.ttf';
import montserrat900Italic from './fonts/Montserrat-BlackItalic.ttf';

import { Font } from '@react-pdf/renderer';


export default function loadFonts() {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: roboto400,
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      {
        src: roboto400Italic,
        fontStyle: 'italic',
        fontWeight: 'normal',
      },
      {
        src: roboto300,
        fontStyle: 'normal',
        fontWeight: 'light',
      },
      {
        src: roboto300Italic,
        fontStyle: 'italic',
        fontWeight: 'light',
      },
      {
        src: roboto500,
        fontStyle: 'normal',
        fontWeight: 'medium',
      },
      {
        src: roboto500Italic,
        fontStyle: 'italic',
        fontWeight: 'medium',
      },
      {
        src: roboto900,
        fontStyle: 'normal',
        fontWeight: 'bold',
      },
      {
        src: roboto900Italic,
        fontStyle: 'italic',
        fontWeight: 'bold',
      },
    ],
  });

  Font.register({
    family: 'Montserrat',
    fonts: [
      {
        src: montserrat200Italic,
        fontStyle: 'italic',
        fontWeight: 'thin',
      },
      {
        src: montserrat300Italic,
        fontStyle: 'italic',
        fontWeight: 'light',
      },
      {
        src: montserrat400Italic,
        fontStyle: 'italic',
        fontWeight: 'normal',
      },
      {
        src: montserrat500Italic,
        fontStyle: 'italic',
        fontWeight: 'medium',
      },
      {
        src: montserrat700Italic,
        fontStyle: 'italic',
        fontWeight: 'bold',
      },
      {
        src: montserrat900Italic,
        fontStyle: 'italic',
        fontWeight: 'heavy',
      },
      {
        src: montserrat200,
        fontStyle: 'normal',
        fontWeight: 'thin',
      },
      {
        src: montserrat300,
        fontStyle: 'normal',
        fontWeight: 'light',
      },
      {
        src: montserrat400,
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      {
        src: montserrat500,
        fontStyle: 'normal',
        fontWeight: 'medium',
      },
      {
        src: montserrat700,
        fontStyle: 'normal',
        fontWeight: 'bold',
      },
      {
        src: montserrat900,
        fontStyle: 'normal',
        fontWeight: 'heavy',
      },
    ]

  });
}