
/*
    Note: The recommended way to do this is assigning it to window.indexedDB,
    to avoid potential issues in the global scope when web browsers start
    removing prefixes in their implementations.
    You can assign it to a varible, like var indexedDB… but then you have
    to make sure that the code is contained within a function.
*/

const dbName = 'pavesetDatabase';
const storeName = 'dynapacCache';
const dbVersion = 1;

let db: IDBDatabase | null = null;

export function initializeDynapacCache(): Promise<void> {
  if (db) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('pavesetDatabase', dbVersion);

    request.onerror = function (this: IDBRequest<IDBDatabase>, _ev: Event) {
      reject(request.error);
    };

    request.onsuccess = function (this: IDBRequest<IDBDatabase>, _ev: Event) {
      db = request.result;
      resolve();
    };

    request.onupgradeneeded = function (this: IDBOpenDBRequest, _ev: IDBVersionChangeEvent) {
      db = request.result;
      db.createObjectStore('dynapacCache');
    };
  });
}

export function checkDynapacFileExists(key: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onerror = (_event) => {
      reject(`Database error: ${request.error}`);
    };

    request.onsuccess = (_event) => {
      const db = request.result;
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const getKeyRequest = store.getKey(key);

      getKeyRequest.onerror = (_event) => {
        reject(`Get request error: ${getKeyRequest.error}`);
      };

      getKeyRequest.onsuccess = (_event) => {
        if (getKeyRequest.result !== undefined) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      transaction.oncomplete = () => {
        db.close();
      };
    };

    request.onupgradeneeded = (_event) => {
      db?.createObjectStore(storeName);
      reject('Database upgrade is needed but not handled in this function.');
    };
  });
}

export function getDynapacFile(key: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!db) {
      reject('Database not open');
      return;
    }

    const transaction = db.transaction(['dynapacCache'], 'readonly');
    const store = transaction.objectStore('dynapacCache');
    const request = store.get(key);

    request.onsuccess = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
      resolve(request.result);
    };

    request.onerror = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
      reject(request.error);
    };
  });
}

export function saveDynapacFile(key: string, value: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!db) {
      reject('Database not open');
      return;
    }

    const transaction = db.transaction(['dynapacCache'], 'readwrite');
    const store = transaction.objectStore('dynapacCache');
    const request = store.put(value, key);

    request.onsuccess = function (_ev: Event) {
      resolve();
    };

    request.onerror = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
      reject(request.error);
    };
  });
}