import { Stack, Autocomplete, Box, TextField, CircularProgress, Typography, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import type { DynapacProjectDetails, DynapacObjectDetails, DynapacLayerDetails } from '@shared/dynapacTypes';
import { type DataItem, type DynapacSelectionState } from '../../dataPickerSlice';
import { memo } from 'react';
//import ActiveLayersView from './activeLayers/ActiveLayersView';

function isLayersEmpty(object: DynapacObjectDetails | null | undefined): boolean {
	if (!object) return true;

	return object.Layers?.filter((l) => l.Layer_ID && l.Layer_Name).length === 0;
}

export interface ProjectsViewProps {
	isDownloading: boolean;
	isLoading: boolean;

	data?: DynapacProjectDetails[];
	project?: DynapacProjectDetails | null;
	object?: DynapacObjectDetails | null;
	layer?: DynapacLayerDetails | null;

	updateSelection: (selection: Partial<DynapacSelectionState>) => void;

	selectedItems: DataItem[];
	removeItem: (item: DataItem) => void;
}

const ProjectsView = (props: ProjectsViewProps) => {
	const {
		isDownloading,
		isLoading,

		data,
		project,
		object,
		layer,

		selectedItems,
		updateSelection,
		removeItem,
	} = props;

	return (
		<Stack direction='column' spacing={1}>
			<Autocomplete
				disabled={isDownloading}
				loading={isLoading}
				options={data?.map((job) => job) ?? []}
				getOptionLabel={(option) => option.Project_Name}
				getOptionKey={(option) => option.Project_ID}
				isOptionEqualToValue={(option, value) => option.Project_ID === value?.Project_ID}
				sx={{ width: '100%' }}
				value={project}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacProjectDetails | null) =>
					updateSelection({ project: newValue, object: null, layer: null })
				}
				renderOption={(props, option) => (
					<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
						{option.Project_Name} ({option.Objects?.length || 0} objects)
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Jobs'
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading && <CircularProgress color='inherit' size={20} />}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>

			<Autocomplete
				disabled={isDownloading}
				loading={isLoading}
				options={project?.Objects ?? []}
				getOptionKey={(option) => option.Object_ID}
				getOptionLabel={(option) => option?.Object_Name ?? ''}
				isOptionEqualToValue={(option, value) => option.Object_ID === value?.Object_ID}
				sx={{ width: '100%' }}
				value={object}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacObjectDetails | null) => {
					updateSelection({ object: newValue, layer: null });
				}}
				renderOption={(props, option) => (
					<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
						{option.Object_Name} ({option.Layers?.filter((l) => l.Layer_ID && l.Layer_Name).length || 0}{' '}
						layers)
					</Box>
				)}
				renderInput={(params) => <TextField {...params} label='Objects' variant='outlined' />}
			/>

			<Autocomplete
				disabled={isDownloading}
				loading={isLoading}
				options={isLayersEmpty(object) ? [] : object!.Layers}
				getOptionKey={(option) => option.Layer_ID}
				getOptionLabel={(option) => option.Layer_Name ?? 'N/A'}
				isOptionEqualToValue={(option, value) => option.Layer_ID === value?.Layer_ID}
				sx={{ width: '100%' }}
				value={layer}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacLayerDetails | null) => {
					updateSelection({ layer: newValue });
				}}
				renderInput={(params) => <TextField {...params} label='Layers' variant='outlined' />}
			/>

			<Stack direction='column' spacing={1}>
				{selectedItems.map((item) => (
					<Box key={item.key} sx={{ position: 'relative', width: 1, pl: 1, pr: 1 }}>
						<Typography
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								mt: 0.5,
								mb: 0.5,
								mr: '24px',
								fontSize: { xs: '0.75rem', sm: '1rem' },
							}}>
							{item.name}
						</Typography>

						<Button
							onClick={() => {
								removeItem(item);
							}}
							sx={{
								position: 'absolute',
								right: 0,
								top: 0,
								m: 0,
								p: '2px',
								minWidth: 0,
								alignSelf: 'center',
							}}>
							<DeleteOutlineIcon color='action' />
						</Button>
					</Box>
				))}
			</Stack>

			{/* <ActiveLayersView items={selectedItems} /> */}
		</Stack>
	);
};

const MemoizedProjectsView = memo(ProjectsView);
export default MemoizedProjectsView;
