import { Datum, type GeodeticCoordinate, type GridCoordinate, type Point } from '@shared/geometry/core/Coordinate';
import dayjs, { type Dayjs } from 'dayjs';
import { PreparedPolygon } from '@shared/geometry/core/Polygon';
import { type AsphaltCompactionSpecificationCellResult, type FailureReason, type CompactionSpecification, type CompactionSpecificationCellResult, isAsphaltSpec, isBaseSpec, type BaseCompactionSpecificationCellResult, type CMVType } from '@analysis/RollerAnalysis';
import { shallowEqual } from 'react-redux';
import HashedMap from '../tools/HashedMap';
import { calculateOutlinePolygons } from './outlineTracer';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';
import type { GeoJSON } from 'geojson';

export type AmplitudeSetting = 'Static' | 'Low' | 'High' | 'Unknown';
export type GNSSQuality = 'High' | 'Low' | 'Unknown';

export interface RollerPass {
  timestamp: Dayjs;
  rollerId: string;

  coords: GridCoordinate;

  passNumber: number;
  temperature: number;
  speed: number;

  amplitudeSetting: AmplitudeSetting;
  amplitude: number;

  cmv: number | null;

  gnss: GNSSQuality;
}

function isHeaderLine(line: string[]): boolean {
  return line.some((val) => {
    const lower = val.toLowerCase();

    return lower.includes('east') || lower.includes('lat');
  });
}

export class Boundary {
  coords: GridCoordinate[];
  polygon: PreparedPolygon;

  get area(): number {
    return this.polygon.calculateArea();
  }

  get bounds(): Bounds {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    for (const coord of this.coords) {
      if (coord.x < minX) { minX = coord.x; }
      if (coord.y < minY) { minY = coord.y; }
      if (coord.x > maxX) { maxX = coord.x; }
      if (coord.y > maxY) { maxY = coord.y; }
    }

    const centreX = (minX + maxX) / 2;
    const centreY = (minY + maxY) / 2;
    return {
      min: { x: minX, y: minY },
      max: { x: maxX, y: maxY },
      centre: { x: centreX, y: centreY },
      width: maxX - minX,
      height: maxY - minY
    };
  }

  constructor(coords: GridCoordinate[]) {
    this.coords = coords;
    if (coords[0] !== coords[coords.length - 1]) {
      this.coords.push(coords[0]);
    }

    this.polygon = PreparedPolygon.fromCoordinates(this.coords);
  }

  equals(other: Boundary): boolean {
    if (this.coords.length !== other.coords.length) {
      return false;
    }

    for (let i = 0; i < this.coords.length; ++i) {
      if (this.coords[i].x !== other.coords[i].x || this.coords[i].y !== other.coords[i].y) {
        return false;
      }
    }

    return true;
  }

  static async parseFile(file: File, datum: Datum): Promise<Boundary | Boundary[]> {
    const text = await file.text();

    if (file.name.endsWith('.geojson')) {
      return Boundary.parseGeoJSON(text, datum);
    }

    // TODO: implement a method for defining the MGA zone of the CSV data
    return Boundary.parseCSV(text.split(/\r?\n/), 56, datum);
  }

  static parseCSV(lines: string[], zone: number, datum: Datum): Boundary {
    const cleanLines = lines.filter((line) => !!line);
    const header = lines[0].split(',');

    if (isHeaderLine(header)) {
      // parse file that contains a header line (i.e. from Emlid probably)
      const eastingIndex = header.findIndex((val) => val.toLowerCase().includes('east'));
      const northingIndex = header.findIndex((val) => val.toLowerCase().includes('north'));
      const csIndex = header.findIndex((val) => val.toLowerCase().includes('cs'));

      if (eastingIndex === -1 || northingIndex === -1) {
        throw new Error('Invalid boundary file');
      }

      const coords = cleanLines
        .slice(1)
        .map((line) => line.split(','))
        .map((split) => {
          const easting = parseFloat(split[eastingIndex]);
          const northing = parseFloat(split[northingIndex]);
          if (csIndex !== -1) {
            const cs = split[csIndex].toUpperCase();
            if (cs.includes('GDA') && cs.includes('MGA')) {
              const datumString = cs.split('/')[0].trim();
              const datumVal = ({
                'GDA94': Datum.GDA94,
                'GDA2020': Datum.GDA2020
              })[datumString];

              const csSplit = cs.split('MGA ZONE');
              const zoneVal = parseInt(csSplit[1].trim());

              console.log(datumVal, ' MGA', zoneVal);
              return {
                x: easting,
                y: northing,
                zone: zoneVal ?? zone,
                datum: datumVal ?? datum
              };
            }
          }

          return {
            x: easting,
            y: northing,
            zone,
            datum
          };
        });

      return new Boundary(coords);
    } else {
      // parse file that does not contain a header line
      const coords = cleanLines
        .map((line) => line.split(/[\s,;\t\n]+/))
        .map((split) => {
          const northingIndex = split.findIndex((val) => parseFloat(val) > 4_000_000);
          if (northingIndex === -1) {
            throw new Error('Invalid boundary line');
          }

          const northing = parseFloat(split[northingIndex]);
          let easting = parseFloat(split[northingIndex - 1]);
          if (isNaN(easting) || easting < 100_000 || easting > 1_000_000) {
            easting = parseFloat(split[northingIndex + 1]);
          }

          return {
            x: easting,
            y: northing,
            zone,
            datum
          };
        });

      return new Boundary(coords);
    }
  }

  static parseGeoJSON(json: string, datum: Datum): Boundary[] {
    // create a transform that doesn't do any datum shifting and assumes the data is in GDA2020
    const transform: MercatorTransform = MercatorTransform.createGDA2020();
    const geoJSON: GeoJSON = JSON.parse(json) as GeoJSON;

    if (geoJSON.type !== 'FeatureCollection') {
      throw new Error('Invalid GeoJSON file');
    }

    const features = geoJSON.features;

    const boundaries: Boundary[] = [];

    for (const feature of features) {
      if (feature.geometry.type === 'Polygon') {
        const polygon = feature.geometry as GeoJSON.Polygon;

        // the 1st ring of a polygon contains the outer boundary
        const coords: GridCoordinate[] = [];
        for (const point of polygon.coordinates[0]) {
          const geodetic: GeodeticCoordinate = {
            lat: point[1],
            lon: point[0],

            datum: datum
          };

          const coord = transform.transform(geodetic);
          coords.push(coord);
        }

        boundaries.push(new Boundary(coords));
      }
    }

    return boundaries;
  }
}

export class BoundaryCollection extends Array<Boundary> {
  get area(): number {
    return this.reduce((acc, b) => acc + b.area, 0);
  }

  equals(other: BoundaryCollection): boolean {
    if (this.length !== other.length) {
      return false;
    }

    for (let i = 0; i < this.length; ++i) {
      if (!this[i].equals(other[i])) {
        return false;
      }
    }

    return true;
  }
}

export class RollerCell {
  coords: GridCoordinate;
  passes: RollerPass[];
  isWithinBoundary: boolean = true;
  polygon: PreparedPolygon;

  private specResults: CompactionSpecificationCellResult[];

  constructor(coords: GridCoordinate, passes: RollerPass[] = []) {
    this.coords = coords;
    this.passes = passes;
    this.polygon = PreparedPolygon.fromCoordinates(this.vertices);
    this.specResults = [];
  }

  get vertices(): Point[] {
    return [
      { x: this.coords.x - 0.2, y: this.coords.y - 0.2 },
      { x: this.coords.x + 0.2, y: this.coords.y - 0.2 },
      { x: this.coords.x + 0.2, y: this.coords.y + 0.2 },
      { x: this.coords.x - 0.2, y: this.coords.y + 0.2 },
    ];
  }

  getCMV(type: CMVType): number | null {
    const cmvs: number[] = this.passes.map((pass) => pass.cmv)
      .filter((cmv) => cmv !== null) as number[];
    if (cmvs.length === 0) {
      return null;
    }

    switch (type) {
      case 'firstPass':
        return cmvs[0];
      case 'lastPass':
        return cmvs[cmvs.length - 1];
      case 'average':
        return (cmvs.reduce((acc, val) => acc + val, 0) ?? 0) / cmvs.length;
      case 'minimum':
        return Math.min(...cmvs);
      case 'maximum':
        return Math.max(...cmvs);
      default:
        throw new Error('Invalid CMV type');
    }
  }

  /**
   * Calculates the time taken to achieve the given number of passes
   * @param passNumber The pass number to calculate the time for
   * @returns The time taken to achieve the given number of passes in seconds
   */
  calculatePassTime(passNumber: number): number {
    if (passNumber < 1 || passNumber > this.passes.length) {
      throw new Error('Invalid pass number');
    }

    return this.passes[passNumber - 1].timestamp.diff(this.passes[0].timestamp, 'seconds', true);
  }

  /**
   * Gets the results of the compaction analysis for the given spec
   * @param spec The compaction spec to get the results for
   * @returns The results of the compaction analysis for the given spec, or undefined if the spec was not analysed
   */
  getCompactionResults(spec: CompactionSpecification): CompactionSpecificationCellResult | undefined {
    if (!this.specResults || this.specResults.length < spec.specNumber) {
      return undefined;
    }

    return this.specResults[spec.specNumber - 1];
  }

  /**
   * Analyse the compaction of this cell based on the given specs and store the results
   * @param specs The collection of compaction specs to analyse
   */
  analyseCompaction(specs: CompactionSpecification[]): void {
    this.specResults = new Array<CompactionSpecificationCellResult>(specs.length);

    // analyse each ASPHALT spec
    for (const spec of specs.filter(isAsphaltSpec)) {
      // get/calculate relevant values
      const passCount = this.passes.length;
      const targetPasses = Math.min(spec.targetPasses, passCount);
      const rollTime = dayjs.duration({
        milliseconds: this.passes[targetPasses - 1].timestamp.diff(this.passes[0].timestamp)
      });

      const temperatures = this.passes.slice(0, targetPasses).map((pass) => pass.temperature);
      const rollTemperature = {
        targetPass: temperatures[targetPasses - 1],
        minimum: Math.min(...temperatures),
        average: temperatures.reduce((acc, val) => acc + val, 0) / targetPasses,
      }

      // check if the spec is met
      const timeLimit = spec.timeLimit ?? dayjs.duration({ minutes: 45 });
      const tempCutoff = spec.temperatureCutoff ?? 0;
      let passed = true;
      let failureReason: FailureReason | undefined = undefined;
      if (passCount < spec.targetPasses) {
        passed = false;
        failureReason = 'passes';
      }

      if (rollTime.asMinutes() > timeLimit.asMinutes()) {
        passed = false;
        failureReason = 'time';

        // if (this.passes[targetPasses - 1].rollerId !== this.passes[0].rollerId) {
        //   console.log(`Time Failure (Separate Rollers): ${this.passes[0].timestamp.format('HH:mm:ss')} - ${this.passes[targetPasses - 1].timestamp.format('HH:mm:ss')}`)
        // }
      }

      if (rollTemperature.targetPass < tempCutoff) {
        passed = false;
        failureReason = 'temperature';
      }

      // create results object
      const result: AsphaltCompactionSpecificationCellResult = {
        spec,
        passCount,
        rollTime,
        rollTemperature,
        passed,
        failureReason
      };

      // store results in map
      this.specResults[spec.specNumber - 1] = result;
    }

    // analyse each BASE spec
    for (const spec of specs.filter(isBaseSpec)) {
      // get/calculate relevant values
      const targetCMV = spec.targetCMV;

      // check if the spec is met
      const cmv = this.getCMV(spec.cmvType);
      let passed = true;
      let failureReason: FailureReason | undefined = undefined;

      if (cmv === null || cmv === 0) {
        passed = false;
        failureReason = 'invalid';
      }
      else if (cmv < targetCMV) {
        passed = false;
        failureReason = 'cmv';
      }

      // create results object
      const result: BaseCompactionSpecificationCellResult = {
        spec,
        cmv: {
          value: cmv,
          type: spec.cmvType,
        },
        // cmv: {
        //   average: averageCMV,
        //   minimum: minCMV,
        //   maximum: maxCMV,
        //   firstPass: firstPassCMV,
        //   lastPass: lastPassCMV,
        //   changes: changesCMV
        // },
        passed,
        failureReason
      };

      // store results in map
      this.specResults[spec.specNumber - 1] = result;
    }
  }

  checkWithinBoundaries(boundaries: BoundaryCollection): void {
    if (!boundaries) {
      this.isWithinBoundary = true;
      return;
    }

    for (const boundary of boundaries) {
      const insideCount = this.countVerticesInside(boundary);

      if (insideCount === 4) {
        this.isWithinBoundary = true;
        return;
      }

      if (insideCount > 0) {
        // calculate overlap polygon
        // calculate area of overlap polygon
        // return true if area > threshold (50% of title size)
        const overlap = boundary.polygon.calculateIntersection(this.polygon);
        try {
          const overlapArea = overlap.calculateArea();
          this.isWithinBoundary = overlapArea >= 0.08;

        } catch (error) {
          console.error('Error calculating overlap area: ');
          console.error(this.polygon);
          console.error(overlap);
          console.error(boundary);

          this.isWithinBoundary = false;
        }

        return;
      }
    }

    this.isWithinBoundary = false;
  }

  private countVerticesInside(boundary: Boundary) {
    let count = 0;
    for (const vertex of this.vertices) {
      if (boundary.polygon.containsPoint([vertex.x, vertex.y])) {
        ++count;
      }
    }
    return count;
  }
}

export interface Bounds {
  min: Point;
  max: Point;
  centre: Point;

  width: number;
  height: number;
}

function isRollerPass(value: RollerCell | RollerPass): value is RollerPass {
  return (value as RollerCell).passes === undefined;
}

export interface SpecResultGroups {
  spec: CompactionSpecification;

  passed: {
    inside: RollerCell[];
    outside: RollerCell[];
  },

  failed: {
    inside: Record<FailureReason, RollerCell[]>;
    outside: Record<FailureReason, RollerCell[]>;
  }
}

export interface OverviewInfo {
  rollerId: string;

  date: Dayjs;

  project: string;
  engineer: string;
  epsg: number;
  epsgName: string;
}

export class RollerData implements Iterable<RollerCell> {
  private map = new Map<string, RollerCell>();

  overviews: OverviewInfo[] = [];

  boundaries: BoundaryCollection = new BoundaryCollection();
  compactionSpecs: CompactionSpecification[] = [];

  private groupedData?: HashedMap<CompactionSpecification, SpecResultGroups>;

  private bounds?: Bounds;

  private cellsInsideBoundary?: RollerCell[];

  /**
   * The area of the cell in m²
   */
  readonly cellArea = 0.16;

  private hashFunction(key: GridCoordinate): string {
    return `${key.x.toFixed(3)},${key.y.toFixed(3)}`;
  }

  private setInternal(key: string, value: RollerCell): void {
    this.map.set(key, value);
  }

  private getInternal(key: string): RollerCell | undefined {
    return this.map.get(key);
  }

  get length(): number {
    return this.map.size;
  }

  get groupedCells(): HashedMap<CompactionSpecification, SpecResultGroups> {
    if (this.groupedData) {
      return this.groupedData;
    }

    this.groupedData = new HashedMap<CompactionSpecification, SpecResultGroups>((spec) => spec.specNumber);

    for (const spec of this.compactionSpecs) {
      this.groupedData.set(spec, this.groupRollerData(spec));
    }

    return this.groupedData;
  }

  get totalArea(): number {
    return this.boundaries.area;
  }

  get cellsWithinBoundary(): RollerCell[] {
    if (this.cellsInsideBoundary) {
      return this.cellsInsideBoundary;
    }

    this.cellsInsideBoundary = this.getCells()
      .where((cell) => cell.isWithinBoundary)
      .toArray();

    return this.cellsInsideBoundary!;
  }

  set(key: GridCoordinate, value: RollerCell): void {
    this.map.set(this.hashFunction(key), value);
  }

  get(key: GridCoordinate): RollerCell | undefined {
    return this.map.get(this.hashFunction(key));
  }

  has(key: GridCoordinate): boolean {
    return this.map.has(this.hashFunction(key));
  }

  addOrUpdate(value: RollerPass | RollerCell): void {
    const coord = value.coords;
    const hash = this.hashFunction(coord);
    const cell = this.getInternal(hash);

    if (isRollerPass(value)) {
      if (cell) {
        cell.passes.push(value);
      } else {
        this.setInternal(hash, new RollerCell(coord, [value]));
      }
    } else {
      if (cell) {
        cell.passes.push(...value.passes);
      } else {
        this.setInternal(hash, value);
      }
    }
  }

  getCells(): IterableIterator<RollerCell> {
    return this.map.values();
  }

  [Symbol.iterator](): Iterator<RollerCell> {
    return this.getCells();
  }

  calculateBounds(): Bounds {
    /**
     * If boundaries have been defined, calculate the bounds based on the boundaries
     */
    if (this.boundaries.length > 0) {
      let minX = Number.MAX_VALUE;
      let minY = Number.MAX_VALUE;
      let maxX = Number.MIN_VALUE;
      let maxY = Number.MIN_VALUE;

      for (const boundary of this.boundaries) {
        const bounds = boundary.bounds;
        if (bounds.min.x < minX) { minX = bounds.min.x; }
        if (bounds.min.y < minY) { minY = bounds.min.y; }
        if (bounds.max.x > maxX) { maxX = bounds.max.x; }
        if (bounds.max.y > maxY) { maxY = bounds.max.y; }
      }

      const centreX = (minX + maxX) / 2;
      const centreY = (minY + maxY) / 2;
      return {
        min: { x: minX, y: minY },
        max: { x: maxX, y: maxY },
        centre: { x: centreX, y: centreY },
        width: maxX - minX,
        height: maxY - minY
      };
    }
    /**
     * If boundaries have not been defined, calculate the bounds based on the cells
     */
    else {
      if (!this.bounds) {
        let minX = Number.MAX_VALUE;
        let minY = Number.MAX_VALUE;
        let maxX = Number.MIN_VALUE;
        let maxY = Number.MIN_VALUE;
        for (const cell of this.map.values()) {
          if (cell.coords.x < minX) { minX = cell.coords.x; }
          if (cell.coords.y < minY) { minY = cell.coords.y; }
          if (cell.coords.x > maxX) { maxX = cell.coords.x; }
          if (cell.coords.y > maxY) { maxY = cell.coords.y; }
        }

        const centreX = (minX + maxX) / 2;
        const centreY = (minY + maxY) / 2;
        this.bounds = {
          min: { x: minX, y: minY },
          max: { x: maxX, y: maxY },
          centre: { x: centreX, y: centreY },
          width: maxX - minX,
          height: maxY - minY
        };
      }

      return this.bounds;
    }
  }

  processBoundaries(boundaries: BoundaryCollection): void {
    // check if the boundaries have changed. If not, just return early
    if (this.boundaries.equals(boundaries)) {
      console.log('Boundaries have not changed');
      return;
    }

    console.log('Processing boundaries');

    // update the boundaries and check each cell against the new boundaries
    this.boundaries = boundaries;
    this.groupedData = undefined; // reset grouped data
    for (const cell of this.map.values()) {
      cell.checkWithinBoundaries(boundaries);
    }
  }

  processCompactionSpecs(specs: CompactionSpecification[]): void {
    if (!this.isNewSpecs(specs)) {
      console.log('Specs have not changed');
      return;
    }

    console.log('Processing compaction specs');
    this.groupedData = undefined;
    this.compactionSpecs = specs; // reset grouped data
    for (const cell of this.map.values()) {
      cell.analyseCompaction(specs);
    }
  }

  private isNewSpecs(specs: CompactionSpecification[]): boolean {
    if (this.compactionSpecs.length !== specs.length) {
      return true;
    }

    for (let i = 0; i < specs.length; ++i) {
      if (!shallowEqual(this.compactionSpecs[i], specs[i])) {
        return true;
      }
    }

    return false;
  }

  private groupRollerData(spec: CompactionSpecification): SpecResultGroups {
    const cells: SpecResultGroups = {
      spec,
      passed: { inside: [], outside: [] },
      failed: {
        inside: {
          passes: [],
          time: [],
          temperature: [],

          cmv: [],
          invalid: []
        },
        outside: {
          passes: [],
          time: [],
          temperature: [],

          cmv: [],
          invalid: []
        }
      }
    };

    for (const cell of this) {
      const inside = cell.isWithinBoundary;
      const result = cell.getCompactionResults(spec);
      if (!result) {
        if (inside) {
          cells.failed.inside.invalid.push(cell);
        } else {
          cells.failed.outside.invalid.push(cell);
        }
        continue;
      }

      if (result.passed) {
        if (inside) {
          cells.passed.inside.push(cell);
        } else {
          cells.passed.outside.push(cell);
        }
      } else {
        const key = result.failureReason ?? 'invalid';
        if (inside) {
          cells.failed.inside[key].push(cell);
        } else {
          cells.failed.outside[key].push(cell);
        }
      }
    }

    return cells;
  }

  private passPolygons: HashedMap<number, PreparedPolygon[]> = new HashedMap<number, PreparedPolygon[]>((pass) => pass);

  public calculatePassPolygons(): HashedMap<number, PreparedPolygon[]> {
    // if (this.passPolygons.size > 0) {
    //   return this.passPolygons;
    // }

    //return this.calculatePassPolygons_PolyBool();
    return this.calculatePassPolygons_Tracer();
  }

  private calculatePassPolygons_Tracer(): HashedMap<number, PreparedPolygon[]> {
    const polygons = calculateOutlinePolygons(this, 1);

    this.passPolygons.clear();
    this.passPolygons.set(1, polygons.map((poly) => PreparedPolygon.fromCoordinates(poly)));
    return this.passPolygons;
  }

  // private calculatePassPolygons_PolyBool(): HashedMap<number, PreparedPolygon[]> {
  //   const passPolys = new HashedMap<number, PreparedPolygon[]>((pass) => pass);
  //   for (const cell of this) {
  //     for (const pass of cell.passes) {
  //       if (!passPolys.has(pass.passNumber)) {
  //         passPolys.set(pass.passNumber, []);
  //       }

  //       passPolys.get(pass.passNumber)!.push(PreparedPolygon.fromCoordinates(cell.vertices));
  //     }
  //   }

  //   const polybool = new PolyBool(new GeometryEpsilon(1e-6));
  //   for (const pass of passPolys.keys()) {
  //     const polys = passPolys.get(pass)!;

  //     const regions = polys.map((poly) => poly.vertices);
  //     const segments = polybool.segments({ regions, inverted: false });
  //     const combined: CombinedSegments = {
  //       combined: segments.segments,
  //       inverted1: false,
  //       inverted2: false,
  //     }

  //     const union = polybool.selectUnion(combined);
  //     const poly = polybool.polygon(union);

  //     for (const region of poly.regions) {
  //       if (!this.passPolygons.has(pass)) {
  //         this.passPolygons.set(pass, []);
  //       }

  //       this.passPolygons.get(pass)!.push(new PreparedPolygon(region));
  //     }
  //   }

  //   return this.passPolygons;
  // }
}

export function mergeRollerData(data: RollerData[], overviews: OverviewInfo[]): RollerData {
  if (data.length === 1) {
    data[0].overviews = overviews;
    return data[0];
  }

  const merged = new RollerData();

  if (data.length === 0) {
    return merged;
  }

  // merge all the data into a single set
  for (const d of data) {
    for (const cell of d) {
      merged.addOrUpdate(cell);
    }
  }

  // sort the passes by timestamp & recalc pass numbers
  const sorted = new RollerData();
  for (const cell of merged) {
    const passes = cell.passes.sort((a, b) => a.timestamp?.diff(b.timestamp) ?? -1);
    for (let i = 0; i < passes.length; ++i) {
      passes[i].passNumber = i + 1;
    }

    sorted.set(cell.coords, new RollerCell(cell.coords, passes));
  }

  sorted.overviews = overviews;
  return sorted;
}
