import { createAppSlice } from '@app/createAppSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SliceActions } from '@tools/helpers';
import { setOrRemove, type PersistHandlers } from '@tools/persistHelpers';
import type { Dayjs } from 'dayjs';

interface AuthDetails {
  accessToken: string;
  expiration: Dayjs;
}

interface AccountState {
  username?: string;

  auth?: AuthDetails;
}

const initialState: AccountState = {
  username: undefined,
  auth: undefined,
};

export const accountSlice = createAppSlice({
  name: 'account',
  initialState,

  reducers: (create) => ({
    setAuth: create.reducer((state, action: PayloadAction<AuthDetails | undefined>) => {
      state.auth = action.payload;
    })
  }),

  selectors: {
    getUsername: (state) => state.username,
    getAuth: (state) => state.auth,
    getAccessToken: (state) => state.auth?.accessToken,
    getExpiration: (state) => state.auth?.expiration,
  }
});

export const {
  setAuth
} = accountSlice.actions;

export const {
  getUsername,
  getAuth,
  getAccessToken,
  getExpiration,
} = accountSlice.selectors;

export const ignoredAccountActions = [
  setAuth.type,
];

export const ignoredAccountPaths = [
  'account.auth.expiration',
];

export type AccountActions = typeof accountSlice.actions;
export type AccountActionTypes = SliceActions<typeof accountSlice.actions>;

export const accountPersistHandlers: PersistHandlers<AccountActionTypes> = {
  'account/setAuth': (state) => {
    setOrRemove('account:auth', state.account.auth);
  },
};