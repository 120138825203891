import { Datum, type CartestianCoordinate } from '../core/Coordinate';
import vector, { type V3 } from '../../math/Vector3';
import matrix, { type M3 } from '../../math/Matrix3';
import rotationParams from './rotationParams';
import '../../math/numberExtensions';
import { type Dayjs } from 'dayjs';

export interface IHelmertTransform {
    sourceDatum: Datum;
    destDatum: Datum;

    transform: (coord: CartestianCoordinate) => CartestianCoordinate;
}

// export class MatrixEx extends Matrix {
//     add(m: Matrix): Matrix {
//         if (this.rows !== m.rows || this.columns !== m.columns) {
//             throw new Error("Matrix dimensions must agree");
//         }

//         const result = Array.from({ length: this.rows }, () => Array(this.columns).fill(0));

//         for (let i = 0; i < this.rows; i++) {
//             for (let j = 0; j < this.columns; j++) {
//                 result[i][j] = this.at(i, j) + m.at(i, j);
//             }
//         }

//         return new Matrix(this.rows, this.columns, result);
//     }
// }

type A3 = [number, number, number];
type A9 = [number, number, number, number, number, number, number, number, number];

export class Helmert implements IHelmertTransform {
    private readonly translation: V3;
    private readonly rotation: M3;
    private readonly scale: number;

    private readonly buffer: Float64Array = new Float64Array(3);
    private readonly input: Float64Array = new Float64Array(3);

    sourceDatum: Datum;
    destDatum: Datum;

    constructor(t: V3 | A3, r: M3 | A9, s: number, sourceDatum: Datum, destDatum: Datum) {
        this.translation = Array.isArray(t) ? new Float64Array(t) : t;
        this.rotation = Array.isArray(r) ? new Float64Array(r) : r;
        this.scale = s;
        this.sourceDatum = sourceDatum;
        this.destDatum = destDatum;
    }

    transformMatrix(coord: CartestianCoordinate): CartestianCoordinate {
        // create vector from coordinate and we re-use this vector throughout the calculations
        this.input.set([coord.x, coord.y, coord.z]);

        const output = this.buffer;
        matrix.multiplyVector(this.rotation, this.input, output);
        vector.multiplyScalar(output, this.scale, output);
        vector.add(output, this.translation, output);

        return {
            x: output[0],
            y: output[1],
            z: output[2]
        };
    }

    transform(coord: CartestianCoordinate): CartestianCoordinate {
        const x = coord.x;
        const y = coord.y;
        const z = coord.z;
        const r = this.rotation;
        const s = this.scale;
        const t = this.translation;

        return {
            x: t[0] + s * (r[0] * x + r[1] * y + r[2] * z),
            y: t[1] + s * (r[3] * x + r[4] * y + r[5] * z),
            z: t[2] + s * (r[6] * x + r[7] * y + r[8] * z)
        }
    }

    static createWGS84ToGDA2020(epoch: Dayjs): Helmert {
        const rotParams = rotationParams.wgs84ToGDA2020(epoch);
        const rotMatrix = Helmert.createRotationMatrix(rotParams);
        const translate = new Float64Array([0, 0, 0]);
        const scale = 1;

        return new Helmert(translate, rotMatrix, scale, Datum.WGS84, Datum.GDA2020);
    }

    static createGDA94ToGDA2020(): Helmert {
        const rotParams = rotationParams.getGDA94ToGDA2020();
        const rotMatrix = Helmert.createRotationMatrix(rotParams);
        const translate = new Float64Array([0.06155, -0.01087, -0.04019]);
        const scale = 1.0 - (0.009994 / 1e6);

        return new Helmert(translate, rotMatrix, scale, Datum.GDA94, Datum.GDA2020);
    }

    static createGDA2020ToGDA94(): Helmert {
        const rotParams = rotationParams.getGDA2020ToGDA94();
        const rotMatrix = Helmert.createRotationMatrix(rotParams);
        const translate = new Float64Array([-0.06155, 0.01087, 0.04019]);
        const scale = 1.0 + (0.009994 / 1e6);

        return new Helmert(translate, rotMatrix, scale, Datum.GDA2020, Datum.GDA94);
    }

    private static createRotationMatrix(rotation: V3): M3 {
        // convert arcseconds to radians x -1
        const [rx, ry, rz] = rotation.map((arcsecs) => arcsecs.convertArcSecsToRadians());
        const cosX = Math.cos(rx);
        const sinX = Math.sin(rx);
        const cosY = Math.cos(ry);
        const sinY = Math.sin(ry);
        const cosZ = Math.cos(rz);
        const sinZ = Math.sin(rz);

        const rotationMatrix = new Float64Array([
            cosY * cosZ, cosY * sinZ, -sinY,
            sinX * sinY * cosZ - cosX * sinZ, cosX * cosZ + sinX * sinY * sinZ, sinX * cosY,
            sinX * sinZ + cosX * sinY * cosZ, cosX * sinY * sinZ - sinX * cosZ, cosX * cosY
        ]);

        return rotationMatrix;
    }
}

/**
 * Small angle approximation implementation of helmert transforms
 */
export class HelmertSAA implements IHelmertTransform {
    private readonly _tx: number;
    private readonly _ty: number;
    private readonly _tz: number;
    private readonly _rx: number;
    private readonly _ry: number;
    private readonly _rz: number;
    private readonly _s: number;

    sourceDatum: Datum;
    destDatum: Datum;

    constructor(tx: number, ty: number, tz: number, rx: number, ry: number, rz: number, s: number, sourceDatum: Datum, destDatum: Datum) {
        this._tx = tx;
        this._ty = ty;
        this._tz = tz;
        this._rx = rx;
        this._ry = ry;
        this._rz = rz;
        this._s = s;
        this.sourceDatum = sourceDatum;
        this.destDatum = destDatum;
    }

    transform(coord: CartestianCoordinate): CartestianCoordinate {
        const x = coord.x;
        const y = coord.y;
        const z = coord.z;

        const tx = this._tx;
        const ty = this._ty;
        const tz = this._tz;
        const rx = this._rx;
        const ry = this._ry;
        const rz = this._rz;
        const s = this._s;

        // const t: V3 = new Float64Array([tx, ty, tz]);
        // const rot: M3 = new Float64Array([
        //     1,      -rz,    ry,
        //     rz,     1,      -rx,
        //     -ry,    rx,     1
        // ]);

        return {
            x: tx + s * (x - rz * y + ry * z),
            y: ty + s * (rz * x + y - rx * z),
            z: tz + s * (-ry * x + rx * y + z)
        };
    }

    static createGDA2020ToGDA94(): HelmertSAA {
        const r = rotationParams.getGDA2020ToGDA94().map((r) => -r.convertArcSecsToRadians());
        const t = new Float64Array([-0.06155, 0.01087, 0.04019]);
        const scale = 1.0 + (0.009994 / 1e6);

        return new HelmertSAA(t[0], t[1], t[2], r[0], r[1], r[2], scale, Datum.GDA94, Datum.GDA2020);
    }

    static createGDA94ToGDA2020(): HelmertSAA {
        const r = rotationParams.getGDA94ToGDA2020().map((r) => -r.convertArcSecsToRadians());
        const t = new Float64Array([0.06155, -0.01087, -0.04019]);
        const scale = 1.0 - (0.009994 / 1e6);

        return new HelmertSAA(t[0], t[1], t[2], r[0], r[1], r[2], scale, Datum.GDA94, Datum.GDA2020);
    }

    static createWGS84ToGDA2020(epoch: Dayjs): HelmertSAA {
        const r = rotationParams.wgs84ToGDA2020(epoch).map((r: number) => -r.convertArcSecsToRadians());

        return new HelmertSAA(0, 0, 0, r[0], r[1], r[2], 1, Datum.WGS84, Datum.GDA2020);
    }
}

export class IdentityHelmert implements IHelmertTransform {
    sourceDatum: Datum;
    destDatum: Datum;

    constructor(sourceDatum: Datum, destDatum: Datum) {
        this.sourceDatum = sourceDatum;
        this.destDatum = destDatum;
    }

    transform(coord: CartestianCoordinate): CartestianCoordinate {
        return coord;
    }

    /**
     * Creates an 'identity' transform that doesn't apply any datum shift.
     * This is used when the source and destination datums are the same and we only want
     * to convert from lat/lon to/from east/north
     * @returns {IdentityHelmert} - Identity Helmert transform for GDA2020
     */
    static createGDA2020(): IdentityHelmert {
        return new IdentityHelmert(Datum.GDA2020, Datum.GDA2020);
    }

    /**
     * Creates an 'identity' transform that doesn't apply any datum shift.
     * This is used when the source and destination datums are the same and we only want
     * to convert from lat/lon to/from east/north
     * @returns {IdentityHelmert} - Identity Helmert transform for the specified datum
     */
    static create(datum: Datum): IdentityHelmert {
        return new IdentityHelmert(datum, datum);
    }
}