import type { AsphaltCompactionSpecification, BaseCompactionSpecification, FailureReason, ReportType } from '@analysis/RollerAnalysis';
import { type GridCoordinate, type Point } from '@shared/geometry/core/Coordinate';
import type {
  save,
  restore,
  path,
  fill,
  font,
  text,
  scale,
  translate,
  polygon,
  fillColor,
  strokeColor,
  stroke,
  lineWidth,
  opacity,
} from 'pdfkit';
import { } from '@react-pdf/renderer';

import type { RollerCell, RollerData, BoundaryCollection } from '../../../data/rollerTypes';
import { failureColors, getBaseMapCellColor, getCellColor } from '../map/helpers';

declare global {
  interface PDFDocument {
    drawCell(coords: GridCoordinate, offset: Point, size: number): void;
  }
}

export type RuleValue = 'evenodd' | 'nonzero' | 'even-odd' | 'non-zero';

export interface PDFKitWrapper {
  save: typeof save;
  restore: typeof restore;
  path: typeof path;
  fill: typeof fill;
  font: typeof font;
  text: typeof text;
  rect: (x: number, y: number, width: number, height: number) => this;
  scale: typeof scale;
  translate: typeof translate;
  polygon: typeof polygon;
  fillColor: typeof fillColor;
  strokeColor: typeof strokeColor;
  stroke: typeof stroke;
  lineWidth: typeof lineWidth;
  opacity: typeof opacity;
}

export function drawCell(painter: PDFKitWrapper, cell: RollerCell, offset: Point, size: number) {
  painter
    .rect(
      cell.coords.x - offset.x - size / 2,
      cell.coords.y - offset.y - size / 2,
      size,
      size
    )
    .fill();
}

export function drawCells(painter: PDFKitWrapper,
  data: RollerData,
  spec: AsphaltCompactionSpecification | BaseCompactionSpecification | null,
  type: ReportType,
  offset: Point,
  cellSize: number) {

  if (spec) {
    // draw spec
    const cells = data.groupedCells.get(spec);
    if (!cells) {
      console.log('No cells found for spec: ', spec);
      return;
    }

    painter.opacity(0.33);
    painter.fillColor('limegreen');
    for (const passed of cells.passed.outside) {
      drawCell(painter, passed, offset, cellSize);
    }
    for (const reason in cells.failed.outside) {
      const result = reason as FailureReason;
      painter.fillColor(failureColors[result]);
      for (const failed of cells.failed.outside[result]) {
        drawCell(painter, failed, offset, cellSize);
      }
    }

    painter.opacity(1.0);
    painter.fillColor('limegreen');
    for (const passed of cells.passed.inside) {
      drawCell(painter, passed, offset, cellSize);
    }

    for (const reason in cells.failed.inside) {
      const result = reason as FailureReason;
      painter.fillColor(failureColors[result]);
      for (const failed of cells.failed.inside[result]) {
        drawCell(painter, failed, offset, cellSize);
      }
    }
  } else {
    // draw pass count
    const inside = data.getCells().where((c) => c.isWithinBoundary);
    const outside = data.getCells().where((c) => !c.isWithinBoundary);

    //TODO: store the CMV type somewhere so we don't need to keep passing it around separately
    //  - maybe store it on the RollerData object in a 'config' property and have it so we set it once for the entire report
    const colorGetter: (cell: RollerCell) => string = type === 'ASPHALT'
      ? getCellColor
      : (cell) => getBaseMapCellColor(cell, 'firstPass');

    painter.opacity(0.33);
    for (const cell of outside) {
      painter.fillColor(colorGetter(cell));
      drawCell(painter, cell, offset, cellSize);
    }
    painter.opacity(1.0);
    for (const cell of inside) {
      painter.fillColor(colorGetter(cell));
      drawCell(painter, cell, offset, cellSize);
    }
  }
}

export function drawBoundaries(painter: PDFKitWrapper, boundaries: BoundaryCollection, offset: Point) {
  for (const boundary of boundaries) {
    const poly = boundary.coords.map((coord) => ([
      coord.x - offset.x,
      coord.y - offset.y,
    ]));

    painter.polygon(...poly).lineWidth(0.25).stroke();
  }
}
